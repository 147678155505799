import { Form, HorizontalGroup, Input, VerticalGroup, useStyles2 } from '@grafana/ui';
import React, { ReactElement } from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  SubmitHandler,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  AFFICHE_ECOLAIR_QAI,
  AutosizeTextArea,
  CONSEILS_CEREMA_ENTRETIEN_AERATION_VENTILATION,
  CreatableSelect,
  DateTimeInput,
  EmailInput,
  FICHE_CEREMA_EQUIPEMENTS_ACTIVITES_EMISSIVES,
  FICHE_ECOLAIR_AERATION,
  FICHE_ECOLAIR_AMBIANCE,
  FICHE_ECOLAIR_DOUBLE_FLUX,
  FICHE_ECOLAIR_FOURNITURES,
  FICHE_ECOLAIR_MATERIAUX,
  FICHE_ECOLAIR_MOBILIER,
  FICHE_ECOLAIR_MOISISSURES,
  FICHE_ECOLAIR_NETTOYAGE_ECO,
  FICHE_ECOLAIR_PRODUITS_ENTRETIEN,
  GUIDE_CEREMA,
  GUIDE_CSTB,
  GUIDE_ECOLAIR_ENTRETIEN,
  GUIDE_ECOLAIR_VENTILATION,
  GoodPractiseInput,
  NBSP,
  OPTIONAL,
  PAGE_ALLERGENES_OQAI,
  PERSON_PLACEHOLDER,
  PROJET_PHYTAIR_OQAI,
  REQUIRED,
  SaveButton,
  SaveState,
  SmallFieldSet,
  TARGET_BLANK,
  TooltipCheckbox,
  TooltipField,
} from 'utils/utils.form';
import { lowerCaseFirstLetter } from 'utils/utils.format';
import { Org, Report, Room, makeNameOptions } from 'utils/utils.model';
import { AutodiagTab } from 'utils/utils.routing';
import { getStyles } from 'utils/utils.styles';

const LARGE_FORM_MAX_WIDTH = 700;

export const POLLUTANT_SOURCE = 'Sources potentiellement émettrices de polluants';

// [label, description, dependentKey]
export type ChoiceField = [string, string | React.ReactElement, string?];

export type TooltipAndChoiceFields = [string | ReactElement | undefined, { [field: string]: ChoiceField }];

export type CheckboxFields = {
  choiceFields: TooltipAndChoiceFields;
  noChoiceField: string;
  otherChoiceLabel: string;
  otherChoiceField: string;
  commentField: string;
};

export type TabFields = {
  nom: string;
  nomLong: string;
  nameField: string;
  functionDescription: string;
  emailField: string;
  functionField: string;
  dateField: string;
  benzene?: CheckboxFields;
  formaldehyde?: CheckboxFields;
  practises: { [group: string]: TooltipAndChoiceFields };
  observations?: CheckboxFields;
};

const INDUSTRIE_CHIMIQUE = 'Industrie chimique, parachimique ou pétrochimique à moins de 3 km';

export type AutodiagFormTab =
  | AutodiagTab.gestion
  | AutodiagTab.maintenance
  | AutodiagTab.entretien
  | AutodiagTab.activites;

// Label and description rules
// - short label to be only one line on PC
// - label starting with noun
// - description with details for action item
export const AUTODIAG_TAB_FIELDS: {
  [key in AutodiagFormTab]: TabFields;
} = {
  gestion: {
    nom: 'Gestion globale',
    nomLong: 'Localisation et gestion globale',
    functionDescription: 'Gestionnaire de l’établissement',
    nameField: 'responsableGestion',
    functionField: 'fonctionGestion',
    emailField: 'emailGestion',
    dateField: 'dateGestion',
    benzene: {
      noChoiceField: 'autodiagGestionAucunBenzene',
      otherChoiceLabel: 'Autres activités potentiellement émettrices',
      otherChoiceField: 'autodiagGestionBenzeneAutre',
      commentField: 'autodiagGestionBenzeneComment',
      choiceFields: [
        <>
          Recenser les activités extérieures potentiellement émettrices de benzène. Un listing indicatif est proposé
          ci-dessous pour vous aider dans votre recensement. Par ailleurs, un contact auprès de l’
          <a href="https://www.atmo-france.org/article/laasqa-de-votre-region" {...TARGET_BLANK}>
            AASQA (Association agréée de surveillance de la qualité de l’air) locale
          </a>{' '}
          pour mise à disposition des données et cartes d’émissions et de concentrations polluantes est utile.
        </>,
        {
          autodiagBenzeneParkingSousSol: ['Parking en sous-sol', ''],
          autodiagBenzeneRejetAirParkingCommerces: [
            'Ouvrants / entrées d’air à moins de 8 m de rejets d’air de parking ou commerce',
            'Ouvrants et/ou entrées d’air du bâtiment situés à moins de 8 m du rejet de l’air extrait du parking souterrain (règlement sanitaire départemental RSD) ou de commerces de proximité (garage voitures ou deux-roues…)',
          ],
          autodiagBenzeneGareRoutiere: ['Gare routière à moins de 100 m', ''],
          autodiagBenzeneRoute: [
            'Axe routier à fort trafic à moins de 200 m',
            'Axe routier avec un TMJA (Trafic Moyen Journalier Annuel) > 7 000 véhicules/jour (ex. : route nationale ou départementale à fort trafic, autoroute, voie rapide)',
          ],
          autodiagBenzeneParkingForTrafic: [
            'Parking à fort trafic à moins de 200 m',
            'Parking de centre commercial (> 1 000 places) ou de centre-ville à forte rotation par exemple.',
          ],
          autodiagBenzeneStationService: ['Station-service à moins de 200 m', ''],
          // autodiagBenzeneIndustrieChimique & autodiagFormaldehydeIndustrieChimique have same name and are dependent
          autodiagBenzeneIndustrieChimique: [INDUSTRIE_CHIMIQUE, '', 'autodiagFormaldehydeIndustrieChimique'],
          autodiagBenzeneInstallationCombustion: [
            'Installation de combustion à moins de 3 km',
            'Installation de combustion : centrale thermique, chaufferie collective, incinérateur… à moins de 3 km',
          ],
          autodiagBenzeneIndustrieMetallurgique: ['Industrie métallurgique, sidérurgique, cokerie à moins de 3 km', ''],
          autodiagBenzeneStockageHydroCarbures: ['Parc de stockage d’hydrocarbures à moins de 3 km', ''],
        },
      ],
    },
    formaldehyde: {
      noChoiceField: 'autodiagGestionAucunFormaldehyde',
      otherChoiceLabel: 'Autres activités potentiellement émettrices',
      otherChoiceField: 'autodiagGestionFormaldehydeAutre',
      commentField: 'autodiagGestionFormaldehydeComment',
      choiceFields: [
        <>
          Recenser les activités extérieures potentiellement émettrices de formaldéhyde. Bien que le formaldéhyde soit
          un composé dont les sources majoritaires sont situées à l’intérieur des locaux, il peut s’avérer utile de
          procéder à ce recensement.
        </>,
        {
          // autodiagBenzeneIndustrieChimique & autodiagFormaldehydeIndustrieChimique have same name and dependent
          autodiagFormaldehydeIndustrieChimique: [INDUSTRIE_CHIMIQUE, '', 'autodiagBenzeneIndustrieChimique'],
          autodiagFormaldehydeIndustrieBois: ['Industrie du bois, papier ou carton à moins de 3 km', ''],
        },
      ],
    },
    practises: {
      'Matériaux de construction et revêtements': [
        undefined,
        {
          autodiagAPlus: [
            'Produits étiquetés A+ pour les travaux en cours ou à venir',
            <>Voir la {FICHE_ECOLAIR_MATERIAUX}.</>,
          ],
          autodiagSansTapis: [
            'Absence de revêtements textiles tels que moquettes ou tapis en tissu',
            <>
              Bannir les revêtements textiles tels que moquettes, tapis en tissu, et ce notamment à proximité des points
              d’eau (risque de développement de moisissures). Voir la {FICHE_ECOLAIR_MATERIAUX}.
            </>,
          ],
        },
      ],
      Mobilier: [
        <>
          En cas de renouvellement complet du mobilier et/ou du matériel de motricité d’une pièce de vie/activité, il
          est recommandé de faire réaliser des mesures de formaldéhyde dans cette pièce, une fois les éléments neufs
          introduits (ayant suivi au préalable les recommandations ci-dessous), afin de s’assurer que les niveaux de
          concentration sont en accord avec les valeurs de référence disponibles pour ce composé. Des mesures sont aussi
          à conduire dans le cas où ce renouvellement aurait lieu il y a moins de 6 mois avant la mise en œuvre de ce
          guide.
        </>,
        {
          autodiagMeublesPeuEmissifs: [
            'Meubles labellisés peu émissifs lors des renouvellements',
            <>Normes : Écolabel européen, NF Environnement Éducation… Voir la {FICHE_ECOLAIR_MOBILIER}.</>,
          ],
          autodiagSansTextile: [
            'Absence de textile ou de rembourrage lors des futurs achats',
            <>
              Lors du renouvellement de mobilier ou matériel de motricité, éviter ceux avec des revêtements textiles et
              des assises ou dossiers rembourrés. Voir la {FICHE_ECOLAIR_MOBILIER}.
            </>,
          ],
          autodiagReceptionVacances: [
            'Réception de mobilier pendant les vacances',
            <>
              Programmer la réception du mobilier autant que possible pendant les vacances. Voir la{' '}
              {FICHE_ECOLAIR_MOBILIER}.
            </>,
          ],
          autodiagMotricite: [
            'Mobiliers et matériels de motricité neufs déballés un mois avant usage',
            <>
              À réception de mobilier ou de matériel de motricité neufs, les déballer et les stocker dans une pièce
              ventilée, chauffée mais non occupée, pendant au moins 4 semaines, avant de les introduire dans une pièce
              occupée. Voir la {FICHE_ECOLAIR_MOBILIER}.
            </>,
          ],
          autodiagReceptionMeubles: [
            'Réception et déballage de matériel au début des vacances',
            <>
              À défaut de pouvoir stocker le matériel dans une pièce non occupée, prévoir la livraison et le déballage
              en début de vacances scolaires (d’été si possible), minimiser l’occupation de la pièce et accentuer les
              phases d’aération pendant toute la durée des vacances. Voir la {FICHE_ECOLAIR_MOBILIER}.
            </>,
          ],
        },
      ],
      //  Activités
      'Sensibilisation et organisation': [
        undefined,
        {
          autodiagSensibilitation: [
            'Sensibilisation des services techniques et d’entretien à la qualité de l’air',
            <>
              Réaliser une sensibilisation du service technique et de l’équipe d’entretien à la qualité de l’air
              intérieur Voir le {GUIDE_ECOLAIR_VENTILATION}, le {GUIDE_ECOLAIR_ENTRETIEN} et l’{AFFICHE_ECOLAIR_QAI}.
            </>,
          ],
          autodiagEcoFournitures: [
            'Achat de fournitures écolabellisés',
            <>
              Normes : Écolabel européen, NF Environnement, Der Blaue Engel, Nordic Environment, Öko-test… Voir la{' '}
              {FICHE_ECOLAIR_FOURNITURES}.
            </>,
          ],
          autodiagRangement: [
            'Espaces de rangements spécifiques, isolés et ventilés',
            <>
              Mettre à disposition, à proximité des pièces de vie ou d’activités, des espaces de rangement spécifiques
              et isolés, ventilés, pour stocker les produits utiles aux activités (peintures, colles…). Voir la{' '}
              {FICHE_ECOLAIR_NETTOYAGE_ECO}.
            </>,
          ],
        },
      ],
      Ménage: [
        undefined,
        {
          autodiagEcoNettoyage: [
            'Privilégier des produits de nettoyage de qualité écologique',
            <>
              utiliser l’Écolabel européen et éviter les pictogrammes de danger. Voir le {GUIDE_ECOLAIR_ENTRETIEN} et la{' '}
              {FICHE_ECOLAIR_PRODUITS_ENTRETIEN}.
            </>,
          ],
          autodiagFormation: [
            'Assurance de formation du personnel d’entretien aux bonnes pratiques',
            <>
              S’assurer que le personnel d’entretien a été formé aux bonnes pratiques recommandées. Voir le{' '}
              {GUIDE_ECOLAIR_ENTRETIEN} et l’{AFFICHE_ECOLAIR_QAI}.
            </>,
          ],
          autodiagPlanningEntretien: [
            'Intervention du personnel d’entretien hors présence des enfants',
            <>
              Faire intervenir le personnel d’entretien, dans la mesure du possible, selon un planning qui facilite
              l’aération et hors présence des enfants. Voir le {GUIDE_ECOLAIR_ENTRETIEN} et l’{AFFICHE_ECOLAIR_QAI}.
            </>,
          ],
        },
      ],
      Travaux: [
        <>
          Même si les travaux réalisés bénéficient de labels performants en termes de qualité de l’air intérieur, ils
          mettent en œuvre des produits émissifs (peinture, colle, nouveau revêtement, isolant…). C’est pourquoi, le
          dispositif réglementaire de surveillance révisé impose désormais des campagnes de mesures des polluants
          réglementés à chaque étape clé de la vie du bâtiment pouvant impacter la QAI (cf. Tomes 4 et 5 du{' '}
          {GUIDE_CEREMA}). Toutefois, il est fortement recommandé de faire réaliser des mesures de formaldéhyde dans les
          espaces rénovés, avant leur réintégration par les élèves, y compris lorsque cela n’est pas rendu obligatoire
          dans le cadre des campagnes de mesures des polluants réglementés dans le cadre du dispositif de surveillance,
          afin de s’assurer que les concentrations sont en accord avec les valeurs réglementaires pour ce composé. Si
          les travaux ont été effectués il y a moins de 6 mois avant la mise en œuvre de ce guide, des mesures de
          formaldéhyde sont également recommandées dans les espaces rénovés, en conditions normales d’occupation.
          (Source: {GUIDE_CEREMA})
        </>,
        {
          autodiagRenovationVacances: [
            'Rénovation préférable pendant les grandes vacances',
            'Programmer les travaux de rénovation autant que possible pendant les grandes vacances',
          ],
          autodiagRenovationAeration: [
            'Inoccupation des espaces rénovés au moins une semaine avec aération',
            'Prévoir un temps d’inoccupation des espaces rénovés, a minima pendant 1 semaine (idéalement 4 semaines), avec d’importantes phases d’aération',
          ],
          autodiagFenetres: [
            'Maintien du renouvellement d’air pendant les changements de fenêtres',
            <>
              Lors de changement de fenêtres, maintenir des conditions satisfaisantes de renouvellement d’air des locaux
              (salles de classe, salles de réunions, salles d’activités). La réglementation thermique des bâtiments
              existants impose, lors de tels travaux, que les nouvelles fenêtres soient équipées d’entrées d’air (sauf
              si la pièce concernée en dispose déjà) - Art. 12 de l’arrêté du 3 mai 2007 modifié le 22 mars 2017.
            </>,
          ],
        },
      ],
      'Aération et ventilation': [
        <>
          Des mesures de dioxyde de carbone (CO2), en présence des élèves dans la pièce instrumentée, sont un bon
          indicateur du confinement de l’air intérieur. Elles peuvent apporter une aide à la définition d’une stratégie
          d’aération dans les pièces accueillant des enfants, à adapter la jauge d’occupation, et permettre d’améliorer
          les pratiques d’aération déjà en place.
        </>,
        {
          autodiagEvaluationAnnuelle: [
            'Planification de l’évaluation obligatoire des moyens d’aération',
            'L’évaluation obligatoire des moyens d’aération selon les textes réglementaires a bien été programmée ou réalisée',
          ],
          autodiagAffichageEvaluation: [
            'Affichage des résultats de l’évaluation des moyens d’aération',
            'Afficher les résultats du rapport relatif à l’évaluation des moyens d’aération',
          ],
          autodiagVMC: [
            'Priorisation de l’installation d’une VMC',
            <>
              Prévoir l’installation d’une VMC, en priorité pour les pièces sans ouvrant sur l’extérieur. Voir par
              exemple la {FICHE_ECOLAIR_DOUBLE_FLUX}.
            </>,
          ],
          autodiagCapteur: [
            'Capteur de CO2 dans chaque pièce',
            <>
              Étudier la possibilité d’implanter un indicateur de confinement (capteur CO2) dans chaque pièce pour
              optimiser les pratiques d’aération déjà en place et adapter la jauge d’occupation. Voir les critères du{' '}
              {GUIDE_CSTB}.
            </>,
          ],
        },
      ],
    },
  },
  maintenance: {
    nom: 'Maintenance',
    nomLong: 'Maintenance',
    functionDescription: 'Personnel de maintenance (services techniques)',
    nameField: 'responsableMaintenance',
    functionField: 'fonctionMaintenance',
    emailField: 'emailMaintenance',
    dateField: 'dateMaintenance',
    benzene: {
      noChoiceField: 'autodiagMaintenanceAucunBenzene',
      otherChoiceLabel: 'Autres sources potentiellement émettrices',
      otherChoiceField: 'autodiagMaintenanceBenzeneAutre',
      commentField: 'autodiagMaintenanceBenzeneComment',
      choiceFields: [
        <>
          Recenser les sources potentiellement émettrices de benzène dans les locaux contigus aux pièces occupées par
          les usagers.
        </>,
        {
          autodiagStockageAppareilMoteurThermique: [
            'Local de stockage d’appareils à moteurs thermiques',
            'Local de stockage d’appareils à moteurs thermiques pour l’entretien d’espaces verts',
          ],
          autodiagStockageHydrocarbures: [
            'Local de stockage des hydrocarbures',
            'Local de stockage des hydrocarbures, bidons d’essence, cuve à fioul…',
          ],
        },
      ],
    },
    formaldehyde: {
      noChoiceField: 'autodiagMaintenanceAucunFormaldehyde',
      otherChoiceLabel: 'Autres sources potentiellement émettrices',
      otherChoiceField: 'autodiagMaintenanceFormaldehydeAutre',
      commentField: 'autodiagMaintenanceFormaldehydeComment',
      choiceFields: [
        <>
          Recenser les sources potentiellement émettrices de formaldéhyde. Un listing indicatif est proposé ci-dessous
          pour vous aider dans votre recensement.
        </>,
        {
          autodiagFauxPlafond: ['Faux plafond endommagé', ''],
          autodiagCharpentAgglomere: ['Charpente en bois aggloméré apparente', ''],
          autodiagIsolantAcoustique: ['Utilisation d’isolant acoustique', ''],
        },
      ],
    },
    practises: {
      'Remarques générales': [
        undefined,
        {
          autodiagGeneralProduitEntretien: [
            'Matériels et produits d’entretien rangés dans un local dédié et sécurisé',
            <>
              Ranger les matériels et produits d’entretien du site (ménage, désinfection, entretien des espaces verts…)
              dans un local non occupé par les usagers, d’accès sécurisé et pouvant être aéré. Voir le{' '}
              {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
          autodiagGeneralLocalTechniqueEvent: [
            'Évents vers l’extérieur pour les locaux techniques',
            'Les locaux techniques (chaufferie, local ménage, stockage, cuisine…) doivent avoir des évents vers l’extérieur afin de limiter la diffusion des émissions vers l’intérieur des locaux.',
          ],
          autodiagGeneralRejetLoinEntreeAir: [
            'Rejets de l’air des locaux à plus de 8 m des ouvrants et entrées d’air',
            <>
              L’air extrait des locaux (et notamment techniques, cuisines, en travaux) est rejeté à au moins 8 m des
              ouvrants et/ou entrées d’air du bâtiment (Voir la « Fiche informative : Positionnement des entrées d’air
              dans le bâtiment et positionnement du bâtiment par rapport aux sources de pollution » à la page 138 du
              {GUIDE_CEREMA} et le Réglement Sanitaire Départemental)
            </>,
          ],
          autodiagGeneralSourceLoinEntreeAir: [
            'Source de pollution située à plus de 8 m des entrées d’air et ouvrants',
            <>
              Les ouvrants et entrées d’air sont placés à au moins 8 m de toute source potentielle de pollution comme
              des places de stationnement, arrêt de bus, bennes à ordure, zones de travaux… (Voir la « Fiche informative
              : Positionnement des entrées d’air dans le bâtiment et positionnement du bâtiment par rapport aux sources
              de pollution » à la page 138 du {GUIDE_CEREMA} et le Réglement Sanitaire Départemental)
            </>,
          ],
          autodiagGeneralDrainage: [
            'Vérification du système de drainage des eaux pluviales',
            'Afin de prévenir des risques d’infiltration, vérifier que le drainage des eaux pluviales s’effectue correctement',
          ],
          autodiagGeneralEssuyagePieds: [
            'Système d’essuyage de pieds à chaque entrée',
            'Avoir un système d’essuyage des pieds à chaque entrée de bâtiment pour réduire les apports extérieurs et limiter des remises en suspension potentielles de particules',
          ],
          autodiagGeneralRemarquesPersonnelEntretien: [
            'Prise en compte des remarques du personnel d’entretien',
            'Prendre connaissance des remarques formulées par le personnel d’entretien',
          ],
        },
      ],
      Cuisine: [
        undefined,
        {
          autodiagCuisineAppareilCombustion: [
            '️Bon fonctionnement des appareils à combustion',
            'Vérifier que les appareils à combustion fonctionnent correctement',
          ],
          autodiagCuisineVentilation: [
            '️Bonne ventilation de la pièce, et système d’extraction en bon état',
            'S’assurer que la pièce est bien ventilée et que les systèmes d’extraction fonctionnent',
          ],
          autodiagCuisineContratMaintenance: [
            'Contrat de maintenance des systèmes à jour',
            'Vérifier l’existence d’un contrat de maintenance des systèmes',
          ],
        },
      ],
      'Matériaux de construction, revêtements et mobiliers': [
        undefined,
        {
          autodiagMateriauxBilan: [
            'Bilan du potentiel émissif des matériaux et revêtements',
            <>
              Faire un bilan du potentiel émissif des matériaux et revêtements présents dans les pièces occupées (des
              informations peuvent être obtenues sur le site{' '}
              <a href="https://www.inies.fr" {...TARGET_BLANK}>
                INIES
              </a>
              )
            </>,
          ],
          autodiagMateriauxAChanger: [
            '️Priorisation des matériaux et revêtements émissifs à changer',
            <>
              Utiliser le bilan du potentiel émissif des matériaux et revêtements pour identifier les matériaux et
              revêtements à changer en priorité, dans les prochains travaux, de par leur potentiel émissif
            </>,
          ],
        },
      ],
      'Opérations de désinfection': [
        undefined,
        {
          autodiagDesinfectionProduits: [
            '️Utilisation des produits conformes aux instructions d’emploi',
            'Lors d’opérations de désinfection, utiliser les produits conformément aux instructions d’emploi (pas de mélange, pas de surdosage)',
          ],
          autodiagDesinfectionPesticides: [
            '️Minimisation de l’application des pesticides',
            'Minimiser l’application de pesticides (étude des alternatives possibles, utilisation localisée, pictogrammes de danger sur les produits à prendre en compte…)',
          ],
          autodiagDesinfectionAeration: [
            'Désinfection des locaux en absence de fréquentation, en aérant ensuite',
            'Conduire les opérations de désinfection des locaux (traitements nuisibles, du bois) en dehors des plages de fréquentation de l’établissement (vacances scolaires), avec des pratiques d’aération appropriées',
          ],
        },
      ],
      'Travaux extérieurs': [
        undefined,
        {
          autodiagTravauxEmanations: [
            '️Surveillance des transferts d’émanations depuis les chantiers proches',
            'En cas de zones de chantier à l’extérieur du bâtiment, rester vigilant sur un transfert éventuel de leurs émanations via les ouvrants et entrées d’air',
          ],
        },
      ],
      // atention d'avoir des titres qui ne dépendent pas du contexte "Avant travaux / Pendant les travaux pour le plan d'action"
      'Avant travaux': [
        undefined,
        {
          autodiagAvantTravauxInoccupation: [
            '️Tâches émissives des travaux à planifier pour minimiser les impacts',
            'Planifier les tâches émissives pendant les périodes inoccupées de l’établissement en prévoyant un délai entre la fin des travaux et la réintégration des usagers',
          ],
          autodiagAvantTravauxAeration: [
            '️Planification d’un temps d’aération suffisant en fin de chantier',
            'Dans la planification du chantier, prévoir un temps d’aération suffisant et nécessaire à la fin de celui-ci',
          ],
          autodiagAvantTravauxConfinement: [
            'Confinement des zones de travaux pour réduire l’impact',
            'Confiner la zone de travaux afin de réduire l’impact sur les espaces occupés à proximité (attention particulière à porter au système de ventilation)',
          ],
          autodiagAvantTravauxSpecificationsQAI: [
            'Spécifications QAI pour tout prestataire lors de travaux',
            'En cas d’exécution des travaux par un prestataire, prévoir des spécifications de QAI dans le contrat de prestation, de type utilisation de produits peu émissifs, confinement de la zone de travaux, aération pendant les phases émissives…',
          ],
        },
      ],
      'Pendant les travaux': [
        undefined,
        {
          autodiagPendantTravauxNettoyage: [
            '️Augmentation du nettoyage humide lors des phases poussiéreuses',
            'Augmenter les fréquences de ménage pendant la période de travaux (privilégier un nettoyage humide), notamment lors d’opérations générant de l’empoussièrement',
          ],
          autodiagPendantTravauxAeration: [
            '️Aération importante et interdiction de fumer sur le chantier',
            'Procéder à d’importantes phases d’aération du chantier et s’assurer de l’absence de fumée de cigarettes sur le chantier',
          ],
        },
      ],
      'Après travaux': [
        undefined,
        {
          autodiagApresTravauxFiltres: [
            '️Changement des filtres des systèmes de ventilation en fin de chantier',
            'Changement des filtres des systèmes de ventilation après travaux, si l’établissement a une VMC avec filtre.',
          ],
          autodiagApresTravauxGrilles: [
            '️Nettoyage des grilles et bouches de ventilation en fin de chantier',
            'Faire nettoyer les grilles d’aération et bouches de ventilation après travaux.',
          ],
          autodiagApresTravauxNettoyage: [
            'Nettoyage humide avant réintégration des occupants en fin de chantier',
            'Faire réaliser un nettoyage humide en profondeur (murs, sols, mobiliers) des locaux avant réintégration des occupants après travaux.',
          ],
          autodiagApresTravauxAeration: [
            'Ventilation et aération des locaux en fin de chantier',
            'Bien ventiler et aérer les locaux à la fin du chantier avant réintégration des occupants après travaux.',
          ],
        },
      ],
      'Aération et ventilation': [
        undefined,
        {
          autodiagVentilationProduitsChimiques: [
            '️Ventilation pendant et après utilisation de produits chimiques',
            'De façon générale, ventiler les locaux pendant et après l’utilisation de produits chimiques odorants ou munis de pictogrammes de danger (dont l’utilisation doit être limitée)',
          ],
          autodiagVentilationNettoyageGrilles: [
            '️Nettoyage des grilles, des entrées d’air, des bouches d’extraction',
            'Nettoyer et dépoussiérer les grilles, entrées d’air, bouches d’extraction au moins une fois par an',
          ],
          autodiagVentilationCorrectionEvaluation: [
            'Correction des défauts relevés lors de la précédente évaluation',
            <>
              S’assurer que les potentiels défauts identifiés lors de la dernière évaluation obligatoire des moyens
              d’aération ont été corrigés{NBSP}:
              <br />– Ouvrants en état de fonctionnement (effectivement ouvrables)
              <br />– Ouvrants facilement accessibles (ouvrables sans obstacle ni action supplémentaire nécessaire)
              <br />– Ouvrants facilement manoeuvrables (ouvrables par un adulte sans effort particulier),
            </>,
          ],
          autodiagVentilationFiltres: [
            'Changement des filtres de la ventilation mécanique le cas échéant',
            <>
              En cas de présence de système de ventilation mécanique, changer régulièrement les filtres lorsque les
              équipements de ventilation en comportent.
            </>,
          ],
        },
      ],
    },
  },
  entretien: {
    nom: 'Entretien',
    nomLong: 'Entretien et nettoyage',
    functionDescription: 'Personnel d’entretien',
    nameField: 'responsableEntretien',
    functionField: 'fonctionEntretien',
    emailField: 'email Entretien',
    dateField: 'dateEntretien',
    practises: {
      'Nettoyage des locaux': [
        undefined,
        {
          autodiagEntretienConnaissance: [
            'Connaissance des bonnes pratiques fournies par la collectivité',
            <>
              Prendre connaissance des bonnes pratiques (recommandations fournies par la collectivité) via un support de
              communication adaptée (affiche, flyer…). Voir par exemple l’{AFFICHE_ECOLAIR_QAI}.
            </>,
          ],
          autodiagEntretienPoubelles: [
            '️Ramassage quotidien des poubelles dans les pièces occupées',
            <>
              Veiller à ramasser quotidiennement les poubelles dans les pièces occupées. Voir le{' '}
              {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
          autodiagEntretienToilettes: [
            'Nettoyage quotidien attentionné des toilettes',
            <>
              Porter une attention particulière pour le nettoyage quotidien des toilettes. Voir le{' '}
              {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
          autodiagEntretienHumide: [
            '️Préférence du nettoyage humide des sols et du mobilier',
            <>
              Privilégier un nettoyage humide des sols et du mobilier pour éviter la remise en suspension des
              poussières. Voir le {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
          autodiagEntretienInstructionsProduits: [
            'Utilisation des produits d’entretien suivant les instructions d’emploi',
            <>
              Utiliser les produits d’entretien conformément aux instructions d’emploi (pas de mélange, pas de surdosage
              pour éviter tout risque de réaction chimique non contrôlée et potentiellement dangereuse). Voir le{' '}
              {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
          autodiagEntretienRangementProduits: [
            'Rangement des produits de nettoyage dans des locaux dédiés',
            <>
              Veiller à ranger l’ensemble des produits de nettoyage dans les locaux prévus à cet effet. Voir le{' '}
              {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
          autodiagEntretienLimitationProduits: [
            '️Limitation du nombre de produits d’entretien différents',
            <>Privilégier un nombre limité de produits d’entretien différents. Voir le {GUIDE_ECOLAIR_ENTRETIEN}.</>,
          ],
          autodiagEntretienProduitsNaturel: [
            '️Préférence pour les produits naturels',
            <>
              Privilégier, autant que possible, des produits naturels (nettoyage à la vapeur, vinaigre blanc,
              bicarbonate de soude, savon noir…). Voir le {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
          autodiagJavel: ['️Limitation de l’utilisation de l’eau de Javel', <>Voir le {GUIDE_ECOLAIR_ENTRETIEN}.</>],
        },
      ],
      Aération: [
        undefined,
        {
          autodiagEntretienAeration: [
            '️Aération des pièces pendant et après les activités de nettoyage',
            <>
              Aérer les pièces pendant et après les activités de nettoyage, en veillant à refermer les ouvrants
              extérieurs avant de quitter les lieux. Voir le {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
          autodiagEntretienAerationTransversale: [
            '️Préférence pour aération transversale et en grand',
            <>
              Privilégier l’aération transversale (portes et fenêtres grandes ouvertes) ou en grand (battants des
              fenêtres entièrement ouverts) des pièces nettoyées. Voir le {GUIDE_ECOLAIR_ENTRETIEN}.
            </>,
          ],
        },
      ],
    },
    observations: {
      noChoiceField: 'autodiagEntretienAucuneObservation',
      otherChoiceLabel: 'Autres remarques',
      otherChoiceField: 'autodiagEntretienAutreObservation',
      commentField: 'autodiagEntretienComment',
      choiceFields: [
        undefined,
        {
          autodiagEntretienMoisissure: [
            'Présence de moisissures sur les surfaces',
            <>Voir la {FICHE_ECOLAIR_MOISISSURES}.</>,
          ],
          autodiagEntretienCondensation: [
            'Phénomènes de condensation récurrents',
            <>Voir la {FICHE_ECOLAIR_MOISISSURES}.</>,
          ],
          autodiagEntretienInfiltration: [
            'Signes d’infiltration',
            <>
              Traces sur les murs ou plafonds, peinture écaillée, papier peint décollé, fuites… Voir la{' '}
              {FICHE_ECOLAIR_MOISISSURES}
            </>,
          ],
          autodiagEntretienFuiteEau: ['Fuite d’eau (sanitaire, éviers…)', <>Voir la {FICHE_ECOLAIR_MOISISSURES}.</>],
          autodiagEntretienManoeuvreOuvrant: [
            'Défauts entravant la manœuvrabilité des ouvrants',
            <>Voir la {FICHE_ECOLAIR_AERATION}.</>,
          ],
          autodiagEntretienBruitVentilation: [
            'Bruit inhabituel du système mécanique de ventilation ou son arrêt total',
            <>Voir le {GUIDE_ECOLAIR_VENTILATION}.</>,
          ],
          autodiagEntretienEncrassementGrilles: [
            'Encrassement des grilles d’aération, entrées d’air et bouches d’extraction',
            <>Voir le {GUIDE_ECOLAIR_VENTILATION}.</>,
          ],
          autodiagEntretienProduitHorsLocal: [
            'Produits spécifiques non rangés dans les locaux dédiés',
            <>Voir le {GUIDE_ECOLAIR_ENTRETIEN}.</>,
          ],
          autodiagEntretienOdeur: [
            'Odeur inhabituelle ressentie',
            <>
              Voir le {GUIDE_ECOLAIR_VENTILATION} et la {FICHE_ECOLAIR_AERATION}.
            </>,
          ],
        },
      ],
    },
  },
  activites: {
    nom: 'Activités',
    nomLong: 'Gestion des activités pédagogiques, artistiques, culturelles, etc.',
    functionDescription: 'Responsable d’activités',
    nameField: 'responsableActivites',
    functionField: 'fonctionActivites',
    emailField: 'emailActivites',
    dateField: 'dateActivites',
    practises: {
      Equipements: [
        undefined,
        {
          autodiagChauffageAppoint: [
            'Absence d’appareil de chauffage d’appoint au fuel ou au pétrole',
            <>
              Ne pas utiliser d’appareil de chauffage d’appoint (pétrole, fioul) qui peuvent générer des substances
              dangereuses qui nécessitent une aération ou ventilation spécifique. Voir la{' '}
              {FICHE_CEREMA_EQUIPEMENTS_ACTIVITES_EMISSIVES}.
            </>,
          ],
          autodiagAppareils: [
            '️Absence de photocopieur, imprimante et télécopieur dans la pièce',
            <>
              Vérifier l’absence de photocopieurs, imprimantes, télécopieurs au sein de la pièce (peut générer des
              substances dangereuses tels que l’ozone, formaldéhyde qui nécessitent une aération ou ventilation
              spécifique). Voir la {FICHE_CEREMA_EQUIPEMENTS_ACTIVITES_EMISSIVES}.
            </>,
          ],
        },
      ],
      'Aération et ventilation': [
        undefined,
        {
          autodiagAerationActivitesEmissives: [
            '️Renouvellement de l’air lors d’activités potentiellement émissives',
            <>
              Renouveler l’air des locaux (ouverture des portes et fenêtres) lors d’activités pédagogiques
              potentiellement émissives (peintures, collages…). Voir la {FICHE_ECOLAIR_AERATION}.
            </>,
          ],
          autodiagAerationSalleTP: [
            'Renouvellement de l’air si une salle de travaux pratiques est mitoyenne',
            <>
              Renouveler l’air des locaux (ouverture des portes, fenêtres) si la pièce est mitoyenne avec une salle de
              travaux pratiques dans laquelle des activités potentiellement émissives ont eu lieu dans la journée. Voir
              la {FICHE_ECOLAIR_AERATION}.
            </>,
          ],
          autodiagAerationPauseMeridienne: [
            '️Aération après la pause de midi en cas de cantine ou cuisine mitoyenne',
            <>
              Renouveler l’air des locaux (ouverture des portes, fenêtres) après la pause méridienne si la pièce est
              mitoyenne avec la cantine, réfectoire ou cuisine. Voir la {FICHE_ECOLAIR_AERATION}.
            </>,
          ],
          autodiagAerationPauses: [
            '️Renouvellement de l’air pendant les pauses',
            <>
              Renouveler l’air des locaux (ouverture des portes, fenêtres) pendant les pauses (intercours, pause
              méridienne). Voir la {FICHE_ECOLAIR_AERATION}.
            </>,
          ],
          autodiagAccessibiliteOuvrants: [
            '️Ouvrants, grilles d’aération et bouches de ventilation accessibles',
            <>
              Lors de l’agencement des pièces, faire en sorte que l’ensemble des ouvrants, grilles d’aération et bouches
              de ventilation reste accessible. Voir les {CONSEILS_CEREMA_ENTRETIEN_AERATION_VENTILATION}.
            </>,
          ],
          autodiagRapportEvaluation: [
            '️Connaissance du rapport d’évaluation annuel des moyens d’aération',
            'Connaissance des résultats du rapport annuel obligatoire de l’évaluation des moyens d’aération',
          ],
          autodiagCapteurCO2: [
            'Évaluation et optimisation de l’aération avec capteur de CO2',
            <>
              Si un indicateur de confinement (capteur CO2) est disponible dans l’établissement, l’utiliser pour
              optimiser les pratiques d’aération déjà en place conformément à leur notice d’utilisation. Voir le{' '}
              {GUIDE_CSTB} pour le choix des capteurs.
            </>,
          ],
        },
      ],
      'Activités pédagogiques, artistiques, culturelles…': [
        undefined,
        {
          autodiagMaterielMotricite: [
            'Renouvellement de matériel de motricité écolabellisé',
            <>
              Lors de l’achat ou renouvellement de matériel de motricité, privilégier ceux écolabellisés (Écolabel
              européen, NF Environnement Éducation…). Voir la {FICHE_ECOLAIR_MOBILIER}.
            </>,
          ],
          autodiagLocalProduitsEmissifs: [
            '️Stockage des produits émissifs dans un local dédié, indépendant',
            <>
              Privilégier le stockage des produits utiles aux activités (peintures, colles…) dans un local de rangement
              spécifique, ventilé, et si possible ne communiquant pas avec la pièce. Voir la {FICHE_ECOLAIR_FOURNITURES}
              .
            </>,
          ],
          autodiagMaterielEco: [
            'Préférence pour feutres et marqueurs écolabellisés',
            <>
              Privilégier l’utilisation de feutres et marqueurs écolabellisés (Écolabel européen, NF Environnement, Der
              Blaue Engel, Nordic Environment, Öko-test…) ou des crayons en bois adaptés aux tableaux blancs. Voir la{' '}
              {FICHE_ECOLAIR_FOURNITURES}.
            </>,
          ],
          autodiagNettoyageSansAerosol: [
            '️Nettoyage de tableau à sec ou à l’eau, sans utiliser de bombe aérosol',
            <>
              Nettoyer le tableau à sec (tampon) ou uniquement avec de l’eau (ne pas utiliser de bombes aérosols). Voir
              la {FICHE_CEREMA_EQUIPEMENTS_ACTIVITES_EMISSIVES} la {FICHE_ECOLAIR_FOURNITURES}.
            </>,
          ],
          autodiagBougieEncens: [
            'Interdiction des bougies d’ambiance et encens',
            <>Ne pas utiliser de bougies d’ambiance ni faire brûler de l’encens. Voir la {FICHE_ECOLAIR_AMBIANCE}.</>,
          ],
          autodiagDiffusion: [
            'Pad d’épurateurs, aérosols, désodorisants et parfums d’ambiance',
            <>
              Ne pas avoir recours à des épurateurs d’air, des bombes aérosols, des désodorisants, parfums d’ambiance,
              diffuseurs d’huiles essentielles y compris lorsque la pièce est inoccupée. Voir la{' '}
              {FICHE_ECOLAIR_AMBIANCE}.
            </>,
          ],
          autodiagAnimauxPlantes: [
            '️Limitation du nombre d’animaux et de plantes',
            <>
              Voir la {PAGE_ALLERGENES_OQAI} et le {PROJET_PHYTAIR_OQAI}.
            </>,
          ],
          autodiagNettoyageCages: [
            '️Nettoyage régulier des cages des animaux présents dans la pièce',
            <>
              Si un ou des animaux sont présents dans la pièce, veiller à ce qu’ils soient dans des cages, nettoyées
              régulièrement. Voir la {PAGE_ALLERGENES_OQAI}.
            </>,
          ],
          autodiagStockageNourriture: [
            '️Stockage de nourriture dans des contenants fermés hermétiquement',
            <>
              Ne pas stocker de nourriture dans la pièce ou alors dans des contenants fermés hermétiquement, afin
              d’éviter générer de nombreuses substances chimiques et ainsi dégrader la qualité de l’air intérieur.
            </>,
          ],
          autodiagLavageMains: [
            '️Lavage des mains des enfants à l’eau et au savon',
            <>
              Pour le nettoyage des mains des enfants, privilégier le lavage à l’eau et au savon et éviter l’usage des
              lingettes, afin d’éviter générer de nombreuses substances chimiques et ainsi dégrader la qualité de l’air
              intérieur.
            </>,
          ],
          autodiagGuideAchat: [
            'Information des parents d’élèves sur le guide « Le Cartable Sain »',
            <>
              Informer les parents d’élèves de l’existence du guide d’achat des fournitures scolaires{' '}
              <a href="http://www.cartable-sain-durable.fr/" {...TARGET_BLANK}>
                « Le Cartable Sain »
              </a>{' '}
              en le mentionnant notamment dans la liste des fournitures scolaires.
            </>,
          ],
        },
      ],
    },

    observations: {
      noChoiceField: 'autodiagActivitesAucuneObservation',
      otherChoiceLabel: 'Autres remarques',
      otherChoiceField: 'autodiagActivitesAutreObservation',
      commentField: 'autodiagActivitesComment',
      choiceFields: [
        undefined,
        {
          autodiagManoeuvrabiliteOuvrants: [
            'Défaut entravant la manœuvrabilité des ouvrants',
            <>Voir la {FICHE_ECOLAIR_AERATION}.</>,
          ],
          autodiagEncrassementGrilles: [
            'Encrassement des grilles, entrées d’air, bouches d’aération',
            <>Voir le {GUIDE_ECOLAIR_VENTILATION}.</>,
          ],
          autodiagBruitVentilation: [
            'Bruit inhabituel du système de ventilation, ou son arrêt total',
            <>Voir le {GUIDE_ECOLAIR_VENTILATION}.</>,
          ],
          autodiagEmpoussierement: ['Zones d’empoussièrement spécifiques', <>Voir le {GUIDE_ECOLAIR_VENTILATION}.</>],
          autodiagMoisissures: [
            'Présence ou apparition de moisissures sur les surfaces',
            <>Voir la {FICHE_ECOLAIR_MOISISSURES}.</>,
          ],
          autodiagOdeur: [
            'Odeurs inhabituelles ressenties',
            <>
              Voir le {GUIDE_ECOLAIR_VENTILATION} et la {FICHE_ECOLAIR_AERATION}.
            </>,
          ],
          autodiagCondensation: [
            'Phénomènes de condensation récurrents sur les fenêtres ou portes-fenêtres, surfaces froides',
            <>Voir la {FICHE_ECOLAIR_MOISISSURES}.</>,
          ],
          autodiagInfiltration: [
            'Signes d’infiltration',
            <>
              Humidité, trace sur les murs ou plafonds, peinture écaillée, papier peint décollé… Voir la{' '}
              {FICHE_ECOLAIR_MOISISSURES}.
            </>,
          ],
          autodiagNuisibles: [
            'Présence de nuisibles : cafards, fourmis, rongeurs…',
            <>
              Veillez à la ventilation et l’aération après désinfestation avec le {GUIDE_ECOLAIR_VENTILATION} et la{' '}
              {FICHE_ECOLAIR_AERATION}.
            </>,
          ],
          autodiagInconfortThermique: [
            'Inconfort thermique significatif',
            <>
              Voir le {GUIDE_ECOLAIR_VENTILATION} et la {FICHE_ECOLAIR_AERATION}.
            </>,
          ],
          autodiagProduitsSpecifiquesHorsLocal: [
            'Produits spécifiques non rangés dans les locaux dédiés',
            <>Voir la {FICHE_ECOLAIR_NETTOYAGE_ECO}.</>,
          ],
        },
      ],
    },
  },
};

const DRAFT_SAVE_ID_PREFIX = 'draft-';

export interface CheckboxFieldsProps {
  label: string;
  fields: CheckboxFields;
  thing: Room | Org;
  control: Control<any>;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  errors: DeepMap<any, FieldError>;
  setValue: UseFormSetValue<any>;
}

function CheckboxField({ label, fields, register, control, watch, setValue, errors, thing }: CheckboxFieldsProps) {
  const noChoiceField = fields.noChoiceField;
  const otherChoiceField = fields.otherChoiceField;
  const commentField = fields.commentField;
  const [tooltip, choices] = fields.choiceFields;
  return (
    <>
      <TooltipField
        label={label}
        tooltip={tooltip}
        error={errors[noChoiceField] || (commentField && errors[commentField])}
      >
        <VerticalGroup>
          {Object.entries(choices).map(([field, labelDescriptionDependent]) => (
            <TooltipCheckbox
              control={control}
              key={field}
              name={field}
              defaultChecked={thing[field]}
              label={labelDescriptionDependent[0]}
              tooltip={labelDescriptionDependent[1]}
              dependentName={labelDescriptionDependent[2]}
              setValue={setValue}
            />
          ))}
          {otherChoiceField && (
            <VerticalGroup>
              <TooltipCheckbox
                control={control}
                name={otherChoiceField}
                defaultChecked={thing[otherChoiceField]}
                label={fields.otherChoiceLabel}
              />
              {commentField && watch(otherChoiceField, thing[otherChoiceField]) && (
                <AutosizeTextArea
                  field={commentField}
                  control={control}
                  options={{
                    validate: (v: any) => {
                      if (!v) {
                        return `Description obligatoire pour les ${lowerCaseFirstLetter(fields.otherChoiceLabel)}.`;
                      }
                      return true;
                    },
                  }}
                  defaultValue={thing[commentField]}
                  defaultRows={2}
                />
              )}
            </VerticalGroup>
          )}
          <TooltipCheckbox
            control={control}
            options={{
              validate: (v) => {
                const someRealChoice =
                  Object.keys(fields.choiceFields[1]).some((k) => watch(k, thing[k])) ||
                  (otherChoiceField && watch(otherChoiceField, thing[otherChoiceField]));
                if (v && someRealChoice) {
                  return 'L’option « Aucune » ne peut pas être cochée avec une autre option.';
                }
                if (!v && !someRealChoice) {
                  return 'Au moins une option doit être cochée.';
                }
                return true;
              },
            }}
            name={noChoiceField}
            defaultChecked={thing[noChoiceField]}
            label="Aucune des situations ci-dessus"
          />
        </VerticalGroup>
      </TooltipField>
    </>
  );
}

export interface AutodiagProps<T extends Org | Room> {
  report: Report;
  tab: AutodiagFormTab;
  thing: T;
  doSubmit: (saveIdPrefix: string) => SubmitHandler<T>;
  setSaveState: (saveId: string, saveState: SaveState) => void;
}

export function Autodiag<T extends Org | Room>({ tab, thing, report, doSubmit, setSaveState }: AutodiagProps<T>) {
  const styles = useStyles2(getStyles);
  const fields = AUTODIAG_TAB_FIELDS[tab];
  return (
    <Form<any> onSubmit={doSubmit('')} maxWidth={LARGE_FORM_MAX_WIDTH}>
      {({ register, errors, control, watch, setValue, getValues }) => (
        <>
          <input {...register('id')} type="hidden" value={thing.id} />
          <input {...register('nom')} type="hidden" value={thing.nom} />
          <SmallFieldSet
            label={fields.functionDescription}
            tooltip={
              <>
                <p>
                  L’autodiagnostic de <em>{lowerCaseFirstLetter(fields.nomLong)}</em> est à renseigner par le{' '}
                  {lowerCaseFirstLetter(fields.functionDescription)}.
                </p>
                <p>
                  L’autodiagnostic obligatoire doit être réalisée ou mise à jour régulièrement, au minimum tous les 4
                  ans.
                </p>
                <p>
                  L’élaboration de ce diagnostic partagé des éléments impactant la qualité de l’air intérieur repose sur
                  la mobilisation de l’ensemble des acteurs. Il reste vivement conseillé de se nourrir des observations
                  de chacun pour compléter l’évaluation, quel que soit son rôle au sein de l’établissement.
                </p>
                <p>
                  L’autodiagnostic porte notamment sur{NBSP}:
                  <ul className={styles.list}>
                    <li>
                      l’identification et les moyens de réduction des sources d’émission de polluants, qu’ils
                      proviennent des matériaux, des équipements ou des activités réalisées{NBSP};
                    </li>
                    <li>l’entretien des systèmes de ventilation et des moyens d’aération de l’établissement{NBSP};</li>
                    <li>
                      la diminution de l’exposition des occupants aux polluants résultant en particulier des travaux et
                      des activités de nettoyage.
                    </li>
                  </ul>
                </p>
                <p>
                  Le résultat de l’autodiagnostic est assimilable à un état des lieux des forces et faiblesses sur la
                  qualité de l’air intérieur à un temps donné. Les items non validés auront vocation à être priorisés et
                  déclinés en actions correctives planifiées pour alimenter le plan d’actions. À noter que
                  l’autodiagnostic réalisé dans le cadre de cette réglementation ne se substitue pas aux autres
                  diagnostics obligatoires auxquels peut être soumis le bâtiment (amiante, plomb, performance
                  énergétique, ventilation, etc.), ni au dispositif réglementaire de surveillance périodique du radon
                  pour les communes concernées.
                </p>
                <p>
                  (Source{NBSP}: pages 34-35 du {GUIDE_CEREMA})
                </p>
              </>
            }
          >
            <TooltipField label="Nom" error={errors[fields.nameField]}>
              <CreatableSelect
                autoFocus={true}
                rules={REQUIRED}
                name={fields.nameField}
                autoComplete="name"
                defaultValue={thing?.[fields.nameField]}
                control={control}
                register={register}
                additionalOnChange={(v) => {
                  const name = report.names.find((n) => n.nom === v);
                  if (name) {
                    setValue(fields.emailField, name.email);
                    setValue(fields.functionField, name.function);
                  }
                }}
                placeholder={PERSON_PLACEHOLDER}
                defaultOptions={makeNameOptions(report.names)}
              />
            </TooltipField>
            <TooltipField label="Courriel" error={errors[fields.emailField]}>
              <EmailInput
                field={fields.emailField}
                register={register}
                defaultValue={thing?.[fields.emailField]}
                placeholder={OPTIONAL}
              />
            </TooltipField>
            <TooltipField label="Fonction" error={errors[fields.functionField]}>
              <Input
                {...register(fields.functionField, REQUIRED)}
                autoComplete="organization-title"
                defaultValue={thing?.[fields.functionField]}
              />
            </TooltipField>
            <TooltipField
              label="Date"
              tooltip={
                <>
                  L’heure peut être utilisée pour planifier le remplissage de ce formulaire. Seule la date apparait dans
                  le rapport.
                </>
              }
              error={errors[fields.dateField]}
            >
              <DateTimeInput
                field={fields.dateField}
                register={register}
                options={REQUIRED}
                defaultValue={thing?.[fields.dateField]}
              />
            </TooltipField>
          </SmallFieldSet>
          {fields.benzene && fields.formaldehyde && (
            <SmallFieldSet label={POLLUTANT_SOURCE}>
              <CheckboxField
                label="Benzène"
                fields={fields.benzene}
                errors={errors}
                register={register}
                control={control}
                watch={watch}
                setValue={setValue}
                thing={thing}
              />
              <CheckboxField
                label="Formaldéhyde"
                fields={fields.formaldehyde}
                errors={errors}
                register={register}
                control={control}
                watch={watch}
                setValue={setValue}
                thing={thing}
              />
            </SmallFieldSet>
          )}
          {Object.entries(fields.practises).map(([group, practises]) => {
            const [tooltip, practiseFields] = practises;
            return (
              <SmallFieldSet key={group} label={group} tooltip={tooltip}>
                {Object.entries(practiseFields).map(([field, labelDescriptionDependent]) => (
                  <TooltipField
                    key={field}
                    label={labelDescriptionDependent[0]}
                    tooltip={labelDescriptionDependent[1]}
                    error={errors[field]}
                  >
                    <GoodPractiseInput<Org | Room> field={field} fieldValues={thing} control={control} />
                  </TooltipField>
                ))}
              </SmallFieldSet>
            );
          })}
          {fields.observations && (
            <SmallFieldSet label="À signifier à l’équipe de gestion">
              <CheckboxField
                label="Observations"
                fields={fields.observations}
                errors={errors}
                register={register}
                control={control}
                watch={watch}
                setValue={setValue}
                thing={thing}
              />
            </SmallFieldSet>
          )}
          <HorizontalGroup align="flex-start" justify="flex-end">
            <SaveButton
              onClick={() => doSubmit(DRAFT_SAVE_ID_PREFIX)(getValues())}
              type="button"
              defaultVariant="secondary"
              label="Sauvegarder le brouillon"
              state={report.editState.autodiag[tab].saving[DRAFT_SAVE_ID_PREFIX + thing.id]}
              setState={(s) => setSaveState(DRAFT_SAVE_ID_PREFIX + thing.id, s)}
            />
            <SaveButton
              label="Sauvegarder"
              state={report.editState.autodiag[tab].saving[thing.id]}
              setState={(s) => setSaveState(thing.id, s)}
            />
          </HorizontalGroup>
        </>
      )}
    </Form>
  );
}
