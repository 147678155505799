import { ReactElement } from 'react';
import reactToText from 'react-to-text';
import { NBSP } from 'utils/utils.form';
import { Action, ActionStatus, NO_OBTURATION, Report, ReportType, hasFilter, reportDate } from 'utils/utils.model';
import { AnnuelTab, AutodiagTab } from 'utils/utils.routing';
import { LESS_THAN_1500_PPM_RESULT, MORE_THAN_1500_PPM_RESULT } from './AnnuelCO2SceneObject';
import { AUTODIAG_TAB_FIELDS, CheckboxFields } from './Autodiag';

interface Proposal {
  sourceReportType: ReportType;
  sourceTab: AnnuelTab | AutodiagTab;
  condition: (report: Report, reportType: ReportType) => boolean;
  sourceType: string;
  nom: string;
  description: (report: Report) => string;
  comment: (report: Report) => string;
}

const checkBoxFieldList = (fields: CheckboxFields | undefined, report: Report) =>
  `
- ` +
  Object.entries(fields?.choiceFields[1] ?? [])
    .filter(([key]) => report.org[key] === true)
    .map(([, labelAndDesc]) => labelAndDesc[0]).join(`
- `);

const makeProposal = (tab: string, key: string, label: string, description: string | ReactElement): Proposal => {
  return {
    sourceReportType: 'autodiag',
    sourceTab: tab,
    condition: (report: Report) =>
      tab === AutodiagTab.activites ? report.autodiagRooms.some((r) => r[key] === false) : report.org[key] === false,
    sourceType: key,
    nom: label,
    description: () => reactToText(description),
    comment: () => '',
  } as Proposal;
};

const PROPOSALS: Proposal[] = (
  [
    {
      sourceReportType: 'annuel',
      sourceTab: AnnuelTab.aeration,
      condition: (report: Report) =>
        report.annuelRooms.some(
          (r) => r.ouvrable < r.ouvrants || r.accessible < r.ouvrants || r.manoeuvrable < r.ouvrants
        ),
      sourceType: 'ouvrants',
      nom: 'Rendre accessibles et manœuvrables l’ensemble des ouvrants',
      description: () =>
        'S’assurer que tous les ouvrants (portes, fenêtres, portes-fenêtres) sont facilement ouvrables et accessibles afin de permettre l’aération par les occupants de la salle.',
      comment: () => '',
    },
    {
      sourceReportType: 'annuel',
      sourceTab: AnnuelTab.aeration,
      condition: (report: Report) =>
        report.annuelRooms.some(
          (r) => r.fonctionne === false || (r.obture && r.obture !== NO_OBTURATION) || r.encrasse === true
        ),
      sourceType: 'grilles',
      nom: 'Maintenance des bouches et grilles',
      description: () => `S’assurer que les bouches et grilles d'aération soient dépoussiérées et non obturées`,
      comment: () => `- ne rien obturer : les rideaux et meubles doivent être à une distance suffisante
- dépoussiérer régulièrement avec un chiffon sec
- nettoyer à l’eau savonneuse tous les 6 mois
`,
    },
    {
      sourceReportType: 'annuel',
      sourceTab: AnnuelTab.aeration,
      condition: (report: Report, reportType: ReportType) => {
        const reportDateMinusOneYear = reportDate(reportType, report);
        return report.annuelZones.some((z) => z.maintenance && new Date(z.maintenance) < reportDateMinusOneYear);
      },
      sourceType: 'vmc',
      nom: 'Maintenance des systèmes de ventilation mécanique',
      description: () =>
        `Vérification et entretien annuels des différents éléments à faire réaliser par un professionnel qualifié`,
      comment: () => '',
    },
    {
      sourceReportType: 'annuel',
      sourceTab: AnnuelTab.aeration,
      condition: (report: Report, reportType: ReportType) => {
        const reportDateMinusOneYear = reportDate(reportType, report);
        return report.annuelZones.some(
          (z) => hasFilter(z.mode) && z.filtres && new Date(z.filtres) < reportDateMinusOneYear
        );
      },
      sourceType: 'filtres',
      nom: 'Changement des filtres des systèmes de ventilation',
      description: () =>
        `Vérifier tous les 6 mois et nettoyer les filtres placés entre la prise d’air neuf et la bouche de soufflage, si besoin`,
      comment: () => `Filtres pour l’air neuf placés entre la prise d’air neuf et la bouche de soufflage :
- à nettoyer tous les 6 mois
- à changer au moins une fois par an, selon leur usure et encrassement (liés à l’environnement extérieur)`,
    },
    {
      sourceReportType: 'annuel',
      sourceTab: AnnuelTab.co2,
      condition: (report: Report) => report.annuelRooms.some((r) => r.resultat === LESS_THAN_1500_PPM_RESULT),
      sourceType: 'aeration-moyen',
      nom: 'Actions correctives suite au dépassement persistant du seuil de 800 ppm',
      description: () =>
        `Actions correctives permettant de revenir à un renouvellement de l’air satisfaisant pouvant être mises en place sans tarder et ne nécessitant pas d’investissement financier en cas de dépassement du seuil de 800 ppm (source : guide CSTB).`,
      comment: () => `Optimiser l'aération par la gestion des ouvrants et/ou la ventilation mécanique :

- Revoir le schéma d'aération et augmenter la fréquence et la durée d'ouverture des fenêtres. Mettre en place un planning d'ouverture des fenêtres.
- Revoir le degré d'ouverture des fenêtres et les obstacles qui rendent difficiles leur ouverture.
- Rechercher et corriger les causes techniques qui ne permettent pas d'assurer un renouvellement de l'air satisfaisant et conforme.
- Vérifier que les débits de ventilation sont conformes au règlement sanitaire départemental et au code du travail. Modifier au besoin les consignes de débit de soufflage et/ou d'extraction (se référer au guide Cerema et Mallette Ecol'Air).
- Vérifier l'adéquation de la densité d'enfants/mineurs avec la surface de la pièce, le ratio du nombre d'élèves par m2 (exemple : guides Bâti Scolaire pour les écoles).
      
Si le dépassement est persistant après une nouvelle mesure à lecture directe du CO2 pour vérification après optimisation :
    
- Envisager des actions correctives urgentes.
- Au besoin revoir ponctuellement la jauge dans l'attente des actions correctives (diminuer temporairement le nombre de personnes dans la pièce).    
`,
    },
    {
      sourceReportType: 'annuel',
      sourceTab: AnnuelTab.co2,
      condition: (report: Report) => report.annuelRooms.some((r) => r.resultat === MORE_THAN_1500_PPM_RESULT),
      sourceType: 'aeration-insuffisant',
      nom: 'Actions urgentes suite au  dépassement persistant du seuil de 1500 ppm',
      description: () =>
        `Actions correctives urgentes à envisager dans le cadre du plan d’actions en cas de dépassement persistant du seuil de 1500 ppm malgré les pratiques d’aération mises en place (source : guide CSTB).`,
      comment: () => `Actions urgentes : Identification des causes du renouvellement de l'air insuffisant

- Mener toute expertise nécessaire pour identifier les causes du renouvellement de l'air insuffisant dans l'établissement et fournir tous les éléments au choix des mesures correctives pérennes et adaptées.
-  Analyse globale des conditions de renouvellement de l'air tenant compte du contexte du bâtiment et du comportement des occupants.
    
Actions correctives urgentes : Modification des moyens techniques d'aération et/ou de ventilation (étape clé)
    
- Si des dysfonctionnements ont été identifiés au niveau des ouvrants, procéder à leur réparation ou à leur changement.
- Si les salles sont équipées d'un dispositif spécifique de ventilation, il est recommandé de faire intervenir un spécialiste pour procéder à une inspection de l'installation et vérifier notamment la conformité des débits d'air neuf. En fonction du résultat de l'inspection, il peut être nécessaire de changer les consignes de débit ou de mener des travaux de modification du système de ventilation (se référer au besoin à la mallette Ecol'air et au guide Cerema).
    
En l'absence de travaux et si le dépassement est persistant après une nouvelle mesure à lecture directe du CO2, mener de nouveau les actions urgentes ci-dessus.
    
En cas de travaux, se référer au tome 4 du guide Cerema : Identification des étapes clés.    
`,
    },
    ,
    {
      sourceReportType: 'autodiag',
      sourceTab: AutodiagTab.gestion,
      condition: (report: Report) =>
        Object.keys(AUTODIAG_TAB_FIELDS[AutodiagTab.gestion].benzene?.choiceFields[1] ?? []).some(
          (k) => report.org[k] === true
        ),
      sourceType: 'gestion-benzene',
      nom: 'Mesures afin d’évaluer l’impact des activités identifiées sur les concentrations en benzène',
      description:
        () => `Faire engager des mesures afin d’évaluer l’impact des activités identifiées sur les concentrations en benzène à l’intérieur de l’établissement, notamment dans les cas où aucune information n’est disponible sur l’absence d’impact de ces activités à proximité de l’établissement.
Ces mesures sont à réaliser simultanément dans l’air intérieur de l’établissement et à l’extérieur.`,
      comment: (report: Report) =>
        `Activités identifiées${NBSP}:` + checkBoxFieldList(AUTODIAG_TAB_FIELDS[AutodiagTab.gestion].benzene, report),
    },
    {
      sourceReportType: 'autodiag',
      sourceTab: AutodiagTab.gestion,
      condition: (report: Report) =>
        Object.keys(AUTODIAG_TAB_FIELDS[AutodiagTab.gestion].formaldehyde?.choiceFields[1] ?? []).some(
          (k) => report.org[k] === true
        ),
      sourceType: 'gestion-formaldehyde',
      nom: 'Mesures afin d’évaluer l’impact des activités identifiées sur les concentrations en formaldéhyde',
      description:
        () => `Faire engager des mesures afin d’évaluer l’impact des activités identifiées sur les concentrations en formaldéhyde à l’intérieur de l’établissement, notamment dans les cas où aucune information n’est disponible sur l’absence d’impact de ces activités à proximité de l’établissement.
Ces mesures sont à réaliser simultanément dans l’air intérieur de l’établissement et à l’extérieur.`,
      comment: (report: Report) =>
        `Activités identifiées${NBSP}:` +
        checkBoxFieldList(AUTODIAG_TAB_FIELDS[AutodiagTab.gestion].formaldehyde, report),
    },
    {
      sourceReportType: 'autodiag',
      sourceTab: AutodiagTab.maintenance,
      condition: (report: Report) =>
        Object.keys(AUTODIAG_TAB_FIELDS[AutodiagTab.maintenance].benzene?.choiceFields[1] ?? []).some(
          (k) => report.org[k] === true
        ),
      sourceType: 'maintenance-benzene',
      nom: 'Analyses afin de vérifier les niveaux de concentrations en benzène',
      description: (report: Report) =>
        `Faire engager des analyses à l’aide de kits ou par l’intermédiaire d’un laboratoire accrédité LAB REF 30 afin de vérifier les niveaux de concentrations en benzène dans les pièces concernées par les activités identifiées${NBSP}:` +
        checkBoxFieldList(AUTODIAG_TAB_FIELDS[AutodiagTab.maintenance].benzene, report),
      comment: () => '',
    },
    {
      sourceReportType: 'autodiag',
      sourceTab: AutodiagTab.maintenance,
      condition: (report: Report) =>
        Object.keys(AUTODIAG_TAB_FIELDS[AutodiagTab.maintenance].formaldehyde?.choiceFields[1] ?? []).some(
          (k) => report.org[k] === true
        ),
      sourceType: 'maintenance-formaldehyde',
      nom: 'Analyses afin de vérifier les niveaux de concentrations en formaldéhyde',
      description: (report: Report) =>
        `Faire engager des analyses à l’aide de kits ou par l’intermédiaire d’un laboratoire accrédité LAB REF 30 afin de vérifier les niveaux de concentrations en formaldéhyde dans les pièces concernées par les activités identifiées${NBSP}:` +
        checkBoxFieldList(AUTODIAG_TAB_FIELDS[AutodiagTab.maintenance].formaldehyde, report),
      comment: () => '',
    },
  ] as Proposal[]
)
  .concat(
    Object.entries(AUTODIAG_TAB_FIELDS)
      .map(([tab, tabFields]) =>
        Object.entries(tabFields.practises).map(([, [, groupFields]]) =>
          Object.entries(groupFields).map(([key, labelAndDescription]) =>
            makeProposal(tab, key, labelAndDescription[0], labelAndDescription[1])
          )
        )
      )
      .flat()
      .flat()
  )
  .concat(
    Object.entries(AUTODIAG_TAB_FIELDS)
      .map(([tab, tabFields]) =>
        Object.entries(tabFields.observations?.choiceFields[1] ?? {}).map(([key, labelAndDescription]) =>
          makeProposal(tab, key, labelAndDescription[0], labelAndDescription[1])
        )
      )
      .flat()
      .flat()
  );

const hasSameActionId = (year: string, a: Action, p: Proposal) =>
  a.sourceType === p.sourceType &&
  a.sourceReportType === p.sourceReportType &&
  a.sourceTab === p.sourceTab &&
  a.sourceYear === year;

/** Returns all actions for the report or undefined is no new action is to be proposed.*/
export function updatedActionsWithProposals(report: Report, reportType: ReportType): Action[] | undefined {
  const newActions: Action[] = [];
  PROPOSALS.forEach((p) => {
    if (!report.org.actions.some((a) => hasSameActionId(report.year, a, p)) && p.condition(report, reportType)) {
      newActions.push({
        id: '' + (report.org.actions.length + newActions.length + 1),
        sourceType: p.sourceType,
        sourceReportType: p.sourceReportType,
        sourceTab: p.sourceTab,
        sourceYear: report.year,
        nom: p.nom,
        description: p.description(report),
        comment: p.comment(report),
        statut: ActionStatus.Proposé,
      });
    }
  });
  return newActions.length === 0 ? undefined : [...report.org.actions, ...newActions];
}
