import { css } from '@emotion/css';
import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import {
  Button,
  CellProps,
  Column,
  Form,
  HorizontalGroup,
  Input,
  InputControl,
  RadioButtonGroup,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import {
  AutosizeTextArea,
  CalendarButton,
  CalendarEvent,
  CreatableSelect,
  EMAIL_PATTERN,
  EditTable,
  GUIDE_CEREMA,
  OPTIONAL,
  PERSON_PLACEHOLDER,
  REQUIRED,
  SaveButton,
  SaveState,
  TooltipField,
  UUID_NAMESPACE,
  addAttendee,
  escapeCalTag,
  expanderCell,
  makeOptions,
} from 'utils/utils.form';
import { formatDateString, stringEnumToMap, utcDate } from 'utils/utils.format';
import {
  Action,
  ActionStatus,
  Org,
  Report,
  ReportType,
  hasOneOrg,
  makeNameOptions,
  reportDate,
  sortActionStatusRow,
  sortActions,
} from 'utils/utils.model';
import { ANNUEL_TAB_DETAILS, AUTODIAG_TAB_DETAILS, AnnuelTab, AutodiagTab, reportUrl } from 'utils/utils.routing';
import { FORM_MAX_WIDTH } from 'utils/utils.styles';
import { v5 as uuidv5 } from 'uuid';
import { updateThing } from './queries';

interface ExpandedRowProps {
  reportType: ReportType;
  report?: Report;
  setReports: Dispatch<SetStateAction<Report[]>>;
  action?: Action;
  button: string;
  closeAdd: () => void;
}

const isActionStatus = (status: ActionStatus[], watch: UseFormWatch<Action>, action: Action | undefined) =>
  (status as string[]).includes(watch('statut', action?.statut ?? ActionStatus.EnCours));

function ExpandedRow({ reportType, report, setReports, action, button, closeAdd }: ExpandedRowProps) {
  const [buttonSaving, setButtonSaving] = useState(undefined as SaveState);
  const submitActionPlan = (newAction: Action) => {
    setButtonSaving('saving');
    if (report) {
      const actions = report.org.actions;
      const idx = newAction.id ? report.org.actions.findIndex((a) => a.id === newAction.id) : -1;
      if (idx !== -1) {
        report.org.actions[idx] = { ...report.org.actions[idx], ...newAction };
      } else {
        newAction.id = '' + (report.org.actions.length + 1);
        report.org.actions.push(newAction);
      }
      updateThing(
        reportType,
        report,
        { org: { nom: report.org.nom, id: report.org.id, actions: actions } as Org },
        () => {
          closeAdd();
          setButtonSaving('saved');
        },
        (err) => setButtonSaving(err),
        setReports
      );
    } else {
      setButtonSaving('Impossible de sauvegarder avec plusieurs établissements');
    }
  };

  return (
    <Form<Action> onSubmit={submitActionPlan} maxWidth={FORM_MAX_WIDTH}>
      {({ register, errors, control, watch, setValue }) => (
        <div>
          <input {...register('id')} type="hidden" value={action?.id} />
          <TooltipField label="Titre" error={errors.nom}>
            <Input {...register('nom', REQUIRED)} defaultValue={action?.nom} type="text" />
          </TooltipField>
          <TooltipField
            label="Description"
            error={errors.description}
            tooltip={
              <>
                Précision sur l’action à réaliser, déroulement de cette action / planning et moyens nécessaires pour la
                réaliser (page 128 du {GUIDE_CEREMA}).
              </>
            }
          >
            <AutosizeTextArea
              field="description"
              control={control}
              options={REQUIRED}
              defaultValue={action?.description}
              defaultRows={4}
            />
          </TooltipField>
          <TooltipField
            label="Informations complémentaires"
            error={errors.comment}
            tooltip={
              <>
                Il est possible de compléter les informations minimales exigées du plan, notamment avec les objectifs,
                des échéances intermédiaires de réalisation, la méthodologie à suivre, le coût estimé de l’action ou un
                indicateur chiffré de réalisation (page 128 du {GUIDE_CEREMA}). Ces informations ne sont pas affichés
                dans le rapport.
              </>
            }
          >
            <AutosizeTextArea
              field="comment"
              control={control}
              placeholder={OPTIONAL}
              defaultValue={action?.comment}
              defaultRows={4}
            />
          </TooltipField>
          <TooltipField
            label="Responsable"
            tooltip={
              <>
                Nom de la personne responsable de la réalisation de l’action ou du service (page 128 du {GUIDE_CEREMA}).
              </>
            }
            error={errors.responsable}
          >
            <CreatableSelect
              rules={{
                validate: (v) => {
                  if (!v && isActionStatus([ActionStatus.EnCours, ActionStatus.Réalisé], watch, action)) {
                    return `Une personne ou un service responsable est obligatoire pour les actions en cours ou réalisées.`;
                  }
                  return true;
                },
              }}
              optional={!isActionStatus([ActionStatus.EnCours, ActionStatus.Réalisé], watch, action)}
              control={control}
              register={register}
              name="responsable"
              autoComplete="name"
              placeholder={PERSON_PLACEHOLDER}
              additionalOnChange={(e) => {
                const name = report?.names.find((n) => n.nom === e);
                if (name) {
                  setValue('emailResponsable', name.email);
                }
              }}
              defaultValue={action?.responsable}
              defaultOptions={makeNameOptions(report?.names)}
            />
          </TooltipField>
          <TooltipField
            label="Courriel du responsable"
            error={errors.emailResponsable}
            tooltip="Utile pour ajouter l’action au calendrier."
          >
            <Input
              {...register('emailResponsable', EMAIL_PATTERN)}
              placeholder={OPTIONAL}
              autoComplete="email"
              defaultValue={action?.emailResponsable}
              type="email"
            />
          </TooltipField>
          <TooltipField label="Partenaires" error={errors.partenaires} tooltip="Services et personnes.">
            <AutosizeTextArea
              field="partenaires"
              control={control}
              options={REQUIRED}
              defaultValue={action?.partenaires ?? 'Aucun'}
            />
          </TooltipField>
          <TooltipField label="Échéance" error={errors.echeance}>
            <Input
              {...register('echeance', {
                validate: (v) => {
                  if (!v && isActionStatus([ActionStatus.EnCours, ActionStatus.Réalisé], watch, action)) {
                    return `L’échéance est obligatoire pour les actions en cours ou réalisées.`;
                  }
                  if (
                    v &&
                    isActionStatus([ActionStatus.Réalisé], watch, action) &&
                    new Date(v).getTime() > new Date().getTime()
                  ) {
                    return `L’échéance ne peut pas être dans le futur pour cette action réalisée.`;
                  }
                  return true;
                },
              })}
              defaultValue={action?.echeance}
              type="date"
            />
          </TooltipField>
          <TooltipField
            label="Statut"
            error={errors.statut}
            tooltip="Seules les actions en cours ou réalisées depuis moins d’un an sont incluses dans le rapport."
          >
            <InputControl
              control={control}
              name={'statut'}
              defaultValue={action?.statut ?? ActionStatus.EnCours}
              rules={REQUIRED}
              render={({ field: { onChange, ref, ...field } }) => (
                <RadioButtonGroup
                  {...field}
                  onChange={onChange}
                  options={makeOptions([...stringEnumToMap(ActionStatus).values()].map((k) => [k]))}
                />
              )}
            />
          </TooltipField>
          <HorizontalGroup align="flex-start" justify="flex-end">
            {!action && (
              <Button type="button" variant="secondary" onClick={closeAdd}>
                Annuler
              </Button>
            )}
            <SaveButton label={button} state={buttonSaving} setState={setButtonSaving} />
          </HorizontalGroup>
        </div>
      )}
    </Form>
  );
}

export interface ActionPlanSceneObjectState extends SceneObjectState {
  reports: Report[];
  setReports: Dispatch<SetStateAction<Report[]>>;
  title?: string;
}

class ActionPlanSceneObject extends SceneObjectBase<ActionPlanSceneObjectState> {
  constructor(
    reports: Report[],
    setReports: Dispatch<SetStateAction<Report[]>>,
    title?: string,
    state?: Partial<ActionPlanSceneObjectState>
  ) {
    super({ reports: reports, setReports: setReports, title: title, ...state });
  }
}

function OriginCell({ row: { original } }: CellProps<ExtendedAction, void>) {
  const styles = useStyles2(() => ({
    link: css`
      text-decoration: underline;
    `,
  }));
  return original.sourceReportType && original.sourceYear && original.sourceTab ? (
    <a
      className={styles.link}
      href={reportUrl(
        original.sourceReportType,
        original.datasource,
        original.sourceYear,
        original.sourceTab as AnnuelTab | AutodiagTab
      )}
    >
      {original.sourceReportType === 'annuel'
        ? `Évaluation annuelle ${original.sourceYear} - ` + ANNUEL_TAB_DETAILS[original.sourceTab as AnnuelTab].title
        : `Autodiagnostic ${original.sourceYear} - ` + AUTODIAG_TAB_DETAILS[original.sourceTab as AutodiagTab].title}
    </a>
  ) : (
    ''
  );
}

interface ExtendedAction extends Action {
  etablissement?: string;
}

const makeRenderer = (reportType: ReportType) =>
  function ActionPlanSceneObjectRenderer({ model }: SceneComponentProps<ActionPlanSceneObject>) {
    const { reports, setReports, title } = model.useState();
    const columns = (
      hasOneOrg(reports)
        ? ([] as Array<Column<ExtendedAction>>)
        : [
            {
              id: 'etablissement',
              header: 'Établissement',
              sortType: 'basic',
            },
          ]
    ).concat([
      {
        id: 'id',
        header: 'N°',
        sortType: 'alphanumeric',
        cell: expanderCell<ExtendedAction>((row) => row.id),
      },
      {
        id: 'nom',
        header: 'Titre',
        sortType: 'basic',
        cell: expanderCell<ExtendedAction>((row) => row.nom),
      },
      {
        id: 'description',
        header: 'Description',
        sortType: 'basic',
        cell: expanderCell<ExtendedAction>((row) => row.description),
      },
      {
        id: 'comment',
        header: 'Informations complémentaires',
        sortType: 'basic',
        cell: expanderCell<ExtendedAction>((row) => row.comment),
      },
      {
        id: 'responsable',
        header: 'Responsable',
        sortType: 'basic',
        cell: expanderCell<ExtendedAction>((row) =>
          row.emailResponsable ? <a href={'mailto:' + row.emailResponsable}>{row.responsable}</a> : row.responsable
        ),
      },
      {
        id: 'partenaires',
        header: 'Partenaires',
        sortType: 'basic',
        cell: expanderCell<ExtendedAction>((row) => row.partenaires),
      },
      {
        id: 'origine',
        header: 'Origine',
        sortType: 'basic',
        cell: OriginCell,
      },
      {
        id: 'echeance',
        header: 'Échéance',
        sortType: 'alphanumeric',
        cell: expanderCell<ExtendedAction>((row) => formatDateString(row.echeance)),
      },
      {
        id: 'statut',
        header: 'Statut',
        sortType: useMemo(() => sortActionStatusRow, []),
        cell: expanderCell<ExtendedAction>((row) => row.statut),
      },
    ]);
    const actions: ExtendedAction[] = reports
      .map((r) =>
        r.org.actions.map((a, i) => ({
          ...a,
          datasource: r.datasourceName,
          etablissement: r.org.nom,
          id: '' + (i + 1),
        }))
      )
      .flat();
    const report = reports.length === 1 ? reports[0] : undefined;
    // TODO allow to edit for multiple orgs
    return (
      <VerticalGroup>
        {title && <h2>{title}</h2>}
        <EditTable
          topButton={report ? 'Ajouter une action' : undefined}
          addButton="Ajouter l’action"
          saveButton="Modifier"
          secondaryTopElements={
            <CalendarButton
              name={`Qualité de l’Air Intérieur - ${report?.org.nom ?? report?.datasourceName}`}
              calendarDates={
                report
                  ? actions.filter((a) => a.statut === ActionStatus.EnCours).map((a) => calendarDate(a, report))
                  : []
              }
            />
            // report ? (
            //   <HorizontalGroup>
            //     {/* <Button variant="secondary" tooltip="Télécharger le plan d’action en PDF" icon="download-alt">
            //       PDF
            //     </Button> */}
            //     {/* <Button variant="secondary" tooltip="Télécharger le plan d’action en CSV" icon="download-alt">
            //       CSV
            //     </Button> */}
            //   </HorizontalGroup>
            // ) : (
            //<></>
            //)
          }
          columns={columns}
          data={actions.sort((a, b) => sortActions(reportDate(reportType, report), a, b))}
          renderExpandedRow={(a, button, closeAdd) => (
            <ExpandedRow
              reportType={reportType}
              report={report}
              setReports={setReports}
              action={a}
              button={button}
              closeAdd={closeAdd}
            />
          )}
        />
      </VerticalGroup>
    );
  };

function calendarDate(action: Action, report: Report): CalendarEvent {
  const org = report.org.nom ?? report.datasourceName;
  // note: mailto urls are not supported on GMail and maybe many other clients
  return addAttendee(report, action.responsable, action.emailResponsable, {
    name: `${action.nom} (${org})`,
    description:
      // real source report type does not matter.
      `Mettre à jour le statut dans [url]https://app.meo.life${reportUrl(
        'annuel',
        encodeURIComponent(report.datasourceName),
        report.year,
        AnnuelTab.actions
      )}|le logiciel meo[/url].

[b]Responsable[/b]\xa0:

${escapeCalTag(`${action.responsable}${action.emailResponsable ? ` (${action.emailResponsable})` : ''}`)}

[b]Partenaires[/b]\xa0:

${escapeCalTag(action.partenaires)}

[b]Description[/b]\xa0:

${escapeCalTag(action.description)}

[b]Informations complémentaires[/b]\xa0:

${escapeCalTag(action.comment)}`,
    // should never have default since action has ActionStatus.EnCours
    startDate: utcDate(new Date(action.echeance ?? '')),
    location: `${org}, ${report.org.adresse ?? ''}, ${report.org.codePostal ?? ''} ${report.org.ville ?? ''}`,
    // different from URL to use ids instead of names which could change
    uid: uuidv5(`annuel/${report.datasourceUser}/${report.year}/actions/${action.id}`, UUID_NAMESPACE),
  });
}

export class AnnuelActionPlanSceneObject extends ActionPlanSceneObject {
  static Component = makeRenderer('annuel');
}

export class AutodiagActionPlanSceneObject extends ActionPlanSceneObject {
  static Component = makeRenderer('autodiag');
}
