import * as React from 'react';
import { Helmet } from 'react-helmet';
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-buy-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const BUTTON_IDS_BY_QTY = [
  'buy_btn_1Nx3FaCfSdBMUrO8IHpv35mc',
  'buy_btn_1O1ozCCfSdBMUrO8PGo7csyA',
  'buy_btn_1O1p16CfSdBMUrO8OG7RK5Ot',
  'buy_btn_1O1p1fCfSdBMUrO8OUTybITm',
  'buy_btn_1O1p2ACfSdBMUrO8Vxz5ucIE',
  'buy_btn_1O1p2hCfSdBMUrO8Suxsnhte',
  'buy_btn_1O1p3LCfSdBMUrO8ZAprMTBi',
  'buy_btn_1O1p3mCfSdBMUrO8mWmjd8Ey',
  'buy_btn_1O1p4KCfSdBMUrO8dcYAwko1',
  'buy_btn_1O1p5ACfSdBMUrO8XHAG8qEA',
  'buy_btn_1O1p5YCfSdBMUrO8dTvN8hDO',
  'buy_btn_1O1p6MCfSdBMUrO8HO05qvVg',
  'buy_btn_1O1p5zCfSdBMUrO8g0o6pVhb',
  'buy_btn_1O1p6xCfSdBMUrO8JezNUte4',
  'buy_btn_1O1p7UCfSdBMUrO8uGsPSyM5',
  'buy_btn_1O1p7yCfSdBMUrO8E0xZcPIF',
  'buy_btn_1O1p8LCfSdBMUrO8LojLjnqU',
  'buy_btn_1O1p8lCfSdBMUrO8cjoR1TDL',
  'buy_btn_1O1p96CfSdBMUrO8kn50COLG',
  'buy_btn_1O1p9SCfSdBMUrO8pd6vW3r7',
];

export function BuyButtonComponent({ clientReferenceId, quantity }: { clientReferenceId: string; quantity: number }) {
  // buy-button-id cannot be dynamic
  const buttons = BUTTON_IDS_BY_QTY.map((id) => (
    <stripe-buy-button
      key={id}
      client-reference-id={clientReferenceId}
      buy-button-id={id}
      publishable-key="pk_live_51NwsZjCfSdBMUrO8OzNwqlLClYOHxuVOQgbsv7TxIdHg0BrX5TaJcfSpTr13vJCeCMBSJqJloPSerZQa68e9YZ7M00asl6ZReK"
    ></stripe-buy-button>
  ));
  return (
    <>
      <Helmet>
        <script async src="https://js.stripe.com/v3/buy-button.js"></script>
      </Helmet>
      {buttons[Math.min(BUTTON_IDS_BY_QTY.length - 1, quantity > 0 ? quantity - 1 : 0)]}
    </>
  );
}
