import { DataFrame, FieldConfig, FieldType, toDataFrame } from '@grafana/data';
import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { useStyles2 } from '@grafana/ui';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { SizedTable } from 'utils/utils.form';
import { NO_OBTURATION, ONLY_WINDOWS, Report, emptyReport, getCommunicationStatus, hasOneOrg } from 'utils/utils.model';
import { prefixRoute } from 'utils/utils.routing';
import { GREEN, RED, YELLOW, getStyles } from 'utils/utils.styles';
import { ROUTES } from '../../constants';

export interface ReportsSceneObjectState extends SceneObjectState {
  reports: Report[];
}

export class ReportsSceneObject extends SceneObjectBase<ReportsSceneObjectState> {
  static Component = ReportsSceneObjectRenderer;

  constructor(reports: Report[], state?: Partial<ReportsSceneObjectState>) {
    super({ reports: reports, ...state });
  }
}

function ReportsSceneObjectRenderer({ model }: SceneComponentProps<ReportsSceneObject>) {
  const { reports } = model.useState();
  const styles = useStyles2(getStyles);
  const reportFrames = getReportsDataFrame(reports);
  const resultFrames = getResultsDataFrame(reports);
  return (
    <div className={styles.container}>
      <AutoSizer disableHeight>
        {({ width }: { width: number }) => {
          return (
            <>
              <h2>Rapports</h2>
              <SizedTable width={width} data={reportFrames} sortBy="Rapport" sortDesc={true} />
              {resultFrames.length > 0 && (
                <>
                  <h2>Résultats</h2>
                  <SizedTable width={width} data={resultFrames} sortBy="Année" sortDesc={true} />
                </>
              )}
            </>
          );
        }}
      </AutoSizer>
    </div>
  );
}

function yearValues<T>(report: Report, yearlyValue: T, autodiagValue?: T): T[] {
  if (!report.org.autodiagPieceIds) {
    return [yearlyValue];
  }
  return autodiagValue ? [yearlyValue, autodiagValue] : Array(2).fill(yearlyValue);
}

function getReportsDataFrame(reports: Report[]): DataFrame {
  const oneOrgFields = !hasOneOrg(reports)
    ? [
        {
          name: 'Établissement',
          type: FieldType.string,
          values: reports.map((r) => yearValues(r, r.org?.nom)).flat(),
        },
      ]
    : [];
  return toDataFrame({
    fields: [
      ...oneOrgFields,
      {
        name: 'Rapport',
        type: FieldType.string,
        values: reports
          .map((r) => yearValues(r, r.year + ' – Évaluation annuelle', r.year + ' – Autodiagnostic'))
          .flat(),
        config: {
          links: [
            {
              title: 'Éditer le rapport',
              url:
                prefixRoute(ROUTES.Home) +
                '${__data.fields["datasource"]}/${__data.fields["type"]}/${__data.fields["year"]}/etablissement',
            },
          ],
          custom: {
            cellOptions: {
              type: 'color-background',
              mode: 'basic',
            },
            filterable: true,
          },
          color: {
            mode: 'fixed',
            fixedColor: '#3d71d9',
          },
        } as FieldConfig,
      },
      {
        name: 'year',
        type: FieldType.string,
        values: reports.map((r) => yearValues(r, r.year)).flat(),
        config: {
          custom: { hidden: true, filterable: true },
        },
      },
      {
        name: 'datasource',
        type: FieldType.string,
        values: reports.map((r) => yearValues(r, r.datasourceName)).flat(),
        config: {
          custom: { hidden: true },
        },
      },
      {
        name: 'type',
        type: FieldType.string,
        values: reports.map((r) => yearValues(r, 'annuel', 'autodiag')).flat(),
        config: {
          custom: { hidden: true },
        },
      },
      {
        name: 'Remplissage',
        type: FieldType.number,
        values: reports.map((r) => yearValues(r, r.annuelTotalProgress, r.autodiagTotalProgress)).flat(),
        config: {
          min: 0,
          max: 100,
          color: {
            fixedColor: 'transparent',
            mode: 'thresholds',
          },
          unit: 'percent',
          thresholds: {
            mode: 'absolute',
            steps: [
              {
                color: 'red',
                value: null,
              },
              {
                color: 'yellow',
                value: 70,
              },
              {
                color: 'green',
                value: 100,
              },
            ],
          },
          custom: {
            cellOptions: {
              type: 'gauge',
              mode: 'basic',
            },
            filterable: true,
          },
        } as FieldConfig,
      },
      {
        name: 'Communication',
        type: FieldType.string,
        values: reports
          .map((r) =>
            yearValues(
              r,
              getCommunicationStatus(
                r.annuelTotalProgress,
                r.org.propQualite,
                r.org.annuelReception,
                r.org.annuelChef,
                r.org.annuelConseil,
                r.org.annuelChs,
                r.org.annuelAffichage
              ),
              getCommunicationStatus(
                r.annuelTotalProgress,
                r.org.propQualite,
                r.org.autodiagReception,
                r.org.autodiagChef,
                r.org.autodiagConseil,
                r.org.autodiagChs,
                'true'
              )
            )
          )
          .flat(),
        config: {
          color: {
            fixedColor: 'transparent',
            mode: 'thresholds',
          },
          mappings: [
            {
              type: 'value',
              options: {
                'En attente du rapport': {
                  color: 'transparent',
                  index: 0,
                },
                Fait: {
                  color: GREEN,
                  index: 2,
                },
              },
            },
            {
              type: 'regex',
              options: {
                pattern: 'À faire :.*',
                result: {
                  color: YELLOW,
                  index: 1,
                },
              },
            },
            {
              type: 'regex',
              options: {
                pattern: '.*de retard',
                result: {
                  color: RED,
                  index: 3,
                },
              },
            },
          ],
          custom: {
            cellOptions: {
              type: 'color-background',
            },
            filterable: true,
          },
        } as FieldConfig,
      },
    ],
  });
}

const PERCENT_CONFIG: any = {
  custom: {
    cellOptions: {
      type: 'color-background',
    },
    filterable: true,
  },
  color: {
    mode: 'continuous-RdYlGr',
  },
  min: 0,
  max: 100,
  unit: 'percent',
  mappings: [
    {
      type: 'special',
      options: {
        match: 'empty',
        result: {
          color: 'transparent',
          index: 0,
        },
      },
    },
  ],
};

const RESULTS_LINK_CONFIG: FieldConfig = {
  links: [
    {
      title: 'Éditer l’évaluation annuelle',
      url: prefixRoute(ROUTES.Home) + '${__data.fields["datasource"]}/annuel/${__data.fields["Année"]}/aeration',
    },
  ],
  custom: {
    align: 'right',
    filterable: true,
    cellOptions: {
      type: 'color-text',
    },
  },
  color: {
    mode: 'fixed',
    fixedColor: 'text',
  },
};

function roomsWithVentilation(r: Report): number {
  const zoneIdsWithVentilation = r.annuelZones.filter((z) => z.mode !== ONLY_WINDOWS).map((z) => z.id);
  return r.annuelRooms.filter((room) => zoneIdsWithVentilation.includes(room.zoneId)).length;
}

function getResultsDataFrame(reports: Report[]): DataFrame {
  const completeReports = reports
    .filter((r) => r.annuelTotalProgress === 100)
    .sort((a, b) => parseInt(b.year, 10) - parseInt(a.year, 10));
  const oneOrg = hasOneOrg(reports);
  const lastResults = oneOrg
    ? completeReports
    : [...new Set(reports.map((r) => r.org?.nom))].map(
        (n) => completeReports.find((c) => n === c.org?.nom) ?? emptyReport(n, '', '' + new Date().getFullYear())
      );
  const orgFields = oneOrg
    ? []
    : [
        {
          name: 'Établissement',
          type: FieldType.string,
          values: lastResults.map((r) => r.org?.nom),
          config: RESULTS_LINK_CONFIG,
        },
      ];
  return toDataFrame({
    fields: [
      ...orgFields,
      {
        name: 'Année',
        type: FieldType.number,
        values: lastResults.map((l) => l.year),
        config: oneOrg
          ? RESULTS_LINK_CONFIG
          : {
              custom: {
                align: 'right',
                filterable: true,
              },
            },
      },
      {
        name: 'datasource',
        type: FieldType.string,
        values: lastResults.map((l) => l.datasourceName),
        config: {
          custom: { hidden: true },
        },
      },
      {
        name: 'Salles évaluables',
        type: FieldType.number,
        values: lastResults.map((l) => l.org.pieces),
        config: {
          custom: {
            align: 'right',
          },
        },
      },
      {
        name: 'Échantillon',
        type: FieldType.number,
        values: lastResults.map((l) => (l.org.pieces ? l.annuelRooms.length : undefined)),
        config: {
          custom: {
            align: 'right',
          },
        },
      },
      {
        name: 'Pièces bien aérées',
        type: FieldType.number,
        values: lastResults.map(
          (l) =>
            '' +
            (l.annuelRooms.length
              ? (100 * l.annuelRooms.reduce((a, r) => a + (!r.ppm800 && !r.ppm1500 ? 1 : 0), 0)) / l.annuelRooms.length
              : '')
        ),
        config: PERCENT_CONFIG,
      },
      {
        name: 'Ouvrants entretenus',
        type: FieldType.number,
        values: lastResults.map(
          (l) =>
            '' +
            (l.annuelRooms.length
              ? (100 * l.annuelRooms.reduce((a, r) => a + Math.min(r.manoeuvrable, r.accessible), 0)) /
                l.annuelRooms.reduce((a, r) => a + r.ouvrants, 0)
              : '')
        ),
        config: PERCENT_CONFIG,
      },
      {
        name: 'Pièces avec ventilation',
        type: FieldType.number,
        values: lastResults.map(
          (l) => '' + (l.annuelRooms.length ? (100 * roomsWithVentilation(l)) / l.annuelRooms.length : '')
        ),
        config: PERCENT_CONFIG,
      },
      {
        name: 'Pièces avec bouches entretenues',
        type: FieldType.number,
        values: lastResults.map(
          (l) =>
            '' +
            (roomsWithVentilation(l)
              ? (100 *
                  l.annuelRooms.reduce(
                    (a, r) => a + (r.fonctionne && r.obture === NO_OBTURATION && !r.encrasse ? 1 : 0),
                    0
                  )) /
                roomsWithVentilation(l)
              : '')
        ),
        config: PERCENT_CONFIG,
      },
    ],
  });
}
