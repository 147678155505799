import { SceneObjectState } from '@grafana/scenes';
import { Dispatch, SetStateAction } from 'react';
import { BaseRow, REQUIRED_BOOLEAN, SaveState } from './utils.form';
import { v4 as uuidv4 } from 'uuid';
import { CellProps, SortByFn } from '@grafana/ui';
import { formatMulti } from './utils.format';
import { AutodiagFormTab, CheckboxFields, AUTODIAG_TAB_FIELDS, TabFields } from 'pages/Home/Autodiag';
import { AnnuelTab, AutodiagTab } from './utils.routing';

export type ReportType = 'annuel' | 'autodiag';

export const isValidType = (type: string) => ['annuel', 'autodiag'].includes(type);

export const typeDescription = (type: ReportType) => {
  switch (type) {
    case 'annuel':
      return 'Évaluation annuelle';
    case 'autodiag':
      return 'Autodiagnostic';
    default:
      return undefined;
  }
};

export interface TabObjectState extends SceneObjectState {
  report: Report;
  setReports: Dispatch<SetStateAction<Report[]>>;
}

export interface Action extends BaseRow {
  sourceType?: string;
  sourceReportType?: ReportType;
  sourceTab?: string;
  sourceYear?: string;
  description: string;
  comment?: string;
  responsable?: string;
  emailResponsable?: string;
  partenaires?: string;
  echeance?: string;
  statut: string;
}

export enum ActionStatus {
  Proposé = 'Proposé',
  Rejeté = 'Rejeté',
  EnCours = 'En cours',
  Réalisé = 'Réalisé',
}

const SORTED_ACTION_STATUS = [ActionStatus.EnCours, ActionStatus.Proposé, ActionStatus.Réalisé, ActionStatus.Rejeté];

export const sortActions = (reportDate: Date, a: Action, b: Action): number => {
  if (a.statut === b.statut) {
    if (a.echeance) {
      if (!b.echeance) {
        return -1;
      }
      const aFromReport = new Date(a.echeance).getTime() - reportDate.getTime();
      const bFromReport = new Date(b.echeance).getTime() - reportDate.getTime();
      if (aFromReport > 0 && bFromReport < 0) {
        return 1;
      }
      if (bFromReport > 0 && aFromReport < 0) {
        return -1;
      }
      return Math.abs(aFromReport) - Math.abs(bFromReport);
    }
    return b.echeance ? 1 : 0;
  }
  return sortActionStatus(a.statut as ActionStatus, b.statut as ActionStatus);
};

export const sortActionStatus = (a: ActionStatus, b: ActionStatus) =>
  SORTED_ACTION_STATUS.indexOf(a) - SORTED_ACTION_STATUS.indexOf(b);

export const sortActionStatusRow: SortByFn<Action> = (
  rowA: CellProps.Row<Action>,
  rowB: CellProps.Row<Action>,
  columnId: CellProps.IdType<Action>
) => sortActionStatus(rowA.original[columnId], rowB.original[columnId]);

const ANNUEL_MANDATORY_ORG_TAB_FIELDS = 20;

export const PROPRIETAIRE = 'Propriétaire';
export const EXPLOITANT = 'Exploitant';
export const SERVICES_TECHNIQUES = 'Services techniques de la collectivité publique';

export const proprietaireOuExploitant = (qualite: string | undefined) =>
  qualite ? qualite : 'Propriétaire ou exploitant';

export const respQualiteWithOwnAddress = (respQualite: string | undefined, propQualite: string | undefined) =>
  respQualite !== propQualite;

export interface Org extends BaseRow {
  logo?: string; //optional
  type?: string;
  adresse?: string;
  codePostal?: string;
  ville?: string;
  siret?: string;
  pieces?: number;
  effectif?: number; //optional
  // Propriétaire/Exploitant
  propPersonneMorale?: string;
  propAdresse?: string;
  propCodePostal?: string;
  propVille?: string;
  propQualite?: string;
  propService?: string;
  propPersonne?: string;
  propTel?: string;
  propEmail?: string;
  // Responsable
  respPersonne?: string;
  respTel?: string;
  respEmail?: string;
  respRappel?: string;
  respQualite?: string;
  respPersonneMorale?: string;
  respAdresse?: string;
  respCodePostal?: string;
  respVille?: string;
  respSiret?: string;
  // Pièces du rapport annuel
  annuelPieceIds: string[];
  // Pièces de l'autodiag quand il y a un rapport pour cette année
  autodiagPieceIds?: string[];
  // Communication du rapport annuel
  annuelBilan?: string;
  annuelEmoticon?: boolean;
  annuelReception?: string;
  annuelChef?: string;
  annuelConseil?: string;
  annuelChs?: string;
  annuelAffichage?: string;
  // Communication du rapport autodiag
  autodiagReception?: string;
  autodiagChef?: string;
  autodiagConseil?: string;
  autodiagChs?: string;
  // Actions
  actions: Action[];
}

export const reportDate = (reportType: ReportType, report?: Report): Date => {
  const now = new Date();
  if (!report) {
    return now;
  }
  if (reportType === 'annuel' && report.org?.annuelReception) {
    return new Date(report.org.annuelReception);
  }
  if (reportType === 'autodiag' && report.org?.autodiagReception) {
    return new Date(report.org.autodiagReception);
  }
  const endOfYear = new Date(parseInt(report.year, 10), 11, 31);
  return now < endOfYear ? now : endOfYear;
};

const getAnnuelRooms = (report: Report): Room[] =>
  report.allRooms.filter((r) => r.id && report.org?.annuelPieceIds.includes(r.id));

const getAutodiagRooms = (report: Report): Room[] =>
  report.allRooms.filter((r) => r.id && report.org?.autodiagPieceIds?.includes(r.id));

const getAnnuelZones = (report: Report, annuelRooms: Room[]): Zone[] => {
  const addedToRooms = report.allZones.filter((z) => z.id && annuelRooms.some((r) => r.zoneId === z.id));
  return addedToRooms.length === 0 ? report.allZones : addedToRooms;
};

// communication status for yearly report or autodiag
export const getCommunicationStatus = (
  totalProgress: number,
  propQualite: string | undefined,
  reception: string | undefined,
  chef: string | undefined,
  conseil: string | undefined,
  chs: string | undefined,
  affichage: string | undefined
): string => {
  if (totalProgress < 100) {
    return 'En attente du remplissage complet';
  }
  const todo: Array<string | undefined> = [];
  if (!reception) {
    todo.push(propQualite ?? 'Propriétaire ou exploitant');
  }
  if (!chef) {
    todo.push('Directeur');
  }
  if (!affichage) {
    todo.push('Affichage');
  }
  if (!conseil) {
    todo.push('Conseil');
  }
  if (!chs) {
    todo.push('CHS');
  }
  if (todo.length > 0) {
    return 'À faire : ' + formatMulti(todo);
  } else {
    return 'Fait';
  }
};

const ANNUEL_MANDATORY_ROOM_AERATION_FIELDS = 8;

const ANNUEL_MANDATORY_ROOM_CO2_FIELDS = 16;

export const NO_OBTURATION = 'Pas d’obturation';

export interface Room extends BaseRow {
  // comment typically on sampling choice
  comment: string;
  // Ouvrants
  responsableAeration?: string;
  emailAeration?: string;
  dateAeration?: string;
  zoneId: string; //thing id
  ouvrants: number;
  ouvrable: number;
  accessible: number;
  manoeuvrable: number;
  commentOuvrants: string;
  fonctionne: boolean;
  obture: string;
  encrasse: boolean;
  commentBouches: string;
  // Capteur
  responsableCapteur?: string;
  emailCapteur?: string;
  dateCapteur?: string;
  modele?: string;
  noSerie?: string;
  modeEtalonnage?: string;
  dateEtalonnage?: string;
  conformiteSeuils?: boolean;
  modeValeur?: boolean;
  modeIndicateur?: boolean;
  modeSonore?: boolean;
  modeEnregistrement?: boolean;
  frequence?: string;
  commentCapteur?: string;
  // Mesure CO2
  responsableMesure?: string;
  emailMesure?: string;
  dateHeureDebutMesure?: string;
  dateHeureFinMesure?: string;
  effectifMax: number;
  occupants: number;
  ppm800: boolean;
  ppm1500: boolean;
  maxCo2?: number;
  ppm800Num?: number;
  ppm1500Num?: number;
  actionAucune?: boolean;
  actionUneOuverture?: boolean;
  actionAerationEnGrand?: boolean;
  actionAerationTransversale?: boolean;
  actionPorte?: boolean;
  resultat: string;
  commentMesure: string;
}

// only in case there is no ventilation, but this field is only indicative for progress
const ANNUEL_MANDATORY_ZONE_FIELDS = 2;

export interface Zone extends BaseRow {
  mode?: string;
  maintenance?: string;
  filtres?: string;
}

export const ONLY_WINDOWS = 'Ouverture des fenêtres uniquement';
export const HYBRID = 'Hybride';
export const MECANICAL = 'Mécanique';
export const SIMPLE_FLUX = MECANICAL + ' avec simple flux';
export const DOUBLE_FLUX = MECANICAL + ' avec double flux';

export const hasMaintenance = (mode: string | undefined) => mode?.startsWith(MECANICAL) || mode?.startsWith(HYBRID);

export const hasFilter = (mode: string | undefined) => mode?.startsWith(DOUBLE_FLUX);

export const weightedMeanFromValueAndWeight = (valuesAndWeights: Array<[number, number]>) => {
  const result = valuesAndWeights
    .map(([value, weight]) => [value * weight, weight])
    .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0]);
  return result[0] / result[1];
};

export const weightedMean = (values: number[], weights: number[]) => {
  const result = values
    .map((value, i) => [value * weights[i], weights[i]])
    .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0]);
  return result[0] / result[1];
};

export const getNecessarySampleRooms = (evaluable: number | undefined): number => {
  if (!evaluable) {
    return 0;
  }
  if (evaluable < 6) {
    return evaluable;
  }
  // Cerema: 50 % minimum des 43 pièces, à savoir dans 22 pièces.
  return Math.max(5, Math.min(20, Math.trunc((evaluable + 1) / 2)));
};

const computeAnnuelProgress = (r: Report): [number, AnnuelProgress] => {
  const necessaryZones = new Set(r.annuelRooms.map((r) => r.zoneId)).size;
  const org = r?.org?.type ? 100 : 0;
  const room = r.necessaryAnnuelRooms ? (100 * r.annuelRooms.length) / r.necessaryAnnuelRooms : 0;
  const aerationRooms = r.necessaryAnnuelRooms
    ? (100 * r.annuelRooms.reduce((a, c) => a + (c.zoneId ? 1 : 0), 0)) / r.necessaryAnnuelRooms
    : 0;
  const aerationZones = necessaryZones
    ? (100 * r.annuelZones.reduce((a, c) => a + (c.mode ? 1 : 0), 0)) / necessaryZones
    : 0;
  const aeration = r.necessaryAnnuelRooms
    ? weightedMean(
        [aerationRooms, aerationZones],
        [r.necessaryAnnuelRooms * ANNUEL_MANDATORY_ROOM_AERATION_FIELDS, necessaryZones * ANNUEL_MANDATORY_ZONE_FIELDS]
      )
    : 0;
  // same number of required fields for monitor and measure parts, so no weighted average required
  const co2 = r.necessaryAnnuelRooms
    ? (100 * r.annuelRooms.reduce((a, c) => a + (c.modele ? 1 : 0) + (c.effectifMax ? 1 : 0), 0)) /
      (r.necessaryAnnuelRooms * 2)
    : 0;
  const communication = [
    r?.org?.annuelReception,
    r?.org?.annuelChef,
    r?.org?.annuelConseil,
    r?.org?.annuelChs,
    r?.org?.annuelAffichage,
  ].reduce((a, c) => a + (c ? 20 : 0), 0);
  // total does not include communication
  const total = weightedMean(
    [org, room, aeration, co2],
    [
      ANNUEL_MANDATORY_ORG_TAB_FIELDS,
      r.necessaryAnnuelRooms,
      r.necessaryAnnuelRooms * ANNUEL_MANDATORY_ROOM_AERATION_FIELDS + necessaryZones * ANNUEL_MANDATORY_ZONE_FIELDS,
      r.necessaryAnnuelRooms * ANNUEL_MANDATORY_ROOM_CO2_FIELDS,
    ]
  );
  return [
    Math.round(total),
    {
      etablissement: Math.round(org),
      pieces: Math.round(room),
      aeration: Math.round(aeration),
      co2: Math.round(co2),
      actions: 100,
      communication: Math.round(communication),
    },
  ];
};

/** "Fake" field for mandatory field list */
const BENZENE_FIELD = 'benzene';
/** "Fake" field for mandatory field list */
const FORMALDEHYDE_FIELD = 'formaldehyde';
/** "Fake" field for mandatory field list */
const OBSERVATION_FIELD = 'observation';

const mandatoryFields = (tab: AutodiagFormTab): string[] => {
  const fields = AUTODIAG_TAB_FIELDS[tab];
  const mandatory: string[] = [];
  if (fields.benzene) {
    mandatory.push(BENZENE_FIELD);
  }
  if (fields.formaldehyde) {
    mandatory.push(FORMALDEHYDE_FIELD);
  }
  if (fields.observations) {
    mandatory.push(OBSERVATION_FIELD);
  }
  return mandatory.concat(
    Object.values(fields.practises)
      .map((p) => Object.keys(p[1]))
      .flat()
  );
};

const isCheckDone = (value: boolean | undefined) => REQUIRED_BOOLEAN.validate(value) === true;

const isCheckboxFieldDone = (thing: Room | Org | undefined, checkboxFields: CheckboxFields | undefined) =>
  checkboxFields &&
  (isCheckDone(thing?.[checkboxFields.noChoiceField]) ||
    isCheckDone(thing?.[checkboxFields.otherChoiceField]) ||
    Object.keys(checkboxFields.choiceFields[1]).some((f) => isCheckDone(thing?.[f])));

const computeAutodiagProgressAndMandatoryFields = (
  thing: Room | Org | undefined,
  tab: AutodiagFormTab
): [number, number] => {
  let doneFields = 0;
  const mandatory = mandatoryFields(tab);

  mandatory.forEach((field) => {
    switch (field) {
      case BENZENE_FIELD:
        if (isCheckboxFieldDone(thing, AUTODIAG_TAB_FIELDS[tab].benzene)) {
          doneFields++;
        }
        break;
      case FORMALDEHYDE_FIELD:
        if (isCheckboxFieldDone(thing, AUTODIAG_TAB_FIELDS[tab].formaldehyde)) {
          doneFields++;
        }
        break;
      case OBSERVATION_FIELD:
        if (isCheckboxFieldDone(thing, AUTODIAG_TAB_FIELDS[tab].observations)) {
          doneFields++;
        }
        break;
      default:
        if (isCheckDone(thing?.[field])) {
          doneFields++;
        }
    }
  });
  return [(doneFields / mandatory.length) * 100, mandatory.length];
};

const computeAutodiagProgress = (report: Report): [number, AutodiagProgress] => {
  const roomDoneAndMandatory = Object.fromEntries(
    report.autodiagRooms.map((r) => [r.id, computeAutodiagProgressAndMandatoryFields(r, AutodiagTab.activites)])
  );
  const communication = [
    report?.org?.autodiagReception,
    report?.org?.autodiagChef,
    report?.org?.autodiagConseil,
    report?.org?.autodiagChs,
  ].reduce((a, c) => a + (c ? 25 : 0), 0);
  const tabDoneAndMandatory: { [key in AutodiagTab]: [number, number] } = {
    etablissement: [report.org?.adresse ? 100 : 0, 4],
    pieces: [100, 0],
    gestion: computeAutodiagProgressAndMandatoryFields(report.org, AutodiagTab.gestion),
    maintenance: computeAutodiagProgressAndMandatoryFields(report.org, AutodiagTab.maintenance),
    entretien: computeAutodiagProgressAndMandatoryFields(report.org, AutodiagTab.entretien),
    activites: [
      report.autodiagRooms.length === 0 ? 0 : weightedMeanFromValueAndWeight(Object.values(roomDoneAndMandatory)),
      Object.values(roomDoneAndMandatory)
        .map((v) => v[1])
        .reduce((a, b) => a + b, 0),
    ],
    actions: [100, 0],
    // total does not include communication
    communication: [communication, 0],
  };
  return [
    Math.round(weightedMeanFromValueAndWeight(Object.values(tabDoneAndMandatory))),
    {
      tab: Object.fromEntries(Object.entries(tabDoneAndMandatory).map((e) => [e[0], Math.round(e[1][0])])) as {
        [key in AutodiagTab]: number;
      },
      room: Object.fromEntries(Object.entries(roomDoneAndMandatory).map((e) => [e[0], Math.round(e[1][0])])),
    },
  ];
};

export interface Name {
  nom: string;
  email?: string;
  function?: string;
}

export const makeNameOptions = (names: Name[] | undefined) =>
  names ? [...new Set(names.map((n) => n.nom))].map((n) => ({ label: n, value: n })) : [];

const mergeNames = (
  target: Map<string, Name>,
  toAdd: Array<{ nom: string | undefined; email?: string; function?: string }>
) => {
  toAdd.forEach((n) => {
    if (!n?.nom) {
      return;
    }
    const exist = target.get(n.nom);
    if (!exist) {
      target.set(n.nom, n as Name);
    } else {
      if (!exist.email && n.email) {
        exist.email = n.email;
      }
      if (!exist.function && n.function) {
        exist.function = n.function;
      }
    }
  });
};

const makeName = (thing: Org | Room, fields: TabFields): Name => ({
  nom: thing[fields.nameField],
  email: thing[fields.emailField],
  function: thing[fields.functionField],
});

function getNames(report: Report): Name[] {
  let names = new Map<string, Name>();
  mergeNames(names, report.org?.respPersonne ? [{ nom: report.org.respPersonne, email: report.org.respEmail }] : []);
  mergeNames(
    names,
    report.allRooms.map((r) => ({ nom: r.responsableAeration, email: r.emailAeration }))
  );
  mergeNames(
    names,
    report.allRooms.map((r) => ({ nom: r.responsableMesure, email: r.emailMesure }))
  );
  mergeNames(
    names,
    report.allRooms.map((r) => ({ nom: r.responsableCapteur, email: r.emailCapteur }))
  );
  Object.entries(AUTODIAG_TAB_FIELDS).forEach(([tab, fields]) => {
    if (tab === AutodiagTab.activites) {
      mergeNames(
        names,
        report.allRooms.map((r) => makeName(r, fields))
      );
    } else {
      mergeNames(names, [makeName(report.org, fields)]);
    }
  });
  return [...names.values()].sort((a, b) => a.nom.localeCompare(b.nom));
}

export type AnnuelProgress = {
  /** Progress in percent (0-100) */
  [key in AnnuelTab]: number;
};

export type AutodiagProgress = {
  /** Tab progress in percent (0-100) */
  tab: { [key in AutodiagTab]: number };
  /** Room progress in percent (0-100) by room id */
  room: { [key in string]: number };
};

export type LabelToState = {
  [key: string]: SaveState;
};

export type EditState = {
  annuel: {
    [key in AnnuelTab]: { saving: LabelToState; expandedRoom?: string; expandedZone?: string };
  };
  autodiag: {
    [key in AutodiagTab]: { saving: LabelToState; expandedRoom?: string; expandedZone?: string };
  };
};

export interface Report {
  // postgres unique user id
  datasourceUser: string;
  // datasource name (not unique)
  datasourceName: string;
  year: string;
  maxRooms: number;
  necessaryAnnuelRooms: number;
  org: Org;
  allRooms: Room[];
  annuelRooms: Room[];
  autodiagRooms: Room[];
  allZones: Zone[];
  annuelZones: Zone[];
  annuelTotalProgress: number;
  annuelTabProgress: AnnuelProgress;
  autodiagTotalProgress: number;
  autodiagTabProgress: AutodiagProgress;
  names: Name[];
  editState: EditState;
}

export function forExport(report: Report) {
  let {
    year,
    necessaryAnnuelRooms,
    org,
    annuelRooms,
    autodiagRooms,
    annuelZones,
    annuelTotalProgress,
    annuelTabProgress,
    autodiagTotalProgress,
    autodiagTabProgress,
  } = report;
  return {
    year,
    necessaryAnnuelRooms,
    org,
    annuelRooms,
    autodiagRooms,
    annuelZones,
    annuelTotalProgress,
    annuelTabProgress,
    autodiagTotalProgress,
    autodiagTabProgress,
  };
}

export const updateReportCalculatedFields = (report: Report) => {
  report.allRooms = report.allRooms.sort((a, b) => a.nom.localeCompare(b.nom));
  report.allZones = report.allZones.sort((a, b) => a.nom.localeCompare(b.nom));
  report.necessaryAnnuelRooms = getNecessarySampleRooms(report?.org?.pieces);
  report.annuelRooms = getAnnuelRooms(report);
  report.annuelZones = getAnnuelZones(report, report.annuelRooms);
  report.autodiagRooms = getAutodiagRooms(report);
  report.names = getNames(report);
  [report.annuelTotalProgress, report.annuelTabProgress] = computeAnnuelProgress(report);
  [report.autodiagTotalProgress, report.autodiagTabProgress] = computeAutodiagProgress(report);
};

export const emptyOrg = (datasource: string) =>
  ({ id: uuidv4(), nom: datasource, annuelPieceIds: [], actions: [] } as Org);

export const emptyReport = (datasourceName: string, datasourceUser: string, year: string): Report => ({
  datasourceName: datasourceName,
  datasourceUser: datasourceUser,
  year: year,
  maxRooms: 1,
  necessaryAnnuelRooms: 0,
  org: emptyOrg(datasourceName),
  allRooms: [],
  annuelRooms: [],
  autodiagRooms: [],
  allZones: [],
  annuelZones: [],
  names: [],
  annuelTotalProgress: 0,
  annuelTabProgress: { etablissement: 0, pieces: 0, aeration: 0, co2: 0, actions: 100, communication: 0 },
  autodiagTotalProgress: 0,
  autodiagTabProgress: {
    tab: {
      etablissement: 0,
      pieces: 0,
      gestion: 0,
      maintenance: 0,
      entretien: 0,
      activites: 0,
      actions: 100,
      communication: 0,
    },
    room: {},
  },
  editState: {
    annuel: {
      etablissement: { saving: {} },
      pieces: { saving: {} },
      aeration: { saving: {} },
      co2: { saving: {} },
      actions: { saving: {} },
      communication: { saving: {} },
    },
    autodiag: {
      etablissement: { saving: {} },
      pieces: { saving: {} },
      gestion: { saving: {} },
      maintenance: { saving: {} },
      entretien: { saving: {} },
      activites: { saving: {} },
      actions: { saving: {} },
      communication: { saving: {} },
    },
  },
});

export const hasOneOrg = (reports: Report[]) =>
  reports.length > 0 && reports.every((r) => r.datasourceUser === reports[0].datasourceUser);
