import jsPDF from 'jspdf';
import autoTable, { CellDef, CellInput, ColumnInput, RowInput, Styles, UserOptions } from 'jspdf-autotable';
import { DEFAULT_FONT, addDefaultFont } from 'utils/utils.font';
import { formatDate, formatDateString, minusOneYear } from './utils.format';
import { ActionStatus, Report, ReportType, reportDate, sortActions } from './utils.model';
import { REPORT_GREEN, REPORT_MEO_BLUE, REPORT_YELLOW } from './utils.styles';
import { Parser } from '@json2csv/plainjs';
import saveAs from 'file-saver';

export const VERTICAL_LINE_WIDTH = 0.4;
export const HORIZONTAL_LINE_WIDTH = 0.4;

export const TABLE_TOP_MARGIN = 3;

export const TITLE_FONT_SIZE = 23;
export const SUBTITLE_FONT_SIZE = 20;
export const REPORT_TITLE_FONT_SIZE = 16;
export const REPORT_TOP_RIGHT_FONT_SIZE = 8;

export const SECTION_TITLE_FONT_SIZE = 15;
export const TABLE_TITLE_FONT_SIZE = 12;
export const TABLE_SUBTITLE_FONT_SIZE = 11;
export const FONT_SIZE = 10;

export const EMOTICON_SIZE = 15;

export const CELL_PADDING = 1.5;
// 6mm from https://stackoverflow.com/a/3513476
// bottom margin to avoid table overlapping on footer
export const PAGE_MARGIN = 7;

export const SMALL_LOGO_SIZE = 20;

export const BIG_LOGO_SIZE = 30;

export const FULL_WIDTH = 210 - PAGE_MARGIN * 2;
export const WIDTH_2 = FULL_WIDTH / 2;
export const WIDTH_3 = FULL_WIDTH / 3;
export const WIDTH_4 = FULL_WIDTH / 4;
export const WIDTH_6 = FULL_WIDTH / 6;
export const WIDTH_8 = FULL_WIDTH / 8;

// some other sizes where there is an auto column
export const SIZE_1 = 14;
export const SIZE_1_5 = 1.5 * SIZE_1;
export const SIZE_1_66 = (5 / 3) * SIZE_1;
export const SIZE_2 = 2 * SIZE_1;
export const SIZE_2_5 = 2.5 * SIZE_1;
export const SIZE_3 = 3 * SIZE_1;
export const SIZE_4_66 = (14 / 3) * SIZE_1;

export function createDoc(): jsPDF {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });
  addDefaultFont(doc);
  doc.setFont(DEFAULT_FONT, 'normal');
  doc.setFontSize(FONT_SIZE);
  return doc;
}

export async function getLogo(src: string | undefined): Promise<Logo | undefined> {
  if (src) {
    let img = new Image();
    img.src = src;
    await img.decode();
    return { src: src, width: img.width, height: img.height };
  }
  return Promise.resolve(undefined);
}

export interface Logo {
  src: string;
  width: number;
  height: number;
}

export function addLogo(doc: jsPDF, logo: Logo | undefined, pdfImgWidth = SMALL_LOGO_SIZE) {
  if (logo) {
    doc.addImage(logo.src, 'UNKNOWN', PAGE_MARGIN, PAGE_MARGIN, pdfImgWidth, (pdfImgWidth * logo.height) / logo.width);
  }
}
export const columnsFromStyles = (styles: { [key: string]: Partial<Styles> }): ColumnInput[] =>
  Object.keys(styles).map((k) => ({ dataKey: k }));

export const fileName = (orgName: string, year: string, title: string) => `${orgName} - ${year} - ${title}`;

export const addFooters = (
  doc: jsPDF | undefined,
  reportType: ReportType,
  report: Report,
  orgName: string,
  resetPageNumber: number | undefined,
  beforeResetTitle: string,
  afterResetTitle: string
) => {
  if (!doc || !resetPageNumber) {
    return;
  }
  const totalPageCount = doc.getNumberOfPages();
  doc.setFont(DEFAULT_FONT, 'italic');
  doc.setFontSize(8);
  for (let i = 1; i <= totalPageCount; i++) {
    doc.setPage(i);
    const y = doc.internal.pageSize.height - 5;
    const pageNum = i <= resetPageNumber ? i : i - resetPageNumber;
    const pageCount = i <= resetPageNumber ? resetPageNumber : totalPageCount - resetPageNumber;
    const title = i <= resetPageNumber ? beforeResetTitle : afterResetTitle;
    doc.text('Page ' + pageNum + ' / ' + pageCount, doc.internal.pageSize.width - 30, y);
    doc.text('www.meo.life', 15, y);
    doc.text(
      orgName + ' – ' + title + ' – ' + formatDate(reportDate(reportType, report)),
      doc.internal.pageSize.width / 2,
      y,
      {
        align: 'center',
      }
    );
  }
};

export const TITLE_STYLES: Partial<Styles> = {
  cellPadding: CELL_PADDING,
  fillColor: REPORT_MEO_BLUE,
  textColor: 255,
  fontSize: TABLE_TITLE_FONT_SIZE,
  font: DEFAULT_FONT,
  fontStyle: 'normal',
  halign: 'left',
};

export const CENTER_TITLE_STYLES: Partial<Styles> = { ...TITLE_STYLES, halign: 'center' };

export const SUBTITLE_STYLES: Partial<Styles> = {
  cellPadding: CELL_PADDING,
  fontSize: TABLE_SUBTITLE_FONT_SIZE,
  font: DEFAULT_FONT,
  fontStyle: 'bold',
  halign: 'left',
};

export const HEAD_STYLES: Partial<Styles> = {
  lineWidth: { top: 0, bottom: HORIZONTAL_LINE_WIDTH, left: 0, right: 0 },
  cellPadding: CELL_PADDING,
  halign: 'center',
  fontSize: FONT_SIZE,
  font: DEFAULT_FONT,
  fontStyle: 'bold',
  textColor: 0,
  fillColor: 255,
};

export const BODY_STYLES: Partial<Styles> = {
  cellPadding: CELL_PADDING,
  fontSize: FONT_SIZE,
  font: DEFAULT_FONT,
  fontStyle: 'normal',
};

export const BORDER_LEFT = { lineWidth: { top: 0, bottom: 0, left: VERTICAL_LINE_WIDTH, right: 0 } };
export const BORDER_LEFT_HEAD = {
  lineWidth: { top: 0, bottom: HORIZONTAL_LINE_WIDTH, left: VERTICAL_LINE_WIDTH, right: 0 },
};
export const NO_BORDER = { top: 0, bottom: 0, left: 0, right: 0 };

export const FOUR_COLUMNS_STYLES: {
  [key: string]: Partial<Styles>;
} = {
  0: { fontSize: FONT_SIZE, font: DEFAULT_FONT, fontStyle: 'bold', cellWidth: 38 },
  1: { fontSize: FONT_SIZE, cellWidth: 60 },
  2: { fontSize: FONT_SIZE, font: DEFAULT_FONT, fontStyle: 'bold', cellWidth: 38, ...BORDER_LEFT },
  3: { fontSize: FONT_SIZE, cellWidth: 60 },
};

export const SECTION_TITLE_COLUMNS_STYLES: { [key: string]: Partial<Styles> } = {
  0: {
    fontSize: SECTION_TITLE_FONT_SIZE,
    fontStyle: 'bold',
    halign: 'left',
    cellWidth: 'auto',
    cellPadding: 0,
    ...NO_BORDER,
    fillColor: undefined,
  },
};

export const PARAGRAPH_COLUMNS_STYLES: { [key: string]: Partial<Styles> } = {
  0: {
    fontSize: FONT_SIZE,
    halign: 'left',
    cellWidth: 'auto',
    cellPadding: 0,
    ...NO_BORDER,
    fillColor: undefined,
  },
};

(jsPDF as any).autoTableSetDefaults({
  margin: { right: PAGE_MARGIN, left: PAGE_MARGIN, top: PAGE_MARGIN, bottom: 11 },
  cellPadding: CELL_PADDING,
  theme: 'striped',
  tableLineColor: REPORT_MEO_BLUE,
  tableLineWidth: VERTICAL_LINE_WIDTH,
  headStyles: HEAD_STYLES,
  bodyStyles: BODY_STYLES,
  lineColor: REPORT_MEO_BLUE,
  lineWidth: 0,
  valign: 'middle',
  pageBreak: 'avoid',
  rowPageBreak: 'avoid',
  font: DEFAULT_FONT,
  fontStyle: 'normal',
} as UserOptions);

export const PAR_DECRET = 'par décret n° 2022-1690\ndu 27 décembre 2022';

export const QAI_TITLE = 'Qualité de l’air intérieur';

export function addMargin(doc: jsPDF | undefined, margin: number) {
  return (doc as any)?.lastAutoTable.finalY + margin;
}

export enum FileFormat {
  PDF = 'PDF',
  CSV = 'CSV',
}

export function saveFile(doc: jsPDF | undefined, data: string[][] | undefined, filename: string) {
  if (doc) {
    doc.save(filename + '.pdf');
  }
  if (data) {
    const parser = new Parser({ header: false });
    const blob = new Blob([String.fromCharCode(0xfeff), parser.parse(data)], {
      type: 'text/csv;charset=utf-8',
    });
    saveAs(blob, filename + '.csv');
  }
}

function addCsv(row: CellInput[], csv: any[][]) {
  csv.push(row.map((r) => ((r as CellDef)?.hasOwnProperty('content') ? (r as CellDef).content : r)));
}

export function genTable(doc: jsPDF | undefined, csv: string[][] | undefined, options: UserOptions) {
  if (doc) {
    autoTable(doc, options);
  }
  if (csv) {
    options.head?.forEach((c) => addCsv(c as CellInput[], csv));
    options.body?.forEach((c) => addCsv(c as CellInput[], csv));
  }
}

export function titleHeader(
  doc: jsPDF | undefined,
  csv: string[][] | undefined,
  logo: Logo | undefined,
  title: string,
  subtitle: string | undefined,
  reportTitle: string,
  topRight: string,
  sideColumnsWidth = 35
): void {
  genTable(doc, csv, {
    startY: PAGE_MARGIN,
    body: [
      [
        { content: '', styles: { cellWidth: sideColumnsWidth } },
        {
          content: title,
          styles: {
            halign: 'center',
            cellWidth: 'auto',
            font: DEFAULT_FONT,
            fontSize: TITLE_FONT_SIZE,
            cellPadding: 0,
          },
        },
        {
          content: topRight,
          styles: {
            halign: 'right',
            cellWidth: sideColumnsWidth,
            font: DEFAULT_FONT,
            fontSize: REPORT_TOP_RIGHT_FONT_SIZE,
            cellPadding: 0,
          },
        },
      ] as RowInput,
    ]
      .concat(
        subtitle
          ? [
              [
                { content: '', styles: { cellWidth: sideColumnsWidth } },
                {
                  content: subtitle,
                  styles: { halign: 'center', font: DEFAULT_FONT, fontSize: SUBTITLE_FONT_SIZE },
                },
                { content: '', styles: { cellWidth: sideColumnsWidth } },
              ],
            ]
          : []
      )
      .concat([
        [
          { content: '', styles: { cellWidth: sideColumnsWidth } },
          {
            content: reportTitle,
            styles: { halign: 'center', font: DEFAULT_FONT, fontSize: REPORT_TITLE_FONT_SIZE },
          },
          { content: '', styles: { cellWidth: sideColumnsWidth } },
        ],
      ]),
    theme: 'plain',
    tableLineWidth: 0,
    styles: {
      fontSize: FONT_SIZE,
    },
  });
  if (doc) {
    addLogo(doc, logo, subtitle ? BIG_LOGO_SIZE : SMALL_LOGO_SIZE);
  }
}

export function generateActionPlan(
  doc: jsPDF | undefined,
  csv: string[][] | undefined,
  reportType: ReportType,
  report: Report,
  logo: Logo | undefined
) {
  doc?.addPage('a4', 'landscape');
  titleHeader(doc, csv, logo, QAI_TITLE, report.org.nom, 'Plan d’action', PAR_DECRET);
  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        { content: 'Titre', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Description', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Responsable', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Partenaires', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Échéance', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Statut', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
      ],
    ],
    body: report.org.actions
      .filter((a) => {
        const reportDay = reportDate(reportType, report);
        const reportDayMinusOneYear = minusOneYear(reportDay);
        if (!a.echeance) {
          return false;
        }
        const echeance = new Date(a.echeance);
        return (
          a.statut === ActionStatus.EnCours ||
          (a.statut === ActionStatus.Réalisé &&
            reportDayMinusOneYear.getTime() < echeance.getTime() &&
            echeance.getTime() <= reportDay.getTime())
        );
      })
      .sort((a, b) => sortActions(reportDate(reportType, report), a, b))
      .map((a) => [
        a.nom,
        a.description,
        a.responsable ?? '',
        a.partenaires ?? '',
        formatDateString(a.echeance),
        { content: a.statut, styles: { textColor: a.statut === ActionStatus.EnCours ? REPORT_YELLOW : REPORT_GREEN } },
      ]),
    columnStyles: {
      0: { fontSize: FONT_SIZE, cellWidth: 50, halign: 'left' },
      1: { fontSize: FONT_SIZE, cellWidth: 'auto', halign: 'left', ...BORDER_LEFT },
      2: { fontSize: FONT_SIZE, cellWidth: 40, halign: 'left', ...BORDER_LEFT },
      3: { fontSize: FONT_SIZE, cellWidth: 40, halign: 'left', ...BORDER_LEFT },
      4: { fontSize: FONT_SIZE, cellWidth: 25, halign: 'left', ...BORDER_LEFT },
      5: { fontSize: FONT_SIZE, cellWidth: 20, halign: 'left', ...BORDER_LEFT },
    },
  });

  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [[`Maitre d’ouvrage :`, 'Date : ', 'Signature : ']],
    columnStyles: {
      0: { fontSize: FONT_SIZE, halign: 'left', cellWidth: 100, ...NO_BORDER, fillColor: undefined },
      1: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_4_66, ...NO_BORDER, fillColor: undefined },
      2: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_4_66, ...NO_BORDER, fillColor: undefined },
    },
    tableLineWidth: 0,
  });
}
