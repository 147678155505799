import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import {
  Button,
  CellProps,
  Collapse,
  Column,
  Form,
  HorizontalGroup,
  Input,
  LinkButton,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';

import { css } from '@emotion/css';
import { GrafanaTheme2, IconName } from '@grafana/data';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { formatDeadline, utcDate, utcTime } from 'utils/utils.format';
import { Org, Report, Room, TabObjectState, makeNameOptions } from 'utils/utils.model';
import { ANNUEL_TAB_DETAILS, AnnuelAnchorStart, AnnuelTab, reportUrl } from 'utils/utils.routing';
import { DATETIME_WIDTH, FORM_MAX_WIDTH, getStyles } from 'utils/utils.styles';
import { v5 as uuidv5 } from 'uuid';
import {
  CO2MeasureDates,
  CalendarButton,
  CalendarEvent,
  CreatableSelect,
  EMAIL_PATTERN,
  EditTable,
  GUIDE_CSTB,
  OPTIONAL,
  PERSON_PLACEHOLDER,
  SalleFields,
  SaveButton,
  SaveState,
  SmallFieldSet,
  TooltipField,
  TooltipLabel,
  UUID_NAMESPACE,
  addAttendee,
  expanderCell,
} from '../../utils/utils.form';
import { BuyButtonComponent } from './BuyButtonComponent';
import { updateThing } from './queries';

interface ExpandedRowProps {
  report: Report;
  setReports: Dispatch<SetStateAction<Report[]>>;
  room?: RoomWithContext;
  button: string;
  closeAdd: () => void;
}

function ExpandedRow({ report, setReports, room, button, closeAdd }: ExpandedRowProps) {
  const [buttonSaving, setButtonSaving] = useState(undefined as SaveState);
  const [expandPlanning, setExpandPlanning] = useState(false);
  const styles = useStyles2(getStyles);
  const submitRoom = (room: Room) => {
    setButtonSaving('saving');
    const org = {
      ...report.org,
      annuelPieceIds: room.id
        ? Array.from(new Set([...report.org.annuelPieceIds, room.id]))
        : report.org.annuelPieceIds,
    } as Org;
    updateThing(
      'annuel',
      report,
      { room: room, org: org },
      () => {
        closeAdd();
        setButtonSaving('saved');
      },
      (err) => setButtonSaving(err),
      setReports
    );
  };
  const removeRoom = (room: Room) => {
    setButtonSaving('saving');
    const org = {
      nom: report.org.nom,
      id: report.org.id,
      annuelPieceIds: report.org.annuelPieceIds.filter((i) => i !== room.id),
    };
    updateThing(
      'annuel',
      report,
      { org: org },
      () => setButtonSaving('saved'),
      (err) => setButtonSaving(err),
      setReports
    );
  };
  return (
    <Form<Room> onSubmit={submitRoom} maxWidth={FORM_MAX_WIDTH}>
      {({ register, errors, control, watch, setValue }) => (
        <>
          <SalleFields
            roomLabel="Salle"
            reportType={'annuel'}
            report={report}
            room={room}
            errors={errors}
            control={control}
            register={register}
            setValue={setValue}
          />
          <Collapse
            label="Planning facultatif"
            key="planning"
            isOpen={expandPlanning}
            onToggle={(isOpen) => setExpandPlanning(isOpen)}
            collapsible={true}
            className={styles.collapse}
          >
            <SmallFieldSet label="Examen des moyens d’aération">
              <TooltipField label="Responsable" error={errors.responsableAeration}>
                <CreatableSelect
                  name={'responsableAeration'}
                  autoComplete="name"
                  defaultValue={room?.responsableAeration}
                  control={control}
                  register={register}
                  additionalOnChange={(v) => {
                    const name = report.names.find((n) => n.nom === v);
                    if (name) {
                      setValue('emailAeration', name.email);
                    }
                  }}
                  placeholder={PERSON_PLACEHOLDER}
                  defaultOptions={makeNameOptions(report.names)}
                />
              </TooltipField>
              <TooltipField label="Courriel" error={errors.emailAeration}>
                <Input
                  {...register('emailAeration', EMAIL_PATTERN)}
                  autoComplete="email"
                  defaultValue={room?.emailAeration}
                  type="email"
                  placeholder={OPTIONAL}
                />
              </TooltipField>
              <TooltipField label="Date" error={errors.emailAeration}>
                <Input
                  {...register('dateAeration')}
                  defaultValue={room?.dateAeration}
                  width={DATETIME_WIDTH}
                  type="datetime-local"
                  lang="fr-FR"
                />
              </TooltipField>
            </SmallFieldSet>
            <SmallFieldSet label="Préparation du capteur CO₂">
              <TooltipField label="Responsable" error={errors.responsableAeration}>
                <CreatableSelect
                  control={control}
                  register={register}
                  name={'responsableCapteur'}
                  autoComplete="name"
                  defaultValue={room?.responsableCapteur}
                  additionalOnChange={(v) => {
                    const name = report.names.find((n) => n.nom === v);
                    if (name) {
                      setValue('emailCapteur', name.email);
                    }
                  }}
                  placeholder={PERSON_PLACEHOLDER}
                  defaultOptions={makeNameOptions(report.names)}
                />
              </TooltipField>
              <TooltipField label="Courriel" error={errors.emailAeration}>
                <Input
                  {...register('emailCapteur', EMAIL_PATTERN)}
                  autoComplete="email"
                  defaultValue={room?.emailCapteur}
                  type="email"
                  placeholder={OPTIONAL}
                />
              </TooltipField>
              <TooltipField label="Date" error={errors.emailAeration}>
                <Input
                  {...register('dateCapteur')}
                  defaultValue={room?.dateCapteur}
                  width={DATETIME_WIDTH}
                  type="datetime-local"
                  lang="fr-FR"
                />
              </TooltipField>
            </SmallFieldSet>
            <SmallFieldSet label="Mesure du CO₂">
              <TooltipField label="Responsable" error={errors.responsableAeration}>
                <CreatableSelect
                  register={register}
                  control={control}
                  name={'responsableMesure'}
                  autoComplete="name"
                  additionalOnChange={(v) => {
                    const name = report.names.find((n) => n.nom === v);
                    if (name) {
                      setValue('emailMesure', name.email);
                    }
                  }}
                  placeholder={PERSON_PLACEHOLDER}
                  defaultValue={room?.responsableMesure}
                  defaultOptions={makeNameOptions(report.names)}
                />
              </TooltipField>
              <TooltipField label="Courriel" error={errors.emailAeration}>
                <Input
                  {...register('emailMesure', EMAIL_PATTERN)}
                  autoComplete="email"
                  defaultValue={room?.emailMesure}
                  type="email"
                  placeholder={OPTIONAL}
                />
              </TooltipField>
              <CO2MeasureDates
                rules={{}}
                report={report}
                room={room}
                register={register}
                watch={watch}
                errors={errors}
                setValue={setValue}
              />
            </SmallFieldSet>
          </Collapse>
          <HorizontalGroup align="flex-start" justify="flex-end">
            {!room && (
              <Button type="button" variant="secondary" onClick={closeAdd}>
                Annuler
              </Button>
            )}
            {room && (
              <Button
                type="button"
                variant="destructive"
                onClick={() => {
                  removeRoom(room);
                }}
              >
                Retirer de l’échantillon
              </Button>
            )}
            <SaveButton label={button} state={buttonSaving} setState={setButtonSaving} />
          </HorizontalGroup>
        </>
      )}
    </Form>
  );
}

interface RoomWithContext extends Room {
  datasourceName: string;
  year: string;
}

const responsibleCell = (
  tooltip: string,
  nameField: keyof RoomWithContext,
  emailField: keyof RoomWithContext,
  dateField: keyof RoomWithContext,
  doneField: keyof RoomWithContext,
  tab: AnnuelTab,
  anchorStart: AnnuelAnchorStart
) =>
  function ResponsibleCell({ row: { original: original } }: CellProps<RoomWithContext, void>) {
    const styles = useStyles2(getRoomTabStyles);
    const row = original as RoomWithContext;
    const deadline = row[dateField] ? new Date(row[dateField]) : undefined;
    const daysToDeadline = deadline ? Math.trunc((deadline.getTime() - new Date().getTime()) / (3600000 * 24)) : 0;
    const icon: IconName = ANNUEL_TAB_DETAILS[tab].titleIcon;
    let variant: 'secondary' | 'destructive' | 'success' = 'secondary';
    const done = row[doneField] !== undefined;
    if (done) {
      variant = 'success';
    } else if (daysToDeadline < 0) {
      variant = 'destructive';
    }
    return (
      <div className={styles.column}>
        <HorizontalGroup>
          <LinkButton
            tooltip={tooltip}
            icon={icon}
            variant={variant}
            href={reportUrl('annuel', row.datasourceName, row.year, tab, anchorStart, row.id)}
          >
            {ANNUEL_TAB_DETAILS[tab].title}
          </LinkButton>
          {row[emailField] ? <a href={'mailto:' + row[emailField]}>{row[nameField]}</a> : row[nameField]}
          {!done && deadline ? ` (${formatDeadline(deadline)})` : ' '}
        </HorizontalGroup>
      </div>
    );
  };

const columns: Array<Column<RoomWithContext>> = [
  {
    id: 'nom',
    header: 'Salle',
    cell: expanderCell<RoomWithContext>((row) => row.nom),
  },
  {
    id: 'responsableAeration',
    header: 'Examen des moyens d’aération',
    cell: responsibleCell(
      'Éditer l’examen des moyens d’aération',
      'responsableAeration',
      'emailAeration',
      'dateAeration',
      'ouvrants',
      AnnuelTab.aeration,
      AnnuelAnchorStart.aeration
    ),
  },
  {
    id: 'responsableCapteur',
    header: 'Préparation du capteur CO₂',
    cell: responsibleCell(
      'Éditer la préparation du capteur CO₂',
      'responsableCapteur',
      'emailCapteur',
      'dateCapteur',
      'modele',
      AnnuelTab.co2,
      AnnuelAnchorStart.capteur
    ),
  },
  {
    id: 'responsableMesure',
    header: 'Mesure du CO₂',
    cell: responsibleCell(
      'Éditer la mesure du CO₂',
      'responsableMesure',
      'emailMesure',
      'dateHeureDebutMesure',
      'ppm800',
      AnnuelTab.co2,
      AnnuelAnchorStart.co2
    ),
  },
];

export class AnnuelRoomSceneObject extends SceneObjectBase<TabObjectState> {
  static Component = AnnuelRoomSceneObjectRenderer;

  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({ report: report, setReports: setReports, ...state });
  }
}

const sampleTooltip = (listClass: string, nestedListClass: string) => (
  <>
    <p>Les pièces non soumises à la réglementation doivent être exclues.</p>
    <p>
      L’évaluation doit être réalisée sur un échantillon représentatif de pièces, correspondant à 50&nbsp;% minimum des
      pièces soumises à la réglementation de l’établissement, avec un minimum de 5 pièces et un maximum de 20 pièces.
    </p>
    <p>
      Ces pièces doivent représenter au mieux les différentes zones du bâtiment en étant&nbsp;:
      <ul className={listClass}>
        <li>réparties dans les différents bâtiments et dans les différents étages,</li>
        <li>choisies en fonction&nbsp;:</li>
        <ul className={nestedListClass}>
          <li>
            de la configuration des bâtiments (isolation, exposition ou autres paramètres pouvant influencer la qualité
            de l’air intérieur)&nbsp;;
          </li>
          <li>de la période de construction&nbsp;;</li>
          <li>des travaux effectués et susceptibles d’avoir un impact sur la qualité de l’air intérieur&nbsp;;</li>
          <li>de la présence ou non d’ouvrants donnant sur l’extérieur&nbsp;;</li>100%
          <li>des activités et occupations susceptibles d’avoir un impact sur la qualité de l’air intérieur&nbsp;;</li>
          <li>des moyens d’aération (usage des ouvrants, recommandations)&nbsp;;</li>
          <li>le cas échéant, du type de ventilation mécanique.</li>
        </ul>
      </ul>
    </p>
    <p>Source&nbsp;: {GUIDE_CSTB}</p>
  </>
);

const roomButtonLabel = (necessaryAnnuelRooms: number, actualRoomWithContexts: number): string => {
  if (!necessaryAnnuelRooms) {
    return '';
  }
  const remaining = necessaryAnnuelRooms - actualRoomWithContexts;
  if (remaining === 0) {
    return 'Le nombre de salles nécessaire a été ajouté.';
  }
  if (remaining === 1) {
    return `Une salle de plus est nécessaire.`;
  }
  if (remaining > 0) {
    return `${remaining} salles de plus sont nécessaires.`;
  }
  return `${-remaining} salle${remaining < -1 ? 's' : ''} de plus que nécessaire.`;
};

function calendarDate(
  event: string,
  urlTab: AnnuelTab,
  anchorStart: AnnuelAnchorStart,
  report: Report,
  room: Room,
  attendeeName: keyof Room,
  attendeeEmail: keyof Room,
  start: keyof Room,
  end?: keyof Room
): CalendarEvent | undefined {
  if (!room[start]) {
    return undefined;
  }
  const startDate = new Date(room[start]);
  let endDate;
  if (end) {
    endDate = new Date(room[end]);
  } else {
    endDate = new Date(room[start]);
    endDate.setMinutes(endDate.getMinutes() + 30);
  }
  const org = report.org.nom ?? report.datasourceName;
  return addAttendee(report, room[attendeeName], room[attendeeEmail], {
    name: `${event} - ${room.nom} (${org})`,
    description: `À compléter dans [url]https://app.meo.life${reportUrl(
      'annuel',
      encodeURIComponent(report.datasourceName),
      report.year,
      urlTab,
      anchorStart,
      room.id
    )}|le logiciel meo[/url] pour le rapport annuel obligatoire sur la Qualité de l’Air Intérieur.`,
    startDate: utcDate(startDate),
    startTime: utcTime(startDate),
    endDate: utcDate(endDate),
    endTime: utcTime(endDate),
    location: `${room.nom}, ${org}, ${report.org.adresse ?? ''}, ${report.org.codePostal ?? ''} ${
      report.org.ville ?? ''
    }`,
    // different from URL to use ids instead of names which could change
    uid: uuidv5(`annuel/${report.datasourceUser}/${report.year}/${urlTab}/${room.id}/${anchorStart}`, UUID_NAMESPACE),
  });
}

function calendarDates(report: Report): CalendarEvent[] {
  return report.annuelRooms
    .map((r) => [
      calendarDate(
        'Examen des moyens d’aération',
        AnnuelTab.aeration,
        AnnuelAnchorStart.aeration,
        report,
        r,
        'responsableAeration',
        'emailAeration',
        'dateAeration'
      ),
      calendarDate(
        'Préparation du capteur CO2',
        AnnuelTab.co2,
        AnnuelAnchorStart.capteur,
        report,
        r,
        'responsableCapteur',
        'emailCapteur',
        'dateCapteur'
      ),
      calendarDate(
        'Mesure du CO2',
        AnnuelTab.co2,
        AnnuelAnchorStart.co2,
        report,
        r,
        'responsableMesure',
        'emailMesure',
        'dateHeureDebutMesure',
        'dateHeureFinMesure'
      ),
    ])
    .flat()
    .filter((item): item is CalendarEvent => !!item);
}

function AnnuelRoomSceneObjectRenderer({ model }: SceneComponentProps<AnnuelRoomSceneObject>) {
  const { report, setReports } = model.useState();
  const styles = useStyles2(getStyles);
  const actualRooms = report.annuelRooms;
  const maxRoomsReached = report.annuelRooms.length >= report.maxRooms;
  return (
    <VerticalGroup>
      <EditTable
        topButton="Ajouter une salle"
        topButtonDisabled={maxRoomsReached}
        topButtonTooltip={maxRoomsReached ? <>Le nombre maximal de salles de l’abonnement a été atteint.</> : undefined}
        secondaryTopElements={
          <HorizontalGroup justify="flex-start" spacing="lg" wrap>
            {report.necessaryAnnuelRooms ? (
              <TooltipLabel
                tooltip={sampleTooltip(styles.list, styles.nestedlist)}
                label={roomButtonLabel(report.necessaryAnnuelRooms, actualRooms.length)}
              />
            ) : undefined}
            {maxRoomsReached && actualRooms.length < report.necessaryAnnuelRooms ? (
              <BuyButtonComponent
                clientReferenceId={report.datasourceUser}
                quantity={report.necessaryAnnuelRooms - actualRooms.length}
              />
            ) : undefined}
            <CalendarButton
              name={`Qualité de l’Air Intérieur - ${report.org.nom ?? report.datasourceName}`}
              calendarDates={calendarDates(report)}
            />
          </HorizontalGroup>
        }
        addButton="Ajouter la salle"
        saveButton="Modifier"
        columns={columns}
        data={actualRooms.map((r) => ({ ...r, datasourceName: report.datasourceName, year: report.year }))}
        showFormWhen={0}
        renderExpandedRow={(r, button, closeAdd) => (
          <ExpandedRow report={report} setReports={setReports} room={r} button={button} closeAdd={closeAdd} />
        )}
      />
    </VerticalGroup>
  );
}

const getRoomTabStyles = (theme: GrafanaTheme2) => ({
  column: css`
    min-width: 300px;
  `,
});
