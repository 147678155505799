import { cx } from '@emotion/css';
import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import {
  Collapse,
  Form,
  HorizontalGroup,
  Input,
  InputControl,
  RadioButtonGroup,
  Select,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Report, Room, TabObjectState, makeNameOptions } from 'utils/utils.model';
import { AnnuelAnchorStart } from 'utils/utils.routing';
import { DATETIME_WIDTH, DATE_WIDTH, FORM_MAX_WIDTH, getStyles, makeOptions } from 'utils/utils.styles';
import {
  AutosizeTextArea,
  CO2MeasureDates,
  CreatableSelect,
  EmptyRooms,
  GUIDE_CSTB,
  NumberInput,
  OPTIONAL,
  OPTIONAL_PERSON_PLACEHOLDER,
  Progress,
  REQUIRED,
  REQUIRED_BOOLEAN,
  STRICTLY_POSITIVE,
  SaveButton,
  SaveState,
  SmallFieldSet,
  TooltipCheckbox,
  TooltipField,
} from '../../utils/utils.form';
import { updateThing } from './queries';
import { minusOneYear } from 'utils/utils.format';

export const LESS_THAN_1500_PPM_RESULT = 'Diminution encore insuffisante';
export const MORE_THAN_1500_PPM_RESULT = 'Persistance du dépassement';
const MANUEL = 'Manuel';

const modeControleDescription = (modeValeur?: boolean, modeIndicateur?: boolean, modeEnregistrement?: boolean) => {
  const modeVisuel = modeValeur || modeEnregistrement;
  if (modeVisuel) {
    if (modeEnregistrement) {
      return 'd’affichage et d’enregistrement';
    } else {
      return 'd’affichage';
    }
  } else {
    if (modeEnregistrement) {
      return 'd’enregistrement';
    } else {
      // also when only "sonore" is true
      return 'd’affichage ou d’enregistrement';
    }
  }
};

const monitorSavingId = (roomId: string) => 'room-' + roomId;
const co2SavingId = (roomId: string) => 'zone-' + roomId;

export class AnnuelCO2SceneObject extends SceneObjectBase<TabObjectState> {
  static Component = AnnuelCO2SceneObjectRenderer;

  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({
      report: report,
      setReports: setReports,
      ...state,
    });
  }
  setExpandRoom = (roomId: string | undefined) => (isOpen: boolean) => {
    this.state.report.editState.annuel.co2.expandedRoom = isOpen ? roomId : undefined;
    this.setState({ report: this.state.report });
  };
  setRoomSaveState = (roomId: string, key: 'monitorSaving' | 'co2Saving', saveState: SaveState) => {
    if (key === 'monitorSaving') {
      this.state.report.editState.annuel.co2.saving[monitorSavingId(roomId)] = saveState;
    } else {
      this.state.report.editState.annuel.co2.saving[co2SavingId(roomId)] = saveState;
    }
    this.setState({ report: this.state.report });
  };
}

const monitorVerificationMoreThanOneYear = (
  dateEtalonnage: string | undefined,
  dateHeureDebutMesure: string | undefined
) => {
  return (
    dateEtalonnage &&
    new Date(dateEtalonnage) < minusOneYear(dateHeureDebutMesure ? new Date(dateHeureDebutMesure) : new Date())
  );
};

function AnnuelCO2SceneObjectRenderer({ model }: SceneComponentProps<AnnuelCO2SceneObject>) {
  const { report, setReports } = model.useState();
  const location = useLocation();
  const styles = useStyles2(getStyles);
  const rooms = report.annuelRooms;
  const editState = report.editState.annuel.co2;

  useEffect(() => {
    // TODO scroll to section
    let id = undefined;
    if (location.hash.startsWith(AnnuelAnchorStart.capteur)) {
      id = location.hash.substring(AnnuelAnchorStart.capteur.length);
    }
    if (location.hash.startsWith(AnnuelAnchorStart.co2)) {
      id = location.hash.substring(AnnuelAnchorStart.co2.length);
    }
    if (id) {
      model.setExpandRoom(id)(true);
    }
  }, [location.hash, model, rooms]);

  const doSubmitRoom = (room: Room, saveKey: 'monitorSaving' | 'co2Saving') => {
    model.setRoomSaveState(room.id, saveKey, 'saving');
    updateThing(
      'annuel',
      report,
      { room: room },
      () => {
        model.setExpandRoom(undefined)(false);
        model.setRoomSaveState(room.id, saveKey, undefined);
      },
      (err) => model.setRoomSaveState(room.id, saveKey, err),
      setReports
    );
  };

  return (
    <VerticalGroup>
      {rooms.length === 0 && <EmptyRooms type="annuel" report={report} />}
      {rooms.map((room, idx) => (
        <VerticalGroup key={room.id} width="100%">
          <Collapse
            key={room.id}
            label={
              <>
                <span className={cx([styles.collapseLabel])}>{room.nom} </span>
                <Progress value={(room.modele ? 50 : 0) + (room.effectifMax ? 50 : 0)} />
              </>
            }
            isOpen={editState.expandedRoom === room.id}
            onToggle={model.setExpandRoom(room.id)}
            collapsible={true}
            className={styles.collapse}
          >
            <div className={cx([styles.collapseDiv])}></div>
            <VerticalGroup spacing="lg" className={cx([styles.collapse])}>
              <Form<Room> onSubmit={(r) => doSubmitRoom(r, 'monitorSaving')} maxWidth={FORM_MAX_WIDTH}>
                {({ register, errors, control, watch, setValue }) => (
                  <SmallFieldSet label="Vérification de l’appareil de mesure">
                    <input {...register('id')} type="hidden" value={room.id} />
                    <TooltipField label="N° de série de l’appareil" error={errors.noSerie}>
                      <CreatableSelect
                        autoFocus
                        control={control}
                        register={register}
                        name={'noSerie'}
                        rules={REQUIRED}
                        additionalOnChange={(v) => {
                          const toCopy = report.allRooms
                            .filter((r) => r.dateEtalonnage)
                            .sort(
                              (a, b) =>
                                new Date(b.dateEtalonnage ?? '').getTime() - new Date(a.dateEtalonnage ?? '').getTime()
                            )
                            .find((r) => r.noSerie === v);
                          if (toCopy) {
                            setValue('responsableCapteur', toCopy.responsableCapteur);
                            setValue('emailCapteur', toCopy.emailCapteur);
                            setValue('dateCapteur', toCopy.dateCapteur);
                            setValue('modele', toCopy.modele);
                            setValue('modeEtalonnage', toCopy.modeEtalonnage);
                            setValue('dateEtalonnage', toCopy.dateEtalonnage);
                            setValue('conformiteSeuils', toCopy.conformiteSeuils);
                            setValue('modeValeur', toCopy.modeValeur);
                            setValue('modeIndicateur', toCopy.modeIndicateur);
                            setValue('modeSonore', toCopy.modeSonore);
                            setValue('modeEnregistrement', toCopy.modeEnregistrement);
                            setValue('frequence', toCopy.frequence);
                            setValue('commentCapteur', toCopy.commentCapteur);
                          }
                        }}
                        placeholder="Choisir ou ajouter un appareil"
                        defaultValue={room.noSerie}
                        defaultOptions={makeOptions(
                          [...new Set(report.allRooms.map((r) => r.noSerie))].filter((n) => n).map((n) => [n as string])
                        )}
                      />
                    </TooltipField>
                    <TooltipField
                      label="Réalisé par"
                      tooltip={<>n’est pas inclus dans le rapport mais seulement utilisé pour organiser l’évaluation</>}
                      error={errors.responsableCapteur}
                    >
                      <CreatableSelect
                        name={'responsableCapteur'}
                        autoComplete="name"
                        control={control}
                        register={register}
                        placeholder={OPTIONAL_PERSON_PLACEHOLDER}
                        defaultValue={room.responsableCapteur}
                        defaultOptions={makeNameOptions(report.names)}
                      />
                    </TooltipField>
                    <TooltipField
                      label="Date de la vérification"
                      tooltip={
                        <>n’est pas inclus dans le rapport mais seulement utilisé pour organiser la vérification</>
                      }
                      error={errors.dateCapteur}
                    >
                      <Input
                        {...register('dateCapteur')}
                        placeholder={OPTIONAL}
                        defaultValue={room.dateCapteur}
                        width={DATETIME_WIDTH}
                        type="datetime-local"
                        lang="fr-FR"
                      />
                    </TooltipField>

                    <TooltipField
                      label="Modèle d’appareil CO₂ utilisé"
                      tooltip={
                        <>
                          <p>
                            Il est important de bien respecter les spécifications indiquées ci-après au moment de
                            l’achat ou de la location des appareils.
                          </p>
                          <p>
                            La mesure à lecture directe de la concentration en CO₂ dans l’air est réalisée à l’aide d’un
                            appareil fonctionnant sur le principe de la spectrométrie d’absorption infrarouge non
                            dispersif ou d’une technologie démontrant des performances équivalentes, répondant aux
                            caractéristiques suivantes&nbsp;:
                            <ul className={styles.list}>
                              <li>Domaine de mesure minimum : 0 à 5000 parties par million (ppm)&nbsp;;</li>
                              <li>Incertitude de mesure maximale : ± (50 ppm + 5 % de la valeur lue)&nbsp;;</li>
                              <li>
                                Affichage de la mesure : en parties par million (ppm) ou par l’utilisation d’indicateurs
                                corrélés à des valeurs de mesure. L’ensemble des valeurs de mesure est affiché y compris
                                pour les mesures sous 400 ppm pour permettre d’identifier un éventuel problème
                                d’étalonnage&nbsp;;
                              </li>
                              <li>Fréquence d’affichage inférieure ou égale à 10 minutes&nbsp;;</li>
                              <li>
                                La fonction d’enregistrement de données peut s’avérer nécessaire dans certains cas mais
                                elle n’est pas obligatoirement requise. Par exemple, durant une période de sommeil dans
                                les dortoirs, une mesure à lecture directe n’est pas possible et l’enregistrement peut
                                permettre de vérifier s’il est nécessaire de modifier les pratiques d’aération durant
                                cette période.
                              </li>
                            </ul>
                          </p>
                          <p>({GUIDE_CSTB})</p>
                        </>
                      }
                      error={errors.modele}
                    >
                      <CreatableSelect
                        name={'modele'}
                        control={control}
                        register={register}
                        rules={REQUIRED}
                        placeholder="Choisir ou ajouter un modèle"
                        defaultValue={room.modele}
                        defaultOptions={makeOptions([
                          ['Meo mini', 'valeurs du CO₂ intégrées automatiquement au rapport'],
                          ['Meo maxi', 'valeurs du CO₂ intégrées automatiquement au rapport'],
                          ['Airthings Space CO₂ mini'],
                          ['Airthings Space CO₂'],
                          ['Airthings Space Plus'],
                          ['Class’Air'],
                          ['Class’Air datalogger'],
                          ['Kaiterra Sensedge'],
                          ['Nexelec Aero'],
                          ['Nexelec Sense'],
                          ['Orium Quaelis'],
                        ])}
                      />
                    </TooltipField>
                    <TooltipField
                      label="Mode d’étalonnage"
                      error={errors.modeEtalonnage}
                      tooltip={
                        <>
                          <p>
                            L’étalonnage consiste à vérifier que le résultat de la mesure fourni par l’appareil est
                            correct en le comparant avec une valeur de référence. Cette vérification de l’appareil doit
                            être effectuée avant chaque mise en œuvre des mesures à lecture directe&nbsp;:
                            <ul className={styles.list}>
                              <li>
                                Présence d’un certificat d’étalonnage datant de moins de 1 an (sauf mention contraire du
                                fabricant) ou
                              </li>
                              <li>
                                Réalisation systématique d’un étalonnage manuel selon les instructions du fournisseur de
                                l’appareil ou en exposant l’appareil à l’extérieur (à l’écart de grands axes routiers,
                                des rejets de cheminée et autres sources de combustion) pendant 15 – 30 minutes et en
                                vérifiant que la mesure affichée est comprise entre 400 et 450 ppm. Si un écart est
                                observé, il est nécessaire de procéder à un nouvel étalonnage ou écarter l’appareil.
                              </li>
                            </ul>
                          </p>
                          <p>({GUIDE_CSTB})</p>
                        </>
                      }
                    >
                      <InputControl
                        name={'modeEtalonnage'}
                        defaultValue={room.modeEtalonnage}
                        control={control}
                        rules={REQUIRED}
                        render={({ field: { onChange, ref, ...field } }) => (
                          <RadioButtonGroup
                            {...field}
                            onChange={onChange}
                            options={[
                              {
                                label: 'Fabricant',
                                value: 'Fabricant',
                                description:
                                  'Présence d’un certificat d’étalonnage de l’usine ou du fournisseur datant de moins de 1 an (sauf mention contraire du fabricant).',
                              },
                              {
                                label: MANUEL,
                                value: MANUEL,
                                description:
                                  'Réalisation systématique d’un étalonnage manuel selon les instructions du fournisseur de l’appareil ou en exposant l’appareil à l’extérieur.',
                              },
                            ]}
                          />
                        )}
                      />
                    </TooltipField>
                    <TooltipField
                      label="Dernier étalonnage"
                      error={errors.dateEtalonnage}
                      description={
                        monitorVerificationMoreThanOneYear(
                          watch('dateEtalonnage', room.dateEtalonnage),
                          watch('dateHeureDebutMesure', room.dateHeureDebutMesure)
                        ) && (
                          <p className={cx([styles.warningDescription])}>
                            Le certificat d’étalonnage doit dater de moins de 1 an (sauf mention contraire du
                            fabricant).
                          </p>
                        )
                      }
                    >
                      <Input
                        {...register('dateEtalonnage', {
                          ...REQUIRED,
                          validate: (v) => {
                            if (
                              monitorVerificationMoreThanOneYear(
                                v,
                                watch('dateHeureDebutMesure', room.dateHeureDebutMesure)
                              )
                            ) {
                              return 'L’étalonnage manuel doit avoir moins d’un an.';
                            }
                            return true;
                          },
                        })}
                        defaultValue={room.dateEtalonnage}
                        width={DATE_WIDTH}
                        type="date"
                        lang="fr-FR"
                      />
                    </TooltipField>
                    <TooltipField
                      label="Conformité des seuils"
                      tooltip={
                        <>
                          En cas d’affichage de la mesure par indicateurs colorés ou sonores, il est nécessaire de
                          vérifier que les seuils de mesure de l’appareil correspondent bien aux valeurs de référence de
                          800 et 1500 ppm, et si besoin réajuster ces seuils.
                        </>
                      }
                      error={errors.conformiteSeuils}
                    >
                      <InputControl
                        name={'conformiteSeuils'}
                        defaultValue={room.conformiteSeuils}
                        control={control}
                        rules={{
                          validate: (v: any) => {
                            if (typeof v === 'undefined') {
                              return 'Champ obligatoire.';
                            }
                            if (!v) {
                              return 'Les seuils doivent être conformes.';
                            }
                            return true;
                          },
                        }}
                        render={({ field: { onChange, ref, ...field } }) => (
                          <RadioButtonGroup
                            {...field}
                            onChange={onChange}
                            options={[
                              { label: 'Conforme', value: true },
                              { label: 'Non conforme', value: false },
                            ]}
                          />
                        )}
                      />
                    </TooltipField>
                    <TooltipField
                      label="Mode de contrôle de la mesure"
                      tooltip={
                        <>
                          <p>
                            Affichage de la mesure&nbsp;: en parties par million (ppm) ou par l’utilisation
                            d’indicateurs corrélés à des valeurs de mesure. L’ensemble des valeurs de mesure est affiché
                            y compris pour les mesures sous 400 ppm pour permettre d’identifier un éventuel problème
                            d’étalonnage.
                          </p>
                          <p>
                            Dans la mesure du possible, il faut éviter les appareils qui émettent une alerte sonore pour
                            signaler un dépassement. Si votre appareil est équipé, désactivez l’alerte sonore avant
                            chaque mesure afin de ne pas gêner les utilisateurs des locaux.
                          </p>
                          <p>
                            La fonction d’enregistrement de données peut s’avérer nécessaire dans certains cas mais elle
                            n’est pas obligatoirement requise. Par exemple, durant une période de sommeil dans les
                            dortoirs, une mesure à lecture directe n’est pas possible et l’enregistrement peut permettre
                            de vérifier s’il est nécessaire de modifier les pratiques d’aération durant cette période.
                          </p>
                          <p>({GUIDE_CSTB})</p>
                        </>
                      }
                      error={errors.modeValeur}
                    >
                      <VerticalGroup>
                        <TooltipCheckbox
                          control={control}
                          name="modeValeur"
                          options={{
                            validate: (v) => {
                              if (
                                !(
                                  v ||
                                  watch('modeIndicateur', room.modeIndicateur) ||
                                  watch('modeSonore', room.modeSonore) ||
                                  watch('modeEnregistrement', room.modeEnregistrement)
                                )
                              ) {
                                return 'Au moins un des modes doit être coché.';
                              }
                              return true;
                            },
                          }}
                          defaultChecked={room.modeValeur}
                          label="Visuel avec valeur en ppm"
                        />
                        <TooltipCheckbox
                          control={control}
                          name="modeIndicateur"
                          defaultChecked={room.modeIndicateur}
                          label="Visuel avec indicateur (couleur, smiley,...)"
                        />
                        <TooltipCheckbox
                          control={control}
                          name="modeSonore"
                          defaultChecked={room.modeSonore}
                          label="Sonore"
                        />
                        <TooltipCheckbox
                          control={control}
                          name="modeEnregistrement"
                          defaultChecked={room.modeEnregistrement}
                          label="Enregistrement"
                        />
                      </VerticalGroup>
                    </TooltipField>

                    <TooltipField
                      label={
                        'Fréquence ' +
                        modeControleDescription(
                          watch('modeValeur', room.modeValeur),
                          watch('modeIndicateur', room.modeIndicateur),
                          watch('modeEnregistrement', room.modeEnregistrement)
                        )
                      }
                      tooltip={<>Fréquence d’affichage inférieure ou égale à 10 minutes ({GUIDE_CSTB}).</>}
                      error={errors.frequence}
                    >
                      <CreatableSelect
                        name="frequence"
                        control={control}
                        register={register}
                        rules={REQUIRED}
                        placeholder="Choisir ou ajouter une valeur"
                        defaultValue={room.frequence}
                        defaultOptions={makeOptions([['≤ 1 min'], ['≤ 10 min']])}
                      />
                    </TooltipField>
                    <TooltipField label="Commentaires" error={errors.commentCapteur}>
                      <AutosizeTextArea
                        field="commentCapteur"
                        control={control}
                        placeholder={OPTIONAL}
                        defaultValue={room.commentCapteur}
                      />
                    </TooltipField>
                    <HorizontalGroup align="flex-start" justify="flex-end">
                      <SaveButton
                        label="Sauvegarder"
                        state={editState.saving[monitorSavingId(room.id)]}
                        setState={(s) => model.setRoomSaveState(room.id, 'monitorSaving', s)}
                      />
                    </HorizontalGroup>
                  </SmallFieldSet>
                )}
              </Form>
              <VerticalGroup key={room.id} width="100%">
                <Form<Room> onSubmit={(r) => doSubmitRoom(r, 'co2Saving')} maxWidth={FORM_MAX_WIDTH}>
                  {({ register, errors, control, watch, setValue, formState: { isDirty } }) => (
                    <SmallFieldSet label="Mesure du CO₂">
                      <input {...register('id')} type="hidden" value={room.id} />
                      <TooltipField
                        label="Réalisé par"
                        tooltip={
                          <>n’est pas inclus dans le rapport mais seulement utilisé pour organiser l’évaluation</>
                        }
                        error={errors.responsableMesure}
                      >
                        <CreatableSelect
                          control={control}
                          register={register}
                          name="responsableMesure"
                          autoComplete="name"
                          placeholder={OPTIONAL_PERSON_PLACEHOLDER}
                          defaultValue={room.responsableMesure}
                          defaultOptions={makeNameOptions(report.names)}
                        />
                      </TooltipField>
                      <CO2MeasureDates
                        rules={REQUIRED}
                        report={report}
                        room={room}
                        register={register}
                        watch={watch}
                        errors={errors}
                        setValue={setValue}
                      />
                      <TooltipField label="Effectif théorique maximal de la salle" error={errors.effectifMax}>
                        <NumberInput
                          field="effectifMax"
                          defaultValue={room.effectifMax}
                          options={{ ...REQUIRED, ...STRICTLY_POSITIVE }}
                          register={register}
                        />
                      </TooltipField>
                      <TooltipField
                        label="Nombre moyen d’occupants mineurs pendant la mesure"
                        error={errors.occupants}
                        tooltip={
                          <p>
                            La mesure à lecture directe est réalisée en période de chauffage, si elle existe, et dans
                            les conditions normales d’exploitation de la pièce (présence des usagers, activités et
                            pratiques d’aération et ventilation habituelles). Elle est effectuée sur une période au
                            cours de laquelle l’effectif présent dans la pièce est compris entre 0,5 fois et 1,5 fois
                            l’effectif théorique de la pièce étudiée ({GUIDE_CSTB}).
                          </p>
                        }
                      >
                        <NumberInput
                          field="occupants"
                          defaultValue={room.occupants}
                          options={{
                            ...REQUIRED,
                            validate: (v) => {
                              if (v && v <= 0) {
                                return 'L’effectif doit être un nombre positif.';
                              }
                              if (
                                v &&
                                watch('effectifMax', room.effectifMax) &&
                                (v < 0.5 * watch().effectifMax || v > 1.5 * watch('effectifMax', room.effectifMax))
                              ) {
                                return 'L’effectif présent dans la pièce doit être compris entre 0,5 fois et 1,5 fois l’effectif théorique.';
                              }
                              return true;
                            },
                          }}
                          register={register}
                        />
                      </TooltipField>
                      <TooltipField label="Présence d’au moins un dépassement > 800 ppm" error={errors.ppm800}>
                        <InputControl
                          name={'ppm800'}
                          defaultValue={room.ppm800}
                          control={control}
                          rules={{
                            validate: (v: any) => {
                              if (typeof v === 'undefined') {
                                return 'Champ obligatoire';
                              }
                              if (!v && watch('ppm1500', room.ppm1500)) {
                                return 'Seule la valeur Oui est possible en raison de la présence d’au moins un dépassement > 1500 ppm ci-dessous.';
                              }
                              return true;
                            },
                          }}
                          render={({ field: { onChange, ref, ...field } }) => (
                            <RadioButtonGroup
                              {...field}
                              onChange={onChange}
                              options={[
                                { label: 'Oui', value: true },
                                { label: 'Non', value: false },
                              ]}
                            />
                          )}
                        />
                      </TooltipField>
                      <TooltipField label="Présence d’au moins un dépassement > 1500 ppm" error={errors.ppm1500}>
                        <InputControl
                          name={'ppm1500'}
                          defaultValue={room.ppm1500}
                          control={control}
                          rules={REQUIRED_BOOLEAN}
                          render={({ field: { onChange, ref, ...field } }) => (
                            <RadioButtonGroup
                              {...field}
                              onChange={onChange}
                              options={[
                                { label: 'Oui', value: true },
                                { label: 'Non', value: false },
                              ]}
                            />
                          )}
                        />
                      </TooltipField>
                      <TooltipField label="Valeur max observée en ppm" error={errors.maxCo2}>
                        <NumberInput
                          placeholder={OPTIONAL}
                          field="maxCo2"
                          defaultValue={room.maxCo2}
                          register={register}
                          options={{
                            validate: (v) => {
                              if (v && v < 800 && watch('ppm800', room.ppm800)) {
                                return 'La valeur doit être au moins 800 conformément la présence d’au moins 1 dépassement > 800 ppm ci-dessus.';
                              }
                              if (v && v < 1500 && watch('ppm1500', room.ppm1500)) {
                                return 'La valeur doit être au moins 1500 conformément la présence d’au moins 1 dépassement > 1500 ppm ci-dessus.';
                              }
                              if (v && (v < 400 || v > 10000)) {
                                return 'La valeur du CO₂ en ppm doit être compris entre 400 (la concentration en CO₂ de l’air extérieur) et 10000 (une valeur entrainant des troubles respiratoires immédiats).';
                              }
                              return true;
                            },
                          }}
                        />
                      </TooltipField>
                      <TooltipField label="Nombre de valeurs dépassant 800 ppm" error={errors.ppm800Num}>
                        <NumberInput
                          placeholder={OPTIONAL}
                          field="ppm800Num"
                          defaultValue={room.ppm800Num}
                          register={register}
                          options={{
                            validate: (v: any) => {
                              if (typeof v !== 'undefined' && v < 0) {
                                return 'Le nombre ne peut pas être négatif.';
                              }
                              if (typeof v !== 'undefined' && watch('ppm800', room.ppm800) && v < 1) {
                                return 'Le nombre doit être au minimum 1 conformément la présence d’au moins 1 dépassement > 800 ppm ci-dessus.';
                              }
                              return true;
                            },
                          }}
                        />
                      </TooltipField>
                      <TooltipField label="Nombre de valeurs dépassant 1500 ppm" error={errors.ppm1500Num}>
                        <NumberInput
                          placeholder={OPTIONAL}
                          field="ppm1500Num"
                          defaultValue={room.ppm1500Num}
                          register={register}
                          options={{
                            validate: (v: any) => {
                              if (typeof v !== 'undefined' && v < 0) {
                                return 'Le nombre ne peut pas être négatif.';
                              }
                              if (typeof v !== 'undefined' && watch('ppm1500', room.ppm1500) && v < 1) {
                                return 'Le nombre doit être au minimum 1 conformément la présence d’au moins 1 dépassement > 1500 ppm ci-dessus.';
                              }
                              return true;
                            },
                          }}
                        />
                      </TooltipField>
                      <TooltipField
                        label="Actions immédiates mises en place en cas de dépassement"
                        tooltip=""
                        error={errors.actionAucune}
                      >
                        <VerticalGroup>
                          <TooltipCheckbox
                            control={control}
                            name="actionAucune"
                            options={{
                              validate: (v) => {
                                const someRealAction = [
                                  watch('actionUneOuverture', room.actionUneOuverture),
                                  watch('actionAerationEnGrand', room.actionAerationEnGrand),
                                  watch('actionAerationTransversale', room.actionAerationTransversale),
                                  watch('actionPorte', room.actionPorte),
                                ].some((a) => a);
                                if (v && someRealAction) {
                                  return 'L’option « Aucune » ne peut pas être cochée avec une autre option.';
                                }
                                if (
                                  (watch('ppm800', room.ppm800) || watch('ppm1500', room.ppm1500)) &&
                                  !v &&
                                  !someRealAction
                                ) {
                                  return 'Au moins une option doit être cochée.';
                                }
                                return true;
                              },
                            }}
                            defaultChecked={room.actionAucune}
                            label="Aucune"
                          />
                          <TooltipCheckbox
                            control={control}
                            name="actionUneOuverture"
                            defaultChecked={room.actionUneOuverture}
                            label="Ouverture d’une fenêtre"
                            description="donnant sur l’extérieur"
                          />
                          <TooltipCheckbox
                            control={control}
                            name="actionAerationEnGrand"
                            defaultChecked={room.actionAerationEnGrand}
                            label="Aération en grand"
                            description="plusieurs fenêtres ouvertes sur une même façade"
                          />
                          <TooltipCheckbox
                            control={control}
                            name="actionAerationTransversale"
                            defaultChecked={room.actionAerationTransversale}
                            label="Aération transversale"
                            description="courant d’air avec plusieurs fenêtres ouvertes sur des façades différente"
                          />
                          <TooltipCheckbox
                            control={control}
                            name="actionPorte"
                            defaultChecked={room.actionPorte}
                            label="Ouverture de la porte"
                            description="en plus des fenêtres"
                          />
                        </VerticalGroup>
                      </TooltipField>
                      <TooltipField
                        label="Résultat"
                        tooltip="Résultat à la fin de la période en cas de dépassement, sans action ou suite à des actions immédiates mises en place"
                        error={errors.resultat}
                      >
                        <InputControl
                          control={control}
                          name={'resultat'}
                          defaultValue={room.resultat}
                          rules={{
                            validate: (v) => {
                              if (!v && (watch('ppm800', room.ppm800) || watch('ppm1500', room.ppm1500))) {
                                return 'Au moins une option doit être cochée.';
                              }
                              return true;
                            },
                          }}
                          render={({ field: { onChange, ref, ...field } }) => (
                            <Select
                              {...field}
                              onChange={(v) => onChange(v?.value)}
                              placeholder="Choisir"
                              isClearable={true}
                              options={makeOptions([
                                ['Retour à la normale', '< 800 ppm'],
                                [LESS_THAN_1500_PPM_RESULT, '< 1500 ppm'],
                                [MORE_THAN_1500_PPM_RESULT, '> 1500 ppm'],
                              ])}
                            />
                          )}
                        />
                      </TooltipField>
                      <TooltipField
                        label="Observations de l’occupant"
                        tooltip={<>Au besoin, il est possible d’indiquer vos actions et/ou observations.</>}
                        error={errors.commentMesure}
                      >
                        <AutosizeTextArea
                          field={'commentMesure'}
                          control={control}
                          placeholder={OPTIONAL}
                          defaultValue={room.commentMesure}
                        />
                      </TooltipField>
                      <HorizontalGroup align="flex-start" justify="flex-end">
                        <SaveButton
                          label="Sauvegarder"
                          state={editState.saving[co2SavingId(room.id)]}
                          setState={(s) => model.setRoomSaveState(room.id, 'co2Saving', s)}
                        />
                      </HorizontalGroup>
                    </SmallFieldSet>
                  )}
                </Form>
              </VerticalGroup>
            </VerticalGroup>
          </Collapse>
        </VerticalGroup>
      ))}
    </VerticalGroup>
  );
}
