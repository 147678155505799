import { cx } from '@emotion/css';
import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import { Collapse, VerticalGroup, useStyles2 } from '@grafana/ui';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AutodiagAnchorStart, AutodiagTab } from 'utils/utils.routing';
import { getStyles } from 'utils/utils.styles';
import { EmptyRooms, Progress, SaveState } from '../../utils/utils.form';
import { Report, Room, TabObjectState } from 'utils/utils.model';
import { Autodiag } from './Autodiag';
import { updateThing } from './queries';

export class AutodiagActivitesSceneObject extends SceneObjectBase<TabObjectState> {
  static Component = AutodiagActivitesSceneObjectRenderer;

  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({
      report: report,
      setReports: setReports,
      ...state,
    });
  }
  setExpandRoom = (roomId: string | undefined) => (isOpen: boolean) => {
    this.state.report.editState.autodiag.activites.expandedRoom = isOpen ? roomId : undefined;
    this.setState({ report: this.state.report });
  };
  setRoomSaveState = (roomId: string, saveState: SaveState) => {
    this.state.report.editState.autodiag.activites.saving[roomId] = saveState;
    this.setState({ report: this.state.report });
  };
}

function AutodiagActivitesSceneObjectRenderer({ model }: SceneComponentProps<AutodiagActivitesSceneObject>) {
  const { report, setReports } = model.useState();
  const location = useLocation();
  const styles = useStyles2(getStyles);
  const rooms = report.autodiagRooms;
  const editState = report.editState.autodiag.activites;

  useEffect(() => {
    // TODO scroll to section
    let id = undefined;
    if (location.hash.startsWith(AutodiagAnchorStart.activites)) {
      id = location.hash.substring(AutodiagAnchorStart.activites.length);
    }
    if (id) {
      model.setExpandRoom(id)(true);
    }
  }, [location.hash, model, rooms]);

  const doSubmitRoom = (prefix: string) => (room: Room) => {
    model.setRoomSaveState(prefix + room.id, 'saving');
    updateThing(
      'autodiag',
      report,
      { room: room },
      () => {
        model.setExpandRoom(undefined)(false);
        model.setRoomSaveState(prefix + room.id, undefined);
      },
      (err) => model.setRoomSaveState(prefix + room.id, err),
      setReports
    );
  };

  return (
    <VerticalGroup>
      {rooms.length === 0 && <EmptyRooms type="autodiag" report={report} />}
      {rooms.map((room, idx) => (
        <VerticalGroup key={room.id} width="100%">
          <Collapse
            key={room.id}
            label={
              <>
                <span className={cx([styles.collapseLabel])}>{room.nom} </span>
                <Progress value={report.autodiagTabProgress.room[room.id]} />
              </>
            }
            isOpen={editState.expandedRoom === room.id}
            onToggle={model.setExpandRoom(room.id)}
            collapsible={true}
            className={styles.collapse}
          >
            <div className={cx([styles.collapseDiv])}></div>
            <VerticalGroup spacing="lg" className={cx([styles.collapse])}>
              <Autodiag
                doSubmit={doSubmitRoom}
                tab={AutodiagTab.activites}
                report={report}
                thing={room}
                setSaveState={(s: SaveState) => model.setRoomSaveState(room.id, s)}
              />
            </VerticalGroup>
          </Collapse>
        </VerticalGroup>
      ))}
    </VerticalGroup>
  );
}
