import { css, cx } from '@emotion/css';
import { DataFrame, GrafanaTheme2, SelectableValue, applyFieldOverrides, colorManipulator } from '@grafana/data';
import { Stack } from '@grafana/experimental';
import {
  Button,
  ButtonVariant,
  CellProps,
  Checkbox,
  Column,
  CustomScrollbar,
  Field,
  HorizontalGroup,
  Icon,
  IconButton,
  Input,
  InputControl,
  InteractiveTable,
  Label,
  Legend,
  LinkButton,
  PanelContainer,
  PopoverContent,
  RadioButtonGroup,
  ReactUtils,
  Select,
  Table,
  TextArea,
  Tooltip,
  VerticalGroup,
  usePanelContext,
  useStyles2,
  useTheme2,
} from '@grafana/ui';
import { atcb_action } from 'add-to-calendar-button';
import React, { FormEvent, HTMLProps, MouseEvent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { localDateTime } from './utils.format';
import { Org, Report, ReportType, Room } from './utils.model';
import { AnnuelTab, AutodiagTab, reportUrl } from './utils.routing';
import {
  DATETIME_WIDTH,
  FORM_MAX_WIDTH,
  GREEN,
  NUM_WIDTH,
  RED,
  TEXT_AREA_TOP_BOTTOM_PADDING,
  TXT_MAX_LENGTH,
  YELLOW,
  getStyles,
} from './utils.styles';
import { getTemplateSrv } from '@grafana/runtime';

export const NBSP = '\xa0';

export const UUID_NAMESPACE = 'a859f6fe-9a60-40f7-a633-deea721c1fc2';

export const OPTIONAL = 'Facultatif';

export const PERSON_PLACEHOLDER = 'Choisir ou saisir un autre nom';

export const OPTIONAL_PERSON_PLACEHOLDER = 'Facultatif : choisir ou saisir un autre nom';

export const REQUIRED = { required: 'Champ obligatoire.' };

export const REQUIRED_BOOLEAN = {
  validate: (v: any) => {
    if (typeof v === 'undefined') {
      return 'Champ obligatoire.';
    }
    return true;
  },
};

export const STRICTLY_POSITIVE = {
  validate: (v: any) => {
    if (typeof v !== 'undefined' && v <= 0) {
      return 'Le nombre doit être strictement positif.';
    }
    return true;
  },
};

export const POSITIVE = {
  validate: (v: any) => {
    if (typeof v !== 'undefined' && v < 0) {
      return 'Le nombre ne peut pas être négatif.';
    }
    return true;
  },
};

export const EMAIL_PATTERN = {
  pattern: {
    // Using a modified version of https://github.com/add2cal/add-to-calendar-button/blob/main/src/atcb-util.js#L417
    // with part of https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    // and forbidding | for NAME|EMAIL add-to-calendar format
    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{}~-]{0,70}@[a-zA-Z0-9]{1,30}\.[a-zA-Z]{2,9}$/,
    message: `Le format de l’adresse courriel (e-mail) est invalide. Exemple de format${NBSP}: dupont@example.com.`,
  },
};

// Would require some replacements (at least space to -, and 00 to +) in phone numbers to be put in a tel URL scheme
export const TEL_PATTERN = {
  pattern: {
    value: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
    message: `Le format du numéro de téléphone français est invalide. Exemple de format${NBSP}: 01 23 45 67 89 ou +33 1 23 45 67 89.`,
  },
};

export const TARGET_BLANK = { target: '_blank', rel: 'noreferrer' };

export const GUIDE_CEREMA = (
  <a href="https://www.cerema.fr/system/files/documents/2023/03/guide_qai.pdf" {...TARGET_BLANK}>
    Guide Cerema
  </a>
);

export const CONSEILS_CEREMA_ENTRETIEN_AERATION_VENTILATION = (
  <>
    «{NBSP}Conseils complémentaires d’entretien des systèmes d’aération et de ventilation{NBSP}
    {NBSP}» à la page 25 du {GUIDE_CEREMA}
  </>
);

export const ficheInformativeCerema = (title: string, page: number) => (
  <>
    «{NBSP}Fiche informative{NBSP}: {title}
    {NBSP}» à la page {page} du {GUIDE_CEREMA}
  </>
);

export const FICHE_CEREMA_AFFICHAGE_ = ficheInformativeCerema('Rappel des affichages obligatoires', 133);
export const FICHE_CEREMA_DEBIT = ficheInformativeCerema('Débits de ventilation dans les bâtiments tertiaires', 134);
export const FICHE_CEREMA_POSITIONNEMENT_ENTREE_AIR = ficheInformativeCerema(
  'Positionnement des entrées d’air dans le bâtiment et positionnement du bâtiment par rapport aux sources de pollution',
  138
);
export const FICHE_CEREMA_BALAYAGE = ficheInformativeCerema('Assurer un bon balayage de l’air', 139);
export const FICHE_CEREMA_FUMEURS = ficheInformativeCerema(
  'Attention aux espaces fumeurs à proximité des bâtiments',
  142
);
export const FICHE_CEREMA_EQUIPEMENTS_ACTIVITES_EMISSIVES = ficheInformativeCerema(
  'Équipements et activités émissives de polluants',
  143
);
export const FICHE_CEREMA_MATERIAUX_CHANTIER = ficheInformativeCerema('Gestion des matériaux sur chantier', 147);
export const FICHE_CEREMA_SENSIBILISATION = ficheInformativeCerema('Sensibilisation des usagers / occupants', 149);
export const FICHE_CEREMA_SOURCES = ficheInformativeCerema(
  'Sources documentaires complémentaires & références réglementaires',
  152
);

// TODO add pages
export const GUIDE_CSTB = (
  <a
    href="https://www.oqai.fr/fr/actualites/guide-d-application-pour-la-surveillance-du-confinement-de-l-air"
    {...TARGET_BLANK}
  >
    Guide CSTB
  </a>
);

export const MALETTE_ECOLAIR = (
  <a
    href="https://www.batiment-ventilation.fr/fileadmin/medias/Bonnes_Pratiques/OUTILS-FICHE_ECOLAIR_2018.pdf"
    {...TARGET_BLANK}
  >
    Malette Écol’air
  </a>
);

export const GUIDE_ECOLAIR_VENTILATION = (
  <>
    «{NBSP}Guide de diagnostic simplifié des installations de ventilation dans les écoles {NBSP}» de la{' '}
    {MALETTE_ECOLAIR}
  </>
);

export const GUIDE_ECOLAIR_ENTRETIEN = (
  <>
    guide «{NBSP}Le choix des produits d’entretien pour une meilleure qualité de l’air intérieur {NBSP}» de la{' '}
    {MALETTE_ECOLAIR}
  </>
);

export const AFFICHE_ECOLAIR_QAI = (
  <>
    affiche «{NBSP}Tous concernés par une meilleure qualité de l’air intérieur{NBSP}!!!{NBSP}» de la {MALETTE_ECOLAIR}
  </>
);

export const ficheEcolair = (title: string) => (
  <>
    fiche «{NBSP}
    {title}
    {NBSP}» de la {MALETTE_ECOLAIR}
  </>
);

export const FICHE_ECOLAIR_SANTE = ficheEcolair('Qualité de l’air intérieur et santé des enfants');
export const FICHE_ECOLAIR_AERATION = ficheEcolair(
  'Aération par ouverture des fenêtres dans les écoles et les crèches'
);
export const FICHE_ECOLAIR_DOUBLE_FLUX = ficheEcolair(
  'Systèmes de ventilation double flux monobloc destinés aux salles de classe'
);
export const FICHE_ECOLAIR_EXTRACTION_LOCAL_CHANGE = ficheEcolair(
  'Extraction d’air localisée dans les poubelles des locaux de changes'
);
export const FICHE_ECOLAIR_MOISISSURES = ficheEcolair('Moisissures : impact sur la santé, traitement et prévention');
export const FICHE_ECOLAIR_MATERIAUX = ficheEcolair('Choisir et mettre en œuvre des matériaux de construction');
export const FICHE_ECOLAIR_MOBILIER = ficheEcolair('Choisir et installer le mobilier');
export const FICHE_ECOLAIR_FOURNITURES = ficheEcolair('Choisir et acheter des fournitures scolaires');
export const FICHE_ECOLAIR_AMBIANCE = ficheEcolair(
  'Désodorisation, assainissement, désinfection des ambiances : ATTENTION !'
);
export const FICHE_ECOLAIR_NETTOYAGE_ECO = ficheEcolair(
  'Définir la qualité écologique d’une prestation de nettoyage dans les locaux'
);
export const FICHE_ECOLAIR_PRODUITS_ENTRETIEN = ficheEcolair('Choisir et acheter des produits d’entretien');

export const PROJET_PHYTAIR_OQAI = (
  <a href="https://www.oqai.fr/fr/pollutions/l-epuration-de-l-air-par-les-plantes" {...TARGET_BLANK}>
    projet PHYTAIR de l’OQAI
  </a>
);

export const PAGE_ALLERGENES_OQAI = (
  <a href="https://www.oqai.fr/fr/pollutions/les-allergenes" {...TARGET_BLANK}>
    page sur les allergènes sur le site de l’OQAI
  </a>
);

export const SIRET_TOOLTIP = 'Le SIRET doit avoir 14 chiffres avec des espaces optionels.';

export const evaluableTooltip = (styles: { list: string; nestedlist: string }) => (
  <>
    <p>
      Les pièces concernées des établissements ciblés par la réglementation actuelle sont :
      <ul className={styles.list}>
        <li>
          les salles d’enseignement des établissements d’enseignement ou de formation professionnelle du premier et du
          second degré, c’est-à-dire les salles de classe de la maternelle au lycée inclus, y compris les salles de
          sport / gymnases. Cela inclut&nbsp;:
          <ul className={styles.nestedlist}>
            <li>
              lorsqu’elles ne sont pas considérées comme locaux à pollution spécifique au sens du code du travail, les
              salles de physique / chimie, de biologie, de travaux pratiques et d’arts plastiques&nbsp;;
            </li>
            <li>les salles de musique, d’informatique ou de bibliothèque&nbsp;;</li>
          </ul>
        </li>
        <li>
          les salles d’activités ou de vie des établissements d’accueil collectif d’enfants de moins de six ans ou des
          accueils de loisirs (salles de jeux, salles de garderie, etc.)&nbsp;;
        </li>
        <li>les salles de restauration&nbsp;;</li>
        <li>les dortoirs des établissements&nbsp;;</li>
        <li>les bâtiments sportifs accolés aux établissements d’enseignement (gymnases).</li>
      </ul>
      À l’inverse, sont exclus&nbsp;:
      <ul className={styles.list}>
        <li>les pièces utilisées comme local technique&nbsp;;</li>
        <li>les cuisines&nbsp;;</li>
        <li>les sanitaires&nbsp;;</li>
        <li>les bureaux&nbsp;;</li>
        <li>les logements de fonction&nbsp;;</li>
        <li>les espaces servant aux circulations&nbsp;;</li>
        <li>
          les autres locaux à pollution spécifique&nbsp;: ces derniers sont définis comme des locaux où existent des
          émissions de produits gênants ou nocifs autres que ceux liés à la seule présence humaine (ateliers techniques
          par exemple). Le code du travail en donne une définition à l’
          <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000018532336">article R. 4222-3</a>.
        </li>
      </ul>
    </p>
    <p>({GUIDE_CEREMA})</p>
  </>
);

export const Address = ({
  org,
  adresse,
  codePostal,
  ville,
  errors,
  register,
  control,
}: {
  org: Org;
  adresse: keyof Org;
  codePostal: keyof Org;
  ville: keyof Org;
  errors: DeepMap<Org, FieldError>;
  register: UseFormRegister<Org>;
  control: Control<Org>;
}) => (
  <>
    <TooltipField label="Adresse" error={errors[adresse]}>
      <AutosizeTextArea
        field={adresse as string}
        control={control}
        options={REQUIRED}
        autoComplete="work street-address"
        defaultValue={org[adresse]}
      />
    </TooltipField>
    <HorizontalGroup spacing="sm" align="flex-start">
      <TooltipField label="Code postal" error={errors[codePostal]}>
        <Input
          {...register(codePostal as string, {
            ...REQUIRED,
            validate: (v: any) => {
              if (v && v.length !== 5) {
                return 'Le code postal doit avoir 5 chiffres.';
              }
              return true;
            },
          })}
          autoComplete="work postal-code"
          defaultValue={org[codePostal]}
          width={12}
          type="number"
        />
      </TooltipField>
      <TooltipField label="Ville" error={errors[ville]}>
        <Input
          {...register(ville as string, REQUIRED)}
          autoComplete="work address-level2"
          defaultValue={org[ville]}
          maxLength={TXT_MAX_LENGTH}
        />
      </TooltipField>
    </HorizontalGroup>
  </>
);

export const SiretLink = ({
  nom,
  adresse,
  codePostal,
  ville,
}: {
  nom: string | undefined;
  adresse: string | undefined;
  codePostal: string | undefined;
  ville: string | undefined;
}) => {
  const disabled = !nom || !adresse || !codePostal || !ville;
  return disabled ? (
    <></>
  ) : (
    <LinkButton
      disabled={disabled}
      tooltip={'Recherche sur le site annuaire-entreprises.data.gouv.fr'}
      fill="text"
      target="_blank"
      rel="noreferrer"
      href={
        'https://annuaire-entreprises.data.gouv.fr/rechercher?terme=' +
        encodeURIComponent(`${nom}, ${adresse} ${codePostal} ${ville}`.replace('\n', ' '))
      }
    >
      Recherche de SIRET
    </LinkButton>
  );
};

export const isValidSiret = (siretString: string | undefined): boolean | string => {
  if (!siretString) {
    return 'Le SIRET doit avoir 14 chiffres avec des espaces optionels.';
  }
  const siret = siretString.replace(/\s/g, '');
  if (!/^[0-9]{14}$/.test(siret)) {
    return 'Le SIRET doit avoir 14 chiffres avec des espaces optionels.';
  }
  let sum = 0;
  let tmp: number;
  for (let cpt = 0; cpt < siret.length; cpt++) {
    const digit = parseInt(siret.charAt(cpt), 10);
    if (cpt % 2 === 0) {
      tmp = digit * 2;
      if (tmp > 9) {
        tmp -= 9;
      }
    } else {
      tmp = digit;
    }
    sum += tmp;
  }
  if (sum % 10 !== 0) {
    return 'Le dernier chiffre de contrôle du SIRET n’est pas valide.';
  }
  return true;
};

export const makeOptions = (values: string[][]) => values.map((v) => ({ label: v[0], value: v[0], description: v[1] }));

export interface SmallFieldSetProps extends Omit<HTMLProps<HTMLFieldSetElement>, 'label'> {
  children: ReactNode[] | ReactNode;
  label: ReactNode;
  tooltip?: string | React.ReactElement;
}

const getSmallFieldSetStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    border: 1px solid ${theme.colors.border.strong};
    padding: 0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};

    &:last-child {
      margin-bottom: 0;
    }
  `,
  legend: css`
    font-size: ${theme.typography.h4.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin: 0 0 ${theme.spacing(2)} 0;
    padding-left: ${theme.spacing(1)};
  `,
});

//background-color: ${theme.colors.background.secondary};

/** Field set with bold text in smaller font and smaller margins. */
export const SmallFieldSet = ({ label, children, className, tooltip, ...rest }: SmallFieldSetProps) => {
  const styles = useStyles2(getSmallFieldSetStyles);
  return (
    <fieldset className={cx(styles.wrapper, className)} {...rest}>
      {label && (
        <Legend className={cx(styles.legend)}>
          <Stack gap={0.5}>
            {label}
            <InfoTooltip tooltip={tooltip} />
          </Stack>
        </Legend>
      )}
      {children}
    </fieldset>
  );
};

export const InfoTooltip = ({ tooltip }: { tooltip?: string | React.ReactElement }) => {
  const styles = useStyles2(getTooltipFieldStyles);
  return tooltip ? (
    <Tooltip content={<div>{tooltip}</div>} interactive>
      <Icon className={styles.icon} name="info-circle" size="sm" />
    </Tooltip>
  ) : (
    <></>
  );
};
const getTooltipFieldStyles = (theme: GrafanaTheme2) => ({
  icon: css`
    margin-right: ${theme.spacing(0.5)};
  `,
});
export interface TooltipLabelProps {
  label: string;
  className?: string;
  childrenForId?: React.ReactElement;
  /** Children is mandatory to get description */
  description?: ReactNode;
  tooltip?: string | React.ReactElement;
}

export const TooltipLabel = ({ label, className, tooltip, description, childrenForId }: TooltipLabelProps) => {
  return childrenForId ? (
    <Label className={className} htmlFor={ReactUtils.getChildId(childrenForId)} description={description}>
      <Stack gap={0.5}>
        {label}
        <InfoTooltip tooltip={tooltip} />{' '}
      </Stack>
    </Label>
  ) : (
    <Stack gap={0.5}>
      <span className={className}>{label}</span>
      <InfoTooltip tooltip={tooltip} />
    </Stack>
  );
};

export interface TooltipFieldProps {
  children: React.ReactElement;
  label: string;
  error: FieldError | undefined;
  tooltip?: string | React.ReactElement;
  description?: React.ReactNode;
}

export const TooltipField = ({ label, description, error, tooltip, children }: TooltipFieldProps) => {
  const styles = useStyles2(getStyles);
  return (
    <Field
      label={
        <TooltipLabel
          className={styles.field}
          description={description}
          tooltip={tooltip}
          label={label}
          childrenForId={children}
        />
      }
      invalid={!!error}
      error={error?.message}
    >
      {children}
    </Field>
  );
};

export const TooltipCheckbox = ({
  name,
  label,
  description,
  tooltip,
  defaultChecked,
  control,
  options,
  dependentName,
  setValue,
  ...rest
}: {
  name: string;
  label: string;
  control: Control<any>;
  options?: RegisterOptions;
  description?: string;
  tooltip?: string | ReactElement;
  defaultChecked?: boolean;
  dependentName?: string;
  setValue?: UseFormSetValue<any>;
}) => {
  const styles = useStyles2(getStyles);
  return (
    <Stack gap={0.5}>
      <InputControl
        name={name}
        defaultValue={defaultChecked}
        control={control}
        rules={options}
        render={({ field: { onChange, ...field } }) => (
          <Checkbox
            {...field}
            className={styles.checkbox}
            label={label}
            description={description}
            onChange={(e) => {
              onChange(e);
              if (dependentName && setValue) {
                setValue(dependentName, e.currentTarget.checked);
              }
            }}
            {...rest}
          />
        )}
      />
      <InfoTooltip tooltip={tooltip} />
    </Stack>
  );
};

type CloseProps = {
  onClick: () => void;
};

const CloseButton = ({ onClick }: CloseProps) => {
  const styles = useStyles2(getCloseStyles);
  return <IconButton aria-label={'Close'} className={styles} name="times" onClick={onClick} />;
};

const getCloseStyles = (theme: GrafanaTheme2) =>
  css`
    position: absolute;
    right: ${theme.spacing(0.5)};
    top: ${theme.spacing(1)};
  `;

export function expanderCell<T>(format: (originalRow: T) => ReactNode) {
  return function ExpanderCell({ row }: CellProps<T, void>) {
    const styles = useStyles2(getStyles);
    const value = format(row.original);
    return (
      <div className={styles.fitParent}>
        <div {...row.getToggleRowExpandedProps()} className={styles.withNewlines} title="Modifier">
          {value ? value : '\u00a0'}
        </div>
      </div>
    );
  };
}

export function SizedTable(props: { width: number; data: DataFrame; sortBy: string; sortDesc?: boolean }) {
  const { width, data, sortBy, sortDesc } = props;
  // tries to estimate table height, with a min of 100 and a max of 600
  const height = Math.min(600, Math.max(data.length * 36, 100) + 40 + 46);
  const theme = useTheme2();
  const panelContext = usePanelContext();
  return (
    <Table
      height={height}
      width={width}
      data={
        applyFieldOverrides({
          data: [data],
          theme: theme,
          replaceVariables: getTemplateSrv().replace.bind(getTemplateSrv()),
          fieldConfig: {
            defaults: {},
            overrides: [],
          },
        })[0]
      }
      resizable={true}
      initialSortBy={[{ displayName: sortBy, desc: sortDesc }]}
      //onSortByChange={(sortBy) => onSortByChange(sortBy, props)}
      //onColumnResize={(displayName, resizedWidth) => onColumnResize(displayName, resizedWidth, props)}
      onCellFilterAdded={panelContext.onAddAdHocFilter}
    />
  );
}

interface TableProps<T extends BaseRow> {
  columns: Array<Column<T>>;
  data: T[];
  topButton?: string;
  topButtonTooltip?: PopoverContent;
  topButtonDisabled?: boolean;
  addButton: string;
  saveButton: string;
  secondaryTopElements?: ReactNode;
  renderExpandedRow: (row: T | undefined, buttonLabel: string, close: () => void) => ReactNode;
  showFormWhen?: number;
  tableClassName?: string;
}

export interface BaseRow extends FieldValues {
  /* Unique name */
  nom: string;
  id: string;
}

export function EditTable<T extends BaseRow>(props: TableProps<T>) {
  const {
    tableClassName,
    topButton,
    topButtonTooltip,
    topButtonDisabled,
    addButton,
    saveButton,
    secondaryTopElements,
    columns,
    data,
    renderExpandedRow,
    showFormWhen,
  } = props;
  const [isAdding, setIsAdding] = useState(false);
  const closeAdd = () => setIsAdding(false);
  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo<Array<Column<T>>>(() => columns, [columns]);
  const styles = useStyles2(getTableStyles);
  const emptyStateShowForm = showFormWhen !== undefined && tableData.length === showFormWhen;
  const showForm = isAdding || emptyStateShowForm;
  return (
    <CustomScrollbar>
      <VerticalGroup>
        <HorizontalGroup justify="flex-start" spacing="lg" wrap>
          {topButton && !showForm && (
            <Button
              tooltip={topButtonTooltip}
              disabled={topButtonDisabled}
              icon="plus"
              onClick={() => setIsAdding(true)}
            >
              {topButton}
            </Button>
          )}
          {secondaryTopElements}
        </HorizontalGroup>
        {showForm && (
          <PanelContainer className={styles.panelContainer}>
            {!emptyStateShowForm && (
              <CloseButton
                onClick={() => {
                  closeAdd();
                }}
              />
            )}

            {renderExpandedRow(undefined, addButton, closeAdd)}
          </PanelContainer>
        )}
        {tableData.length && (
          <InteractiveTable
            className={tableClassName}
            renderExpandedRow={(r) => renderExpandedRow(r, saveButton, closeAdd)}
            columns={tableColumns}
            data={tableData}
            getRowId={(r: T) => r.id}
          />
        )}
      </VerticalGroup>
    </CustomScrollbar>
  );
}

const getTableStyles = (theme: GrafanaTheme2) => ({
  panelContainer: css`
    min-width: ${FORM_MAX_WIDTH}px;
    width: 100%;
    position: relative;
    padding: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
});

// string for error string
export type SaveState = 'saving' | 'saved' | string | undefined;

const icon = (state: SaveState) => {
  switch (state) {
    case 'saving':
      return 'fa fa-spinner';
    case 'saved':
      return 'check';
    case undefined:
      return undefined;
    default:
      return 'exclamation-triangle';
  }
};

const whichVariant = (state: SaveState, defaultVariant: 'primary' | 'secondary'): ButtonVariant => {
  switch (state) {
    case 'saved':
      return 'success';
    case undefined:
    case 'saving':
      return defaultVariant;
    default:
      return 'destructive';
  }
};

export function SaveButton(props: {
  state: SaveState | undefined;
  setState: (state: SaveState | undefined) => void;
  label: string;
  defaultVariant?: 'primary' | 'secondary';
  type?: 'submit' | 'button';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  tooltip?: string | ReactElement;
}) {
  const { state, setState, label, defaultVariant, type, onClick, disabled, tooltip } = props;
  useEffect(() => {
    if (state === 'saved') {
      setTimeout(() => setState(undefined), 1000);
    }
  }, [state, setState]);
  const variant = whichVariant(state, defaultVariant ?? 'primary');
  return (
    <Button
      onClick={onClick}
      type={type ?? 'submit'}
      variant={variant}
      icon={icon(state)}
      tooltip={
        variant === 'destructive'
          ? `Erreur. Veuillez réessayer. (Détails: ${state})`
          : variant === 'success'
          ? 'Succès'
          : tooltip
      }
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

const selectableValueToString = (selectableValue: SelectableValue<string>): string => selectableValue.value!;

const selectableValuesToStrings = (arr: Array<SelectableValue<string>> | undefined): string[] =>
  (arr ?? []).map(selectableValueToString);

export const mapMultiSelectValueToStrings = (
  selectableValues: Array<SelectableValue<string>> | undefined
): string[] => {
  if (!selectableValues) {
    return [];
  }

  return selectableValuesToStrings(selectableValues);
};

const getProgressStyles = (theme: GrafanaTheme2) => ({
  green: getProgressStyle(theme, GREEN),
  yellow: getProgressStyle(theme, YELLOW),
  red: getProgressStyle(theme, RED),
});

const getProgressStyle = (theme: GrafanaTheme2, bgColor: string) =>
  css`
    height: 21px;
    margin-left: ${theme.spacing(1)};
    border-radius: ${theme.spacing(3)};
    padding: ${theme.spacing(0.25, 1)};
    color: ${theme.colors.text.secondary};
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.size.sm};
    background-color: ${colorManipulator.alpha(bgColor, 0.5)};
  `;

export const Progress = ({ value }: { value: number }) => {
  const styles = useStyles2(getProgressStyles);
  let className: 'red' | 'yellow' | 'green' = 'green';
  if (value < 70) {
    className = 'red';
  } else if (value < 100) {
    className = 'yellow';
  }
  return <span className={styles[className]}>{value}&nbsp;%</span>;
};

interface InputProps<T extends FieldValues, V> {
  field: Path<T>;
  defaultValue: V | undefined;
  register: UseFormRegister<T>;
  options?: RegisterOptions;
  placeholder?: string;
  autoComplete?: string;
}

export function NumberInput<T extends FieldValues>({
  field,
  defaultValue,
  register,
  options,
  placeholder,
}: InputProps<T, number>) {
  return (
    <Input
      {...register(field, { ...options, valueAsNumber: true })}
      defaultValue={defaultValue ? defaultValue : undefined}
      width={NUM_WIDTH}
      type="number"
      placeholder={placeholder}
    />
  );
}

export function EmailInput<T extends FieldValues>({
  field,
  defaultValue,
  register,
  options,
  placeholder,
  autoComplete,
}: InputProps<T, string>) {
  let placeholderWithDefault = placeholder;
  if (!placeholderWithDefault && !options?.required) {
    placeholderWithDefault = OPTIONAL;
  }
  return (
    <Input
      {...register(field, { ...options, ...EMAIL_PATTERN })}
      autoComplete={autoComplete ?? 'email'}
      defaultValue={defaultValue ? defaultValue : undefined}
      type="email"
      placeholder={placeholderWithDefault}
    />
  );
}

export function DateTimeInput<T extends FieldValues>({
  field,
  defaultValue,
  register,
  options,
}: InputProps<T, string>) {
  return (
    <Input
      {...register(field, options)}
      defaultValue={defaultValue ? defaultValue : undefined}
      width={DATETIME_WIDTH}
      type="datetime-local"
      lang="fr-FR"
    />
  );
}

export function DateInput<T extends FieldValues>({ field, defaultValue, register, options }: InputProps<T, string>) {
  return (
    <Input
      {...register(field, options)}
      defaultValue={defaultValue ? defaultValue : undefined}
      width={DATETIME_WIDTH}
      type="date"
      lang="fr-FR"
    />
  );
}

interface TextAreaProps<T extends FieldValues, V> {
  field: Path<T>;
  defaultValue: V | undefined;
  control: Control<T>;
  options?: RegisterOptions;
  placeholder?: string;
  autoComplete?: string;
  defaultRows?: number;
}

export function AutosizeTextArea<T extends FieldValues>({
  field,
  defaultValue,
  control,
  options,
  autoComplete,
  defaultRows,
  placeholder,
}: TextAreaProps<T, string>) {
  const styles = useStyles2(getStyles);
  /**
   * It handles the textarea resize. Notice that the subtracted number on
   * `e.target.scrollHeight` is the sum of top and bottom padding.
   * It's important to keep it up-to-date to avoid flickering.
   */
  const handleInput = (e: FormEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = 'auto';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight + TEXT_AREA_TOP_BOTTOM_PADDING * 2}px`;
  };
  return (
    <InputControl
      name={field as Path<T>}
      defaultValue={defaultValue ? defaultValue : undefined}
      control={control}
      rules={options}
      render={({ field: { onChange, ...field } }) => (
        <TextArea
          {...field}
          className={styles.textarea}
          autoComplete={autoComplete}
          placeholder={placeholder}
          maxLength={TXT_MAX_LENGTH}
          rows={defaultValue ? defaultValue.split(/\r?\n/).length : defaultRows ?? 1}
          onInput={(e) => {
            onChange(e);
            handleInput(e);
          }}
          onChange={(e) => {
            onChange(e);
            handleInput(e);
          }}
        />
      )}
    />
  );
}

interface GoodPractiseProps<T extends FieldValues> {
  field: Path<T>;
  fieldValues: T;
  control: Control<T>;
}

export const COMMENT_SUFFIX = 'Comment';

export function GoodPractiseInput<T extends FieldValues>({ field, fieldValues, control }: GoodPractiseProps<T>) {
  const [open, setOpen] = useState(!!fieldValues[field + COMMENT_SUFFIX]);
  return (
    <VerticalGroup>
      <HorizontalGroup>
        <InputControl
          name={field as Path<T>}
          defaultValue={fieldValues[field]}
          control={control}
          rules={REQUIRED_BOOLEAN}
          render={({ field: { onChange, ref, ...field } }) => (
            <RadioButtonGroup
              {...field}
              onChange={onChange}
              options={[
                {
                  label: 'Oui',
                  icon: 'check',
                  description: 'Action réalisée ou bonne pratique respectée',
                  value: true as PathValue<T, Path<T>>,
                },
                {
                  label: 'Non',
                  icon: 'times',
                  description: 'Action non réalisée lors de l’évaluation ou non-respect de la bonne pratique',
                  value: false as PathValue<T, Path<T>>,
                },
                { label: 'Sans Objet', icon: 'minus', value: null as PathValue<T, Path<T>> },
              ]}
            />
          )}
        />
        <Button
          icon="comment-alt-message"
          variant="secondary"
          fill="text"
          onClick={() => setOpen(!open)}
          tooltip={
            <>
              Ajouter un commentaire indiquant par exemple ce qui a été mis en place pour répondre positivement à cette
              bonne pratique. Cela peut être l’inscription d’une exigence QAI dans les CCTP de la collectivité pour
              limiter les émissions de polluants.
            </>
          }
        />
      </HorizontalGroup>
      {open && (
        <AutosizeTextArea
          field={(field + COMMENT_SUFFIX) as Path<T>}
          defaultValue={fieldValues[field + COMMENT_SUFFIX]}
          control={control}
          defaultRows={2}
        />
      )}
    </VerticalGroup>
  );
}

interface StrictSelectableValue<T = any> {
  label?: string;
  ariaLabel?: string;
  value?: T;
  imgUrl?: string;
  icon?: string;
  description?: string;
  title?: string;
}

interface CreatableSelectProps<T extends FieldValues> {
  control: Control<T>;
  register: UseFormRegister<T>;
  name: Path<T>;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  placeholder: string;
  defaultOptions: Array<StrictSelectableValue<string>>;
  defaultValue: string | undefined;
  additionalOnChange?: (value: string | undefined) => void;
  autoFocus?: boolean;
  autoComplete?: 'name';
  optional?: boolean;
}

export function CreatableSelect<T extends FieldValues>({
  control,
  register,
  name,
  rules,
  placeholder,
  autoFocus,
  defaultValue,
  defaultOptions,
  additionalOnChange,
  autoComplete,
  optional,
}: CreatableSelectProps<T>) {
  const [options, setOptions] = useState(
    defaultOptions.concat(
      !defaultValue || defaultOptions.find((o) => o.value === defaultValue)
        ? []
        : [{ label: defaultValue, value: defaultValue }]
    )
  );
  return options.length === 0 ? (
    <Input
      {...register(name, rules)}
      autoFocus={autoFocus}
      placeholder={rules?.required || !optional ? '' : OPTIONAL}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      type="text"
    />
  ) : (
    <InputControl
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, ref, ...field } }) => (
        <Select<string, { autoComplete?: 'name' }>
          {...field}
          autoComplete={autoComplete}
          onChange={(v) => {
            onChange(v?.value);
            if (additionalOnChange) {
              additionalOnChange(v?.value);
            }
          }}
          autoFocus={autoFocus}
          allowCustomValue
          noOptionsMessage={'Aucune option trouvée'}
          formatCreateLabel={() => 'Appuyez sur Entrée pour ajouter'}
          defaultValue={defaultValue}
          onCreateOption={(v) => {
            onChange(v);
            setOptions(defaultOptions.concat([{ label: v, value: v }]));
          }}
          placeholder={placeholder}
          options={options}
        />
      )}
    />
  );
}

interface EmptyRoomsProps {
  type: ReportType;
  report: Report;
}

export const EmptyRooms = ({ report, type }: EmptyRoomsProps) => (
  <VerticalGroup>
    <p>Aucune salle n’a encore été ajoutée.</p>
    <p>
      <LinkButton
        icon="plus"
        href={reportUrl(
          type,
          report.datasourceName,
          report.year,
          type === 'annuel' ? AnnuelTab.pieces : AutodiagTab.pieces
        )}
      >
        Ajouter une salle
      </LinkButton>
    </p>
  </VerticalGroup>
);

const endDateFromStart = (start: string): string => {
  const endFromStart = new Date(start);
  endFromStart.setHours(endFromStart.getHours() + 2);
  return localDateTime(endFromStart);
};

const validatePeriod = (year: string, start?: string, end?: string) => {
  if (start && new Date(start).getFullYear() !== parseInt(year, 10)) {
    return `L’année doit être celle du rapport: ${year}.`;
  }
  if (start && end && new Date(start).getTime() > new Date(end).getTime()) {
    return 'L’heure de fin doit être après celle de début.';
  }
  return true;
};

const PeriodWarning = ({
  styles,
  start,
  end,
}: {
  styles: { warningDescription: string };
  start?: string;
  end?: string;
}) => {
  const warnings: string[] = [];
  if (!start || !end) {
    return <></>;
  }
  const startDate = new Date(start);
  const startTime = startDate.getTime();
  const endTime = new Date(end).getTime();
  if (endTime - startTime < 3600000 * 2) {
    warnings.push(`La durée de la mesure doit être d’au moins 2 heures, 
  sauf si les locaux présentent en situation habituelle une durée d’occupation inférieure à 2 heures sur une journée.`);
  }
  // 15 Oct to 15 Apr are Typical France heating time
  // from https://www.lavoixdunord.fr/1386293/article/2023-10-17/individuel-ou-collectif-quand-faut-il-allumer-le-chauffage
  const startHeating = new Date(startDate.getFullYear(), 9, 14).getTime();
  const endHeating = new Date(startDate.getFullYear(), 3, 15).getTime();
  if ((endHeating < startTime && startTime < startHeating) || (endHeating < endTime && endTime < startHeating)) {
    warnings.push(`La mesure à lecture directe doit être réalisée en période de chauffage, si elle existe.`);
  }
  return (
    <VerticalGroup>
      {warnings.map((w, i) => (
        <p key={i} className={cx([styles.warningDescription])}>
          {w}
        </p>
      ))}
    </VerticalGroup>
  );
};

export const CO2MeasureDates = ({
  rules,
  report,
  room,
  errors,
  register,
  watch,
  setValue,
}: {
  rules: RegisterOptions;
  report: Report;
  room?: Room;
  errors: DeepMap<Org, FieldError>;
  register: UseFormRegister<Room>;
  watch: UseFormWatch<Room>;
  setValue: UseFormSetValue<Room>;
}) => {
  const styles = useStyles2(getStyles);
  return (
    <>
      <TooltipField
        label="Dates et heures de début et fin de mesure"
        tooltip={
          <>
            <p>
              La mesure à lecture directe est réalisée en <strong>période de chauffage</strong>, si elle existe, et dans
              les conditions normales d’exploitation de la pièce (présence des usagers, activités et pratiques
              d’aération et ventilation habituelles). Elle est effectuée sur une période au cours de laquelle l’effectif
              présent dans la pièce est compris entre 0,5 fois et 1,5 fois l’effectif théorique de la pièce étudiée.
            </p>
            <p>
              La mesure est idéalement réalisée pendant les{' '}
              <strong>deux heures d’occupation présentant le risque de confinement le plus élevé</strong> sans remettre
              en cause les pratiques, ni chercher à reproduire des conditions de confinement plus défavorables que
              celles d’usage. Le Tableau 2 du {GUIDE_CSTB} fournit des informations pour aider à déterminer l’effectif
              théorique et pour identifier les périodes avec risque de confinement élevé par type de salle.
            </p>
            <p>
              Dans le cas des établissements d’enseignement où l’occupation change en moins de deux heures (par exemple
              les salles de cours dans le second degré où les élèves changent de salle après une heure de cours),
              privilégier le fractionnement par heure de cours. C’est-à-dire qu’il faut commencer à mesurer au début
              d’un cours avec forte fréquentation et arrêter de mesurer à la fin du cours suivant. La pause intercours
              ou la récréation peut être intégrée à la période de mesure à lecture directe de la concentration en CO₂.
              Lorsque les locaux présentent en situation habituelle une durée d’occupation inférieure à 2 heures sur une
              journée (exemple : salle de restauration d’une petite école), il est exceptionnellement toléré de réaliser
              la mesure à lecture directe du CO₂ sur une durée plus courte correspondant à la période d’occupation.
            </p>
            <p>({GUIDE_CSTB})</p>
          </>
        }
        error={errors.dateHeureDebutMesure ?? errors.dateHeureFinMesure}
        description={
          <PeriodWarning
            styles={styles}
            start={watch('dateHeureDebutMesure', room?.dateHeureDebutMesure)}
            end={watch('dateHeureFinMesure', room?.dateHeureFinMesure)}
          />
        }
      >
        <VerticalGroup>
          <Input
            {...register('dateHeureDebutMesure', {
              ...rules,
              validate: (v) => validatePeriod(report.year, v, watch('dateHeureFinMesure', room?.dateHeureFinMesure)),
            })}
            onChange={(e) => {
              if (!watch('dateHeureFinMesure')) {
                setValue('dateHeureFinMesure', endDateFromStart(e.currentTarget.value));
              }
            }}
            defaultValue={room?.dateHeureDebutMesure}
            width={DATETIME_WIDTH}
            type="datetime-local"
            lang="fr-FR"
          />
          <Input
            {...register('dateHeureFinMesure', {
              ...rules,
              validate: (v) =>
                validatePeriod(report.year, watch('dateHeureDebutMesure', room?.dateHeureDebutMesure), v),
            })}
            defaultValue={room?.dateHeureFinMesure}
            width={DATETIME_WIDTH}
            type="datetime-local"
            lang="fr-FR"
          />
        </VerticalGroup>
      </TooltipField>
    </>
  );
};

export interface CalendarEvent {
  name: string;
  description: string;
  startDate: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  location: string;
  uid: string;
  organizer?: string;
  attendee?: string;
}

export const CalendarButton = ({ calendarDates, name }: { calendarDates: CalendarEvent[]; name: string }) =>
  calendarDates.length === 0 ? (
    <></>
  ) : (
    <Button
      variant="secondary"
      icon="calendar-alt"
      onClick={(e: MouseEvent<HTMLButtonElement>) =>
        atcb_action(
          {
            language: 'fr',
            // only overlay and modal are supported and overlay is broken
            listStyle: 'modal',
            lightMode: 'system',
            name: name,
            options: ['iCal', 'Apple', 'Google', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'],
            customLabels: {
              'label.icalfile': 'Fichier ICS (recommandé)',
              'modal.multidate.h': 'Liste d’évènements',
              'modal.multidate.text': 'Ajouter les évènements un par un :',
            },
            dates: calendarDates,
          },
          e.currentTarget
        )
      }
    >
      Ajouter à mon calendrier
    </Button>
  );

export const escapeCalTag = (str: string | undefined) => (str ?? '').replaceAll('[', '&#91;');

// Trying to comply with add-to-calendar but some very long emails could still fail
const formatNameEmail = (name: string | undefined, email: string) =>
  `${(name ?? email).replaceAll('|', '/').substring(0, 50)}|${email}`;

export function addAttendee(
  report: Report,
  attendeeName: string | undefined,
  attendeeEmail: string | undefined,
  /** To be escaped with escapeCalTag */
  event: CalendarEvent
): CalendarEvent {
  if (attendeeEmail && report.org.respEmail) {
    event.organizer = formatNameEmail(report.org.respPersonne, report.org.respEmail);
    event.attendee = formatNameEmail(attendeeName, attendeeEmail);
  }
  return event;
}

export const SalleFields = ({
  reportType,
  report,
  roomLabel,
  roomTooltip,
  room,
  errors,
  control,
  register,
  setValue,
}: {
  reportType: ReportType;
  report: Report;
  room?: Room;
  roomLabel: string;
  roomTooltip?: string | React.ReactElement;
  errors: DeepMap<Org, FieldError>;
  control: Control<Room>;
  register: UseFormRegister<Room>;
  setValue: UseFormSetValue<Room>;
}) => (
  <>
    <input {...register('id')} type="hidden" value={room?.id ?? uuidv4()} />
    <TooltipField label={roomLabel} tooltip={roomTooltip} error={errors.nom}>
      {room ? (
        <Input
          {...register('nom', {
            ...REQUIRED,
            validate: (v) => {
              if (v && v !== room?.nom && report.allRooms.some((r) => r.nom.trim() === v)) {
                return 'Ne peut pas être le même nom qu’une salle existante.';
              }
              return true;
            },
          })}
          defaultValue={room?.nom}
          type="text"
        />
      ) : (
        <CreatableSelect
          name="nom"
          rules={REQUIRED}
          defaultValue={undefined}
          control={control}
          register={register}
          additionalOnChange={(v) => {
            const room = report.allRooms.find((n) => n.nom === v);
            if (room) {
              setValue('id', room.id);
            }
          }}
          placeholder={'Choisir ou ajouter une salle'}
          defaultOptions={makeOptions(
            report.allRooms
              .filter(
                (r) =>
                  !(reportType === 'annuel' ? report.annuelRooms : report.autodiagRooms)
                    .map((r) => r.nom)
                    .includes(r.nom)
              )
              .map((r) => [r.nom])
          )}
        />
      )}
    </TooltipField>
    <TooltipField label="Commentaire" tooltip="Justification du choix de la salle évaluée." error={errors.comment}>
      <Input {...register('comment')} placeholder={OPTIONAL} defaultValue={room?.comment} type="text" />
    </TooltipField>
  </>
);
