import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import { Form, HorizontalGroup, Input } from '@grafana/ui';
import React, { Dispatch, SetStateAction } from 'react';
import { Org, Report, TabObjectState } from 'utils/utils.model';
import { Address, REQUIRED, SaveButton, SaveState, TooltipField } from '../../utils/utils.form';
import { FORM_MAX_WIDTH } from '../../utils/utils.styles';
import { updateThing } from './queries';

const SAVE_ID = 'button';

export class AutodiagOrgSceneObject extends SceneObjectBase<TabObjectState> {
  static Component = AutodiagOrgSceneObjectRenderer;

  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({ report: report, setReports: setReports, ...state });
  }
  setSaving = (saving: SaveState) => {
    this.state.report.editState.autodiag.etablissement.saving[SAVE_ID] = saving;
    this.setState({ report: this.state.report });
  };
}

function AutodiagOrgSceneObjectRenderer({ model }: SceneComponentProps<AutodiagOrgSceneObject>) {
  const { report, setReports } = model.useState();

  const doSubmit = (org: Org) => {
    model.setSaving('saving');
    updateThing(
      'autodiag',
      report,
      { org: org },
      () => model.setSaving('saved'),
      (err) => model.setSaving(err),
      setReports
    );
  };

  const org = report.org;
  return (
    <Form<Org> onSubmit={doSubmit} maxWidth={FORM_MAX_WIDTH}>
      {({ register, errors, control, watch, setValue }) => (
        <div>
          <input {...register('id')} type="hidden" value={org.id} />
          <TooltipField label="Nom de l’établissement" error={errors.nom}>
            <Input {...register('nom', REQUIRED)} autoComplete="work organization" defaultValue={org.nom} type="text" />
          </TooltipField>
          <Address
            adresse="adresse"
            codePostal="codePostal"
            ville="ville"
            org={org}
            errors={errors}
            register={register}
            control={control}
          />
          <HorizontalGroup align="flex-start" justify="flex-end">
            <SaveButton
              label="Sauvegarder"
              state={report.editState.autodiag.etablissement.saving[SAVE_ID]}
              setState={model.setSaving}
            />
          </HorizontalGroup>
        </div>
      )}
    </Form>
  );
}
