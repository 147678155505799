import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import React, { Dispatch, SetStateAction } from 'react';
import { SaveState } from '../../utils/utils.form';
import { AutodiagTab } from '../../utils/utils.routing';
import { Autodiag, AutodiagFormTab } from './Autodiag';
import { Org, Report, TabObjectState } from 'utils/utils.model';
import { updateThing } from './queries';

class AutodiagOrgSceneObject extends SceneObjectBase<TabObjectState> {
  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({ report: report, setReports: setReports, ...state });
  }
  setSaving = (saving: SaveState, tab: AutodiagTab, id: string) => {
    this.state.report.editState.autodiag[tab].saving[id] = saving;
    this.setState({ report: this.state.report });
  };
}

const makeRenderer = (tab: AutodiagFormTab) =>
  function AutodiagOrgPractisesSceneObjectRenderer({ model }: SceneComponentProps<AutodiagOrgSceneObject>) {
    const { report, setReports } = model.useState();
    const doSubmit = (saveIdPrefix: string) => (org: Org) => {
      model.setSaving('saving', tab, saveIdPrefix + org.id);
      updateThing(
        'autodiag',
        report,
        { org: org },
        () => model.setSaving('saved', tab, saveIdPrefix + org.id),
        (err) => model.setSaving(err, tab, saveIdPrefix + org.id),
        setReports
      );
    };
    return (
      <Autodiag
        doSubmit={doSubmit}
        tab={tab}
        report={report}
        thing={report.org}
        setSaveState={(id: string, s: SaveState) => model.setSaving(s, tab, id)}
      />
    );
  };

export class AutodiagGestionSceneObject extends AutodiagOrgSceneObject {
  static Component = makeRenderer(AutodiagTab.gestion);
}

export class AutodiagMaintenanceSceneObject extends AutodiagOrgSceneObject {
  static Component = makeRenderer(AutodiagTab.maintenance);
}

export class AutodiagEntretienSceneObject extends AutodiagOrgSceneObject {
  static Component = makeRenderer(AutodiagTab.entretien);
}
