import jsPDF from 'jspdf';
import { CellInput, Color, Styles } from 'jspdf-autotable';
import { CIRCLE, CROSS, DEFAULT_FONT, ICONS, MINUS, TICK } from 'utils/utils.font';
import { COMMENT_SUFFIX } from 'utils/utils.form';
import { formatAddress, formatDate } from 'utils/utils.format';
import { Org, Report, Room } from 'utils/utils.model';
import {
  FONT_SIZE,
  FOUR_COLUMNS_STYLES,
  FileFormat,
  Logo,
  NO_BORDER,
  PAR_DECRET,
  QAI_TITLE,
  SECTION_TITLE_FONT_SIZE,
  SUBTITLE_STYLES,
  TABLE_TOP_MARGIN,
  TITLE_STYLES,
  addFooters,
  addMargin,
  createDoc,
  fileName,
  genTable,
  generateActionPlan,
  getLogo,
  saveFile,
  titleHeader,
} from 'utils/utils.pdf';
import { AutodiagTab } from 'utils/utils.routing';
import { REPORT_BLACK, REPORT_GREEN, REPORT_GREY } from 'utils/utils.styles';
import { AUTODIAG_TAB_FIELDS, CheckboxFields, POLLUTANT_SOURCE, TabFields } from './Autodiag';

const FIRST_COL_WIDTH = 8;

const TICK_COLOR: Color = REPORT_GREEN;
const CROSS_COLOR: Color = REPORT_BLACK;
const MINUS_COLOR: Color = REPORT_GREY;

const CIRCLE_SIZE = 7;

const PRACTISE_COLUMN_STYLES: {
  [key: string]: Partial<Styles>;
} = {
  0: { font: ICONS, fontSize: 14, halign: 'center', valign: 'middle', cellWidth: FIRST_COL_WIDTH, ...NO_BORDER },
  1: { halign: 'left', valign: 'top', cellWidth: 'auto', ...NO_BORDER },
};

const CHECKBOX_COLUMN_STYLES: {
  [key: string]: Partial<Styles>;
} = {
  0: {
    font: ICONS,
    fontSize: CIRCLE_SIZE,
    halign: 'center',
    valign: 'top',
    cellWidth: FIRST_COL_WIDTH,
    ...NO_BORDER,
  },
  1: { halign: 'left', valign: 'middle', cellWidth: 'auto', ...NO_BORDER },
};

export function generateAutodiagPdf(report: Report) {
  generateAutodiag(FileFormat.PDF, report);
}

export function generateAutodiagCsv(report: Report) {
  generateAutodiag(FileFormat.CSV, report);
}

function generateAutodiag(fmt: FileFormat, report: Report) {
  getLogo(report.org.logo).then((l) => generateWithLogo(fmt, report, l));
}

const formatBool = (thing: Org | Room, key: string): [string, Color | undefined] => {
  const val = thing[key];
  if (val === true) {
    return [TICK, TICK_COLOR];
  }
  if (val === false) {
    return [CROSS, CROSS_COLOR];
  }
  return [MINUS, MINUS_COLOR];
};

function generateSection(
  report: Report,
  doc: jsPDF | undefined,
  csv: string[][] | undefined,
  tab: string,
  fields: TabFields,
  i: number
) {
  if (tab === AutodiagTab.activites) {
    report.autodiagRooms.forEach((r) => generateSectionWithThing(r, true, doc, csv, tab, fields, i));
  } else {
    generateSectionWithThing(report.org, false, doc, csv, tab, fields, i);
  }
}

function generateSectionWithThing(
  thing: Org | Room,
  isRoom: boolean,
  doc: jsPDF | undefined,
  csv: string[][] | undefined,
  tab: string,
  fields: TabFields,
  i: number
) {
  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [
      [
        {
          content: `${i + 1} - ${fields.nom.toUpperCase()}${isRoom ? ' - ' + thing.nom : ''}`,
          styles: { font: DEFAULT_FONT, fontSize: SECTION_TITLE_FONT_SIZE, fontStyle: 'bold' },
        },
      ],
      [
        {
          content: `Rempli le ${formatDate(new Date(thing[fields.dateField]))} par ${thing[fields.nameField]} (${
            thing[fields.functionField]
          })`,
          styles: { font: DEFAULT_FONT, fontSize: FONT_SIZE },
        },
      ],
    ],
    theme: 'plain',
    tableLineWidth: 0,
    styles: {
      fontSize: FONT_SIZE,
    },
  });

  if (fields.formaldehyde && fields.benzene) {
    genTable(doc, csv, {
      startY: addMargin(doc, TABLE_TOP_MARGIN),
      head: [
        [
          {
            content: POLLUTANT_SOURCE,
            colSpan: 2,
            styles: TITLE_STYLES,
          },
        ],
      ],
    });
    genTable(doc, csv, {
      startY: addMargin(doc, 0),
      head: [
        [
          {
            content: 'Benzène',
            colSpan: 2,
            styles: SUBTITLE_STYLES,
          },
        ],
      ],
      body: generateCheckboxFieldBody(thing, fields.benzene, 'Aucune source émettrice de benzène identifiée'),
      columnStyles: CHECKBOX_COLUMN_STYLES,
    });
    genTable(doc, csv, {
      startY: addMargin(doc, 0),
      head: [
        [
          {
            content: 'Formaldéhyde',
            colSpan: 2,
            styles: SUBTITLE_STYLES,
          },
        ],
      ],

      body: generateCheckboxFieldBody(thing, fields.formaldehyde, 'Aucune source émettrice de formaldéhyde identifiée'),
      columnStyles: CHECKBOX_COLUMN_STYLES,
    });
  }

  Object.entries(fields.practises).forEach(([group, choices]) => {
    const [, choiceFields] = choices;
    genTable(doc, csv, {
      startY: addMargin(doc, TABLE_TOP_MARGIN),
      head: [
        [
          {
            content: group,
            colSpan: 6,
            styles: TITLE_STYLES,
          },
        ],
      ],
      body: Object.entries(choiceFields).map(([key, labelAndTooltip]) => {
        const [val, color] = formatBool(thing, key);
        return [
          {
            content: val,
            styles: { textColor: color, font: ICONS, fontSize: FONT_SIZE },
          },
          labelAndTooltip[0] +
            '.' +
            (thing[key + COMMENT_SUFFIX] ? `\nCommentaires : ${thing[key + COMMENT_SUFFIX]}` : ''),
        ];
      }),
      columnStyles: PRACTISE_COLUMN_STYLES,
    });
  });

  if (fields.observations) {
    genTable(doc, csv, {
      startY: addMargin(doc, TABLE_TOP_MARGIN),
      head: [
        [
          {
            content: 'Observations à signifier à l’équipe de gestion',
            colSpan: 2,
            styles: TITLE_STYLES,
          },
        ],
      ],
      body: generateCheckboxFieldBody(thing, fields.observations, 'Aucune observation particulière à signifier'),
      columnStyles: CHECKBOX_COLUMN_STYLES,
    });
  }
}

function generateCheckboxFieldBody(thing: Org | Room, fields: CheckboxFields, noChoiceLabel: string): CellInput[][] {
  if (thing[fields.noChoiceField]) {
    return [[CIRCLE, noChoiceLabel]];
  }
  const [, choices] = fields.choiceFields;
  return Object.entries(choices)
    .filter(([key]) => thing[key] === true)
    .map(([, [label]]) => [CIRCLE, label])
    .concat(
      fields.otherChoiceField && thing[fields.otherChoiceField]
        ? [
            [
              CIRCLE,
              `${fields.otherChoiceLabel} :
${fields.commentField ? thing[fields.commentField] : ''}`,
            ],
          ]
        : []
    );
}

function generateWithLogo(fmt: FileFormat, report: Report, logo: Logo | undefined) {
  const org = report.org;
  const doc = fmt === FileFormat.PDF ? createDoc() : undefined;
  const csv = fmt === FileFormat.CSV ? [] : undefined;
  titleHeader(doc, csv, logo, QAI_TITLE, undefined, 'Autodiagnostic ' + report.year, PAR_DECRET);
  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content: 'Établissement',
          colSpan: 4,
          styles: TITLE_STYLES,
        },
      ],
    ],
    body: [['Nom', org.nom, 'Adresse', formatAddress(org.adresse, org.codePostal, org.ville)]],
    columnStyles: FOUR_COLUMNS_STYLES,
  });

  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),

    body: [
      [
        { content: 'Légende', styles: { fontStyle: 'bold' } },
        { content: TICK, styles: { textColor: TICK_COLOR, font: ICONS, fontSize: FONT_SIZE } },
        'Respect de la bonne pratique',
        { content: CROSS, styles: { textColor: CROSS_COLOR, font: ICONS, fontSize: FONT_SIZE } },
        'Non-respect de la bonne pratique',
        { content: MINUS, styles: { textColor: MINUS_COLOR, font: ICONS, fontSize: FONT_SIZE } },
        'Sans Objet',
        { content: CIRCLE, styles: { valign: 'middle', font: ICONS, fontSize: CIRCLE_SIZE } },
        'Point d’attention',
      ],
    ],
  });

  Object.entries(AUTODIAG_TAB_FIELDS).forEach(([tab, fields], i) => {
    generateSection(report, doc, csv, tab, fields, i);
  });

  const autodiagReportOnlyPages = doc?.getNumberOfPages();
  generateActionPlan(doc, csv, 'autodiag', report, logo);

  addFooters(doc, 'autodiag', report, org.nom, autodiagReportOnlyPages, 'Autodiagnostic', 'Plan d’action');
  saveFile(doc, csv, fileName(org.nom, report.year, 'Autodiagnostic'));
}
