import { CellInput, Styles } from 'jspdf-autotable';
import { DISSATISFIED_FACE, ICONS, NEUTRAL_FACE, SATISFIED_FACE } from 'utils/utils.font';
import {
  formatAddress,
  formatDateString,
  formatDayMonth,
  formatInt,
  formatList,
  formatMulti,
  formatPeriodString,
} from 'utils/utils.format';
import { NO_OBTURATION, Report, Room, hasFilter, hasMaintenance, respQualiteWithOwnAddress } from 'utils/utils.model';
import {
  BORDER_LEFT,
  BORDER_LEFT_HEAD,
  CENTER_TITLE_STYLES,
  EMOTICON_SIZE,
  FONT_SIZE,
  FOUR_COLUMNS_STYLES,
  FileFormat,
  Logo,
  NO_BORDER,
  PARAGRAPH_COLUMNS_STYLES,
  PAR_DECRET,
  QAI_TITLE,
  SECTION_TITLE_COLUMNS_STYLES,
  SIZE_1,
  SIZE_1_5,
  SIZE_1_66,
  SIZE_2,
  SIZE_2_5,
  SIZE_3,
  SIZE_4_66,
  TABLE_TOP_MARGIN,
  TITLE_STYLES,
  WIDTH_2,
  WIDTH_3,
  WIDTH_4,
  WIDTH_8,
  addFooters,
  addMargin,
  columnsFromStyles,
  createDoc,
  fileName,
  genTable,
  generateActionPlan,
  getLogo,
  saveFile,
  titleHeader,
} from 'utils/utils.pdf';

export function generateAnnuelFullReportPdf(report: Report) {
  generateAnnuelFullReport(FileFormat.PDF, report);
}

export function generateAnnuelFullReportCsv(report: Report) {
  generateAnnuelFullReport(FileFormat.CSV, report);
}

function generateAnnuelFullReport(fmt: FileFormat, report: Report) {
  getLogo(report.org.logo).then((l) => generateFullReportWithLogo(fmt, report, l));
}

export function generateAnnuelResultsPdf(report: Report) {
  getLogo(report.org.logo).then((l) => generateResultsPdfWithLogo(report, l));
}

const modeControleDescription = (
  modeValeur?: boolean,
  modeIndicateur?: boolean,
  modeSonore?: boolean,
  modeEnregistrement?: boolean
) => {
  const actions = [];
  if (modeValeur) {
    actions.push('valeur (ppm)');
  }
  if (modeIndicateur || modeSonore) {
    const indicateurs = [];
    if (modeIndicateur) {
      indicateurs.push('visuel');
    }
    if (modeSonore) {
      indicateurs.push('sonore');
    }
    const indicateurStr = 'indicateur' + (indicateurs.length > 1 ? 's ' : ' ') + formatMulti(indicateurs);
    actions.push(indicateurStr + (indicateurs.length > 1 && modeEnregistrement ? ',' : ''));
  }
  if (modeEnregistrement) {
    actions.push('enregistrement');
  }
  return formatMulti(actions, true);
};

const formatOpposite = (bool: boolean | undefined): string => {
  if (bool === undefined) {
    return '';
  }
  return formatBoolean(!bool);
};

// ✓	✔	✕	✖	✗	✘
const formatBoolean = (bool: boolean | undefined): string => {
  if (bool === true) {
    return 'Oui';
  }
  if (bool === false) {
    return 'Non';
  }
  return '';
};

const formatActions = (r: Room) => {
  const actions = [];
  if (r.actionAucune) {
    actions.push('Aucune');
  }
  if (r.actionUneOuverture) {
    actions.push('Ouverture d’une fenêtre');
  }
  if (r.actionAerationEnGrand) {
    actions.push('Aération en grand');
  }
  if (r.actionAerationTransversale) {
    actions.push('Aération transversale');
  }
  if (r.actionPorte) {
    actions.push('Ouverture de la porte');
  }
  return formatMulti(actions);
};

function evaluationDates(report: Report): [Date] | [Date, Date] {
  const evaluationTimes = [
    ...new Set(
      report.annuelRooms
        .map((r) => (r.dateAeration ? new Date(new Date(r.dateAeration).toDateString()).getTime() : undefined))
        .concat(
          report.annuelRooms.map((r) =>
            r.dateHeureDebutMesure ? new Date(new Date(r.dateHeureDebutMesure).toDateString()).getTime() : undefined
          )
        )
        .filter((d): d is number => d !== undefined)
    ),
  ].sort();
  return evaluationTimes.length > 1
    ? [new Date(evaluationTimes[0]), new Date(evaluationTimes[evaluationTimes.length - 1])]
    : [new Date(evaluationTimes[0])];
}

function generateFullReportWithLogo(fmt: FileFormat, report: Report, logo: Logo | undefined) {
  const org = report.org;
  const doc = fmt === FileFormat.PDF ? createDoc() : undefined;
  const csv = fmt === FileFormat.CSV ? [] : undefined;
  titleHeader(
    doc,
    csv,
    logo,
    QAI_TITLE,
    undefined,
    'Rapport d’évaluation des moyens d’aération ' + report.year,
    PAR_DECRET
  );
  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content: 'Établissement',
          colSpan: 4,
          styles: TITLE_STYLES,
        },
      ],
    ],
    body: [
      ['Nom', org.nom, 'Type', org.type ?? ''],
      ['Adresse', formatAddress(org.adresse, org.codePostal, org.ville), 'N° de SIRET', org.siret ?? ''],
      [
        'Pièces évaluables',
        formatInt(org.pieces),
        org.effectif ? 'Effectif théorique max' : '',
        formatInt(org.effectif),
      ],
    ],
    columnStyles: FOUR_COLUMNS_STYLES,
  });
  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content:
            (org.propQualite?.toLowerCase() === 'propriétaire' ? 'Propriétaire' : 'Exploitant') + ' de l’établissement',
          colSpan: 4,
          styles: TITLE_STYLES,
        },
      ],
    ],
    body: [
      ['Personne morale', org.propPersonneMorale ?? '', 'Personne référente', org.propPersonne ?? ''],
      ['Adresse', formatAddress(org.propAdresse, org.propCodePostal, org.propVille), 'Courriel', org.propEmail ?? ''],
      ['Service concerné', org.propService ?? '', 'Téléphone', org.propTel ?? ''],
    ],
    columnStyles: FOUR_COLUMNS_STYLES,
  });
  const evalDates = evaluationDates(report);
  const evalDateLabel = evalDates.length > 1 ? 'Dates de l’évaluation' : 'Date de l’évaluation';
  let evalDate = evalDates.map((d) => formatDayMonth(d)).join(' – ');
  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content: 'Responsable de l’évaluation des moyens d’aération',
          colSpan: 4,
          styles: TITLE_STYLES,
        },
      ],
    ],
    body: [
      ['Personne', org.respPersonne ?? '', 'Qualité', org.respQualite ?? '', ''],
      ['Téléphone', org.respTel ?? '', 'Courriel', org.respEmail ?? '', ''],
    ].concat(
      respQualiteWithOwnAddress(org.respQualite, org.propQualite)
        ? [
            ['Organisme', org.respPersonneMorale ?? '', evalDateLabel, evalDate],
            [
              'Adresse',
              formatAddress(org.respAdresse, org.respCodePostal, org.respVille),
              'SIRET',
              org.respSiret ?? '',
              '',
            ],
          ]
        : [[evalDateLabel, evalDate, '', '']]
    ),
    columnStyles: FOUR_COLUMNS_STYLES,
  });

  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content: 'Aération par zone',
          colSpan: 5,
          styles: TITLE_STYLES,
        },
      ],
      [
        { content: 'Zone', styles: { halign: 'left' } },
        { content: 'Pièces', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Mode d’aération ou de ventilation', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Dernière maintenance', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Changement de filtre', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
      ],
    ],
    body: report.annuelZones.map((z) => [
      z.nom,
      report.annuelZones.length === 1
        ? 'Toutes les pièces'
        : formatList(report.annuelRooms.filter((r) => r.zoneId === z.id).map((r) => r.nom)),
      z.mode ?? '',
      formatDateString(z.maintenance),
      formatDateString(z.filtres),
    ]),
    columnStyles: {
      0: { fontSize: FONT_SIZE, halign: 'left', cellWidth: 'auto' },
      1: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_3, ...BORDER_LEFT },
      2: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_4_66, ...BORDER_LEFT },
      3: { fontSize: FONT_SIZE, halign: 'right', cellWidth: SIZE_2, ...BORDER_LEFT },
      4: { fontSize: FONT_SIZE, halign: 'right', cellWidth: SIZE_2, ...BORDER_LEFT },
    },
  });

  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content: 'Aération par pièce',
          colSpan: 8,
          styles: TITLE_STYLES,
        },
      ],
      [
        { content: '', styles: { lineWidth: 0 } },
        { content: 'Nombre d’ouvrants', colSpan: 4, styles: BORDER_LEFT_HEAD },
        {
          content: 'Bouches d’aération',
          colSpan: 3,
          styles: BORDER_LEFT_HEAD,
        },
      ],
      [
        { content: 'Pièce', styles: { valign: 'bottom', halign: 'left' } },
        { content: 'Total', styles: BORDER_LEFT_HEAD },
        'Ouvrable',
        'Facile d’accès',
        'Facile à ouvrir',
        { content: 'Fonctionne', styles: BORDER_LEFT_HEAD },
        'Obturé',
        'Encrassé',
      ],
    ],
    body: report.annuelRooms.map((r) => [
      r.nom,
      formatInt(r.ouvrants),
      formatInt(r.ouvrable),
      formatInt(r.accessible),
      formatInt(r.manoeuvrable),
      formatBoolean(r.fonctionne),
      r.obture ?? '',
      formatBoolean(r.encrasse),
    ]),
    columnStyles: {
      0: { fontSize: FONT_SIZE, cellWidth: 'auto' },
      1: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_5, ...BORDER_LEFT },
      2: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_5 },
      3: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_5 },
      4: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_5 },
      5: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_66, ...BORDER_LEFT },
      6: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_66 },
      7: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_66 },
    },
  });

  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content: 'Mesure de CO₂',
          colSpan: 8,
          styles: TITLE_STYLES,
        },
      ],
      [
        { content: '', styles: { lineWidth: 0 } },
        { content: 'Effectif', colSpan: 2, styles: BORDER_LEFT_HEAD },
        { content: 'CO₂ en ppm', colSpan: 3, styles: BORDER_LEFT_HEAD },
        { content: 'Conséquences', colSpan: 2, styles: BORDER_LEFT_HEAD },
      ],
      [
        { content: 'Pièce', styles: { valign: 'bottom', halign: 'left' } },
        { content: 'Théorique', styles: BORDER_LEFT_HEAD },
        'Moyen',
        { content: '< 800', styles: BORDER_LEFT_HEAD },
        '< 1500',
        'Max',
        { content: 'Actions', styles: BORDER_LEFT_HEAD },
        'Résultat',
      ],
    ],
    body: report.annuelRooms.map((r) => [
      r.nom + '\n(' + formatPeriodString(r.dateHeureDebutMesure ?? '', r.dateHeureFinMesure ?? '') + ')',
      formatInt(r.effectifMax),
      formatInt(r.occupants),
      formatOpposite(r.ppm800),
      formatOpposite(r.ppm1500),
      formatInt(r.maxCo2),
      formatActions(r),
      r.resultat,
    ]),
    columnStyles: {
      0: { fontSize: FONT_SIZE, cellWidth: 'auto' },
      1: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_5, ...BORDER_LEFT },
      2: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1_5 },
      3: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1, ...BORDER_LEFT },
      4: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1 },
      5: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_1 },
      6: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_2_5, ...BORDER_LEFT },
      7: { fontSize: FONT_SIZE, halign: 'center', cellWidth: SIZE_2_5 },
    },
  });

  const allMonitors: Array<[string, string, string, string, string, string]> = report.annuelRooms.map((r) => [
    r.nom,
    r.modele + ' (' + r.noSerie + ')',
    r.conformiteSeuils ? 'Conforme' : 'Non conforme',
    r.frequence ?? '',
    modeControleDescription(r.modeValeur, r.modeIndicateur, r.modeSonore, r.modeEnregistrement),
    r.modeEtalonnage + ' (' + formatDateString(r.dateEtalonnage) + ')',
  ]);
  const uniqueMonitors = new Map<String, [string, string, string, string, string, string]>();
  allMonitors.forEach((m) => {
    const key = m.slice(1).join();
    if (uniqueMonitors.has(key)) {
      const existing = uniqueMonitors.get(key) as [string, string, string, string, string, string];
      if (!existing[0].startsWith('–')) {
        existing[0] = '– ' + existing[0];
      }
      existing[0] = existing[0] + '\n– ' + m[0];
    } else {
      uniqueMonitors.set(key, m);
    }
  });
  if (uniqueMonitors.size === 1) {
    uniqueMonitors.values().next().value[0] = 'Toutes les pièces';
  }

  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content: 'Appareil de mesure du CO₂',
          colSpan: 6,
          styles: TITLE_STYLES,
        },
      ],
      [
        {
          content: uniqueMonitors.size > 1 && uniqueMonitors.size === allMonitors.length ? 'Pièce' : 'Pièces',
          styles: { halign: 'left' },
        },
        { content: 'Modèle (N° de série)', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Seuils', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Fréquence', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Contrôle', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
        { content: 'Étalonnage', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
      ],
    ],
    body: [...uniqueMonitors.values()],
    columnStyles: {
      0: { fontSize: FONT_SIZE, halign: 'left', cellWidth: 'auto' },
      1: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_3, ...BORDER_LEFT },
      2: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_1_5, ...BORDER_LEFT },
      3: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_1_5, ...BORDER_LEFT },
      4: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_2_5, ...BORDER_LEFT },
      5: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_2_5, ...BORDER_LEFT },
    },
  });

  genTable(doc, csv, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [[`Responsable de l’évaluation : ${org.respPersonne}`, 'Date : ', 'Signature : ']],
    columnStyles: {
      0: { fontSize: FONT_SIZE, halign: 'left', cellWidth: 'auto', ...NO_BORDER, fillColor: undefined },
      1: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_3, ...NO_BORDER, fillColor: undefined },
      2: { fontSize: FONT_SIZE, halign: 'left', cellWidth: SIZE_4_66, ...NO_BORDER, fillColor: undefined },
    },
    tableLineWidth: 0,
  });

  const annuelReportOnlyPages = doc?.getNumberOfPages();
  generateActionPlan(doc, csv, 'annuel', report, logo);
  addFooters(
    doc,
    'annuel',
    report,
    org.nom,
    annuelReportOnlyPages,
    'Rapport d’évaluation des moyens d’aération',
    'Plan d’action'
  );
  saveFile(doc, csv, fileName(org.nom, report.year, 'Rapport d’évaluation des moyens d’aération'));
}

export const generateBilan = (report: Report) => {
  const mesures: string[] = report.org.actions
    .filter((a) => a.sourceReportType === 'annuel' && a.sourceYear === report.year)
    .map((a) => `\n– ${a.nom}.`);
  return mesures.length === 0
    ? 'Les moyens d’aération sont satisfaisants.\nAucune mesure n’est suggérée.'
    : mesures.length === 1
    ? 'La mesure suivante est suggérée.' + mesures[0]
    : 'Les mesures suivantes sont suggérées.' + mesures.join('');
};

export const DEFAULT_EMOTICON = true;

function generateResultsPdfWithLogo(report: Report, logo: Logo | undefined) {
  const org = report.org;
  const doc = createDoc();
  titleHeader(
    doc,
    undefined,
    logo,
    'Qualité de l’air intérieur',
    report.org.nom,
    'Résultats de l’évaluation des moyens d’aération ' + report.year,
    '',
    0
  );
  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [
      [
        `L’évaluation annuelle des moyens d’aération permet de vérifier la bonne aération des locaux.
Cette bonne aération est essentielle pour diminuer les concentrations de polluants présents en intérieur.`,
      ],
    ],
    columnStyles: {
      0: { fontSize: 11, halign: 'left', cellWidth: 'auto', ...NO_BORDER, fillColor: undefined },
    },
    tableLineWidth: 0,
  });

  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content:
            `Bilan général de l’évaluation ` +
            (report.annuelRooms.length > 1
              ? `des ${report.annuelRooms.length} pièces examinées`
              : `de la pièce examinée`),
          styles: CENTER_TITLE_STYLES,
        },
      ],
    ],
    body: [[report.org.annuelBilan ?? generateBilan(report)]],
  });
  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [[`Résultats chiffrés :`]],
    columnStyles: SECTION_TITLE_COLUMNS_STYLES,
    tableLineWidth: 0,
  });

  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content: 'Ouvrants',
          colSpan: 4,
          styles: CENTER_TITLE_STYLES,
        },
        {
          content: 'Pièces avec des bouches d’aération',
          colSpan: 4,
          styles: { ...CENTER_TITLE_STYLES, ...BORDER_LEFT_HEAD },
        },
      ],
      [
        'Total',
        'Ouvrable',
        'Facile d’accès',
        'Facile à ouvrir',
        { content: 'Total', styles: BORDER_LEFT_HEAD },
        'Fonctionnant',
        'Non bouchées',
        'Propres',
      ],
    ],
    body: [
      [
        formatInt(report.annuelRooms.reduce((a, r) => a + r.ouvrants, 0)),
        formatInt(report.annuelRooms.reduce((a, r) => a + r.ouvrable, 0)),
        formatInt(report.annuelRooms.reduce((a, r) => a + r.accessible, 0)),
        formatInt(report.annuelRooms.reduce((a, r) => a + r.manoeuvrable, 0)),
        formatInt(report.annuelRooms.reduce((a, r) => a + (r.fonctionne !== undefined ? 1 : 0), 0)),
        formatInt(report.annuelRooms.reduce((a, r) => a + (r.fonctionne ? 1 : 0), 0)),
        formatInt(report.annuelRooms.reduce((a, r) => a + (r.obture === NO_OBTURATION ? 1 : 0), 0)),
        formatInt(report.annuelRooms.reduce((a, r) => a + (r.encrasse === false ? 1 : 0), 0)),
      ],
    ],
    columnStyles: {
      0: { fontSize: FONT_SIZE, halign: 'center', cellWidth: WIDTH_8 },
      1: { fontSize: FONT_SIZE, halign: 'center', cellWidth: WIDTH_8 },
      2: { fontSize: FONT_SIZE, halign: 'center', cellWidth: WIDTH_8 },
      3: { fontSize: FONT_SIZE, halign: 'center', cellWidth: WIDTH_8 },
      4: { fontSize: FONT_SIZE, halign: 'center', cellWidth: WIDTH_8, ...BORDER_LEFT },
      5: { fontSize: FONT_SIZE, halign: 'center', cellWidth: WIDTH_8 },
      6: { fontSize: FONT_SIZE, halign: 'center', cellWidth: WIDTH_8 },
      7: { fontSize: FONT_SIZE, halign: 'center', cellWidth: WIDTH_8 },
    },
  });

  const SIZE_COL1 = 25;
  const SIZE_COL2 = 29;
  const SIZE_COL3 = 26;

  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      [
        {
          content:
            (report.annuelRooms.length > 1 ? 'Pièces classées' : 'Pièce classée') +
            ' par renouvellement d’air d’après les mesures de CO₂',
          colSpan: 6,
          styles: CENTER_TITLE_STYLES,
        },
      ],
      report.org.annuelEmoticon ?? DEFAULT_EMOTICON
        ? [
            { content: SATISFIED_FACE, styles: { halign: 'right', font: ICONS, fontSize: EMOTICON_SIZE } },
            { content: 'Satisfaisant', styles: { halign: 'left', valign: 'middle' } },
            {
              content: NEUTRAL_FACE,
              styles: { halign: 'right', font: ICONS, fontSize: EMOTICON_SIZE, ...BORDER_LEFT_HEAD },
            },
            { content: 'Moyen', styles: { halign: 'left', valign: 'middle' } },
            {
              content: DISSATISFIED_FACE,
              styles: { halign: 'right', font: ICONS, fontSize: EMOTICON_SIZE, ...BORDER_LEFT_HEAD },
            },
            { content: 'Insuffisant', styles: { halign: 'left', valign: 'middle' } },
          ]
        : [
            { content: 'Satisfaisant', colSpan: 2 },
            { content: 'Moyen', colSpan: 2, styles: { ...BORDER_LEFT_HEAD } },
            { content: 'Insuffisant', colSpan: 2, styles: { ...BORDER_LEFT_HEAD } },
          ],
    ],
    body: [
      [
        { colSpan: 2, content: '' + report.annuelRooms.reduce((a, r) => a + (!r.ppm800 && !r.ppm1500 ? 1 : 0), 0) },
        { colSpan: 2, content: '' + report.annuelRooms.reduce((a, r) => a + (r.ppm800 && !r.ppm1500 ? 1 : 0), 0) },
        { colSpan: 2, content: '' + report.annuelRooms.reduce((a, r) => a + (r.ppm1500 ? 1 : 0), 0) },
      ],
    ],
    columnStyles: {
      0: { halign: 'center', cellWidth: SIZE_COL1 },
      1: { halign: 'center', cellWidth: WIDTH_3 - SIZE_COL1 },
      2: { halign: 'center', cellWidth: SIZE_COL2, ...BORDER_LEFT },
      3: { halign: 'center', cellWidth: WIDTH_3 - SIZE_COL2 },
      4: { halign: 'center', cellWidth: SIZE_COL3, ...BORDER_LEFT },
      5: { halign: 'center', cellWidth: WIDTH_3 - SIZE_COL3 },
    },
  });

  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [[`Informations complémentaires :`]],
    columnStyles: SECTION_TITLE_COLUMNS_STYLES,
    tableLineWidth: 0,
  });
  const oneZone = report.annuelZones.length === 1;
  const hasSomeMaintenance = report.annuelZones.some((z) => hasMaintenance(z.mode));
  const hasSomeFilter = report.annuelZones.some((z) => hasFilter(z.mode));
  const zoneStyles: { [key: string]: Partial<Styles> } = {
    ...(oneZone
      ? {}
      : {
          0: { fontSize: FONT_SIZE, halign: 'left', cellWidth: WIDTH_4 },
          1: { fontSize: FONT_SIZE, halign: 'left', cellWidth: 'auto', ...BORDER_LEFT },
        }),
    2: {
      fontSize: FONT_SIZE,
      halign: 'left',
      cellWidth: oneZone ? 'auto' : hasSomeMaintenance ? (hasSomeFilter ? WIDTH_4 : WIDTH_4 + WIDTH_8) : WIDTH_2,
      ...BORDER_LEFT,
    },
    ...(hasSomeMaintenance
      ? {
          3: { fontSize: FONT_SIZE, halign: 'right', cellWidth: WIDTH_8, ...BORDER_LEFT },
        }
      : {}),
    ...(hasSomeFilter
      ? {
          4: { fontSize: FONT_SIZE, halign: 'right', cellWidth: WIDTH_8, ...BORDER_LEFT },
        }
      : {}),
  };
  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    head: [
      (oneZone
        ? []
        : [
            { content: 'Zone', styles: { halign: 'left' } },
            { content: 'Pièces', styles: { halign: 'left', ...BORDER_LEFT_HEAD } },
          ]
      )
        .concat([{ content: 'Mode d’aération ou de ventilation', styles: { halign: 'left', ...BORDER_LEFT_HEAD } }])
        .concat(
          hasSomeMaintenance
            ? [{ content: 'Dernière maintenance', styles: { halign: 'left', ...BORDER_LEFT_HEAD } }]
            : []
        )
        .concat(
          hasSomeFilter ? [{ content: 'Changement de filtre', styles: { halign: 'left', ...BORDER_LEFT_HEAD } }] : []
        ) as CellInput[],
    ],
    body: report.annuelZones.map((z) =>
      (oneZone ? [] : [z.nom, formatList(report.annuelRooms.filter((r) => r.zoneId === z.id).map((r) => r.nom))])
        .concat([z.mode ?? ''])
        .concat(hasSomeMaintenance ? [formatDateString(z.maintenance)] : [])
        .concat(hasSomeFilter ? [formatDateString(z.filtres)] : [])
    ),
    columns: columnsFromStyles(zoneStyles),
    columnStyles: zoneStyles,
  });
  const evalDates = evaluationDates(report).map((d) => formatDayMonth(d, 'long'));
  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [
      [
        'L’évaluation a été réalisée ' +
          (evalDates.length > 1 ? 'du ' + evalDates.join(' au ') : 'le ' + evalDates[0]) +
          ` ${report.year}.`,
      ],
    ],
    tableLineWidth: 0,
    columnStyles: PARAGRAPH_COLUMNS_STYLES,
  });
  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [
      [
        `Le rapport d’évaluation des moyens d’aération détaillé est disponible sur demande auprès de :
` +
          [
            org.propPersonne,
            org.propService,
            org.propPersonneMorale,
            formatAddress(org.propAdresse, org.propCodePostal, org.propVille).split(/\r?\n/g).join(', '),
          ]
            .filter((s) => s)
            .join(', ') +
          '.',
      ],
    ],
    tableLineWidth: 0,
    columnStyles: PARAGRAPH_COLUMNS_STYLES,
  });

  genTable(doc, undefined, {
    startY: addMargin(doc, TABLE_TOP_MARGIN),
    body: [
      [
        `Le responsable de l’évaluation conforme aux dispositions du code de l’environnement (Articles R. 221-30 à D. 221-38) est :
` +
          [
            org.respPersonne,
            org.respPersonneMorale,
            (respQualiteWithOwnAddress(org.respQualite, org.propQualite)
              ? formatAddress(org.respAdresse, org.respCodePostal, org.respVille)
              : formatAddress(org.propAdresse, org.propCodePostal, org.propVille)
            )
              .split(/\r?\n/g)
              .join(', '),
          ]
            .filter((s) => s)
            .join(', ') +
          '.',
      ],
    ],
    tableLineWidth: 0,
    columnStyles: PARAGRAPH_COLUMNS_STYLES,
  });

  const annuelReportOnlyPages = doc.getNumberOfPages();
  generateActionPlan(doc, undefined, 'annuel', report, logo);
  addFooters(
    doc,
    'annuel',
    report,
    org.nom,
    annuelReportOnlyPages,
    'Résultats de l’évaluation des moyens d’aération',
    'Plan d’action'
  );
  doc.save(fileName(org.nom, report.year, 'Résultats de l’évaluation des moyens d’aération'));
}
