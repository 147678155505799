import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import {
  Button,
  CellProps,
  Collapse,
  Column,
  Form,
  HorizontalGroup,
  Input,
  LinkButton,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';

import { css } from '@emotion/css';
import { GrafanaTheme2, IconName } from '@grafana/data';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Control, DeepMap, FieldError, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { formatDeadline, utcDate, utcTime } from '../../utils/utils.format';
import { AUTODIAG_TAB_DETAILS, AutodiagAnchorStart, AutodiagTab, reportUrl } from '../../utils/utils.routing';
import { DATETIME_WIDTH, FORM_MAX_WIDTH, getStyles } from '../../utils/utils.styles';
import { v5 as uuidv5 } from 'uuid';
import {
  BaseRow,
  CalendarButton,
  CalendarEvent,
  CreatableSelect,
  EMAIL_PATTERN,
  EditTable,
  GUIDE_CEREMA,
  OPTIONAL,
  PERSON_PLACEHOLDER,
  SalleFields,
  SaveButton,
  SaveState,
  SmallFieldSet,
  TooltipField,
  UUID_NAMESPACE,
  addAttendee,
  evaluableTooltip,
  expanderCell,
} from '../../utils/utils.form';
import { AutodiagFormTab, AUTODIAG_TAB_FIELDS } from './Autodiag';
import { BuyButtonComponent } from './BuyButtonComponent';
import { Org, Report, Room, TabObjectState, makeNameOptions } from 'utils/utils.model';
import { updateThing } from './queries';

const ROOM_RESP = 'Responsable d’activités';

const ROOM_NAME_FIELD = 'responsableActivites';
const ROOM_EMAIL_FIELD = 'emailActivites';
const ROOM_FUNCTION_FIELD = 'fonctionActivites';
const ROOM_DATE_FIELD = 'dateActivites';

interface TableRow<T extends Room | Org> extends BaseRow {
  datasourceName: string;
  year: string;
  isRoom: T extends Room ? true : false;
  value?: T;
  nameField: string;
  emailField: string;
  functionField: string;
  dateField: string;
  report: Report;
  tab: AutodiagFormTab;
  anchorStart?: AutodiagAnchorStart;
}

const tableData = (report: Report): Array<TableRow<any>> =>
  (
    Object.entries(AUTODIAG_TAB_FIELDS)
      .filter((e) => e[0] !== AutodiagTab.activites)
      .map(([tab, fields]) => ({
        ...fields,
        datasourceName: report.datasourceName,
        year: report.year,
        id: tab,
        tab: tab,
        isRoom: false,
        value: report.org,
        report: report,
      })) as Array<TableRow<any>>
  ).concat(
    report.autodiagRooms.map((r) => ({
      datasourceName: report.datasourceName,
      year: report.year,
      id: r.id,
      nom: 'Activités',
      isRoom: true,
      nameField: ROOM_NAME_FIELD,
      functionField: ROOM_FUNCTION_FIELD,
      emailField: ROOM_EMAIL_FIELD,
      dateField: ROOM_DATE_FIELD,
      tab: AutodiagTab.activites,
      anchorStart: AutodiagAnchorStart.activites,
      value: r,
      report: report,
    }))
  );

interface ExpandedRowProps {
  report: Report;
  setReports: Dispatch<SetStateAction<Report[]>>;
  row?: TableRow<any>;
  button: string;
  closeAdd: () => void;
}

const RespFields = ({
  report,
  row,
  errors,
  control,
  register,
  setValue,
}: {
  report: Report;
  row?: TableRow<any>;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
}) => (
  <>
    <TooltipField
      label={row?.tab ? AUTODIAG_TAB_FIELDS[row?.tab].functionDescription : ROOM_RESP}
      error={errors[row?.nameField ?? ROOM_NAME_FIELD]}
    >
      <CreatableSelect
        name={row?.nameField ?? ROOM_NAME_FIELD}
        autoComplete="name"
        defaultValue={row?.value?.[row.nameField]}
        control={control}
        register={register}
        additionalOnChange={(v) => {
          const name = report.names.find((n) => n.nom === v);
          if (name) {
            setValue(row?.emailField ?? ROOM_EMAIL_FIELD, name.email);
          }
        }}
        placeholder={PERSON_PLACEHOLDER}
        defaultOptions={makeNameOptions(report.names)}
      />
    </TooltipField>
    <TooltipField label="Fonction" error={errors[row?.functionField ?? ROOM_FUNCTION_FIELD]}>
      <Input
        {...register(row?.functionField ?? ROOM_FUNCTION_FIELD)}
        autoComplete="organization-title"
        defaultValue={row?.value?.[row.functionField]}
        placeholder={OPTIONAL}
      />
    </TooltipField>
    <TooltipField label="Courriel" error={errors[row?.emailField ?? ROOM_EMAIL_FIELD]}>
      <Input
        {...register(row?.emailField ?? ROOM_EMAIL_FIELD, EMAIL_PATTERN)}
        autoComplete="email"
        defaultValue={row?.value?.[row.emailField]}
        type="email"
        placeholder={OPTIONAL}
      />
    </TooltipField>
    <TooltipField label="Date" error={errors[row?.dateField ?? ROOM_DATE_FIELD]}>
      <Input
        {...register(row?.dateField ?? ROOM_DATE_FIELD)}
        defaultValue={row?.value?.[row.dateField]}
        width={DATETIME_WIDTH}
        type="datetime-local"
        lang="fr-FR"
      />
    </TooltipField>
  </>
);

function ExpandedRow({ report, setReports, row, button, closeAdd }: ExpandedRowProps) {
  const [buttonSaving, setButtonSaving] = useState(undefined as SaveState);
  const [expandPlanning, setExpandPlanning] = useState(false);
  const styles = useStyles2(getStyles);
  const submitRoom = (room: Room) => {
    setButtonSaving('saving');
    const org = {
      ...report.org,
      autodiagPieceIds: room.id
        ? Array.from(new Set([...(report.org.autodiagPieceIds ?? []), room.id]))
        : report.org.autodiagPieceIds,
    } as Org;
    updateThing(
      'autodiag',
      report,
      { room: room, org: org },
      () => {
        closeAdd();
        setButtonSaving('saved');
      },
      (err) => setButtonSaving(err),
      setReports
    );
  };
  const submitOrg = (org: Org) => {
    setButtonSaving('saving');
    updateThing(
      'autodiag',
      report,
      { org: org },
      () => {
        closeAdd();
        setButtonSaving('saved');
      },
      (err) => setButtonSaving(err),
      setReports
    );
  };

  const removeRoom = (room: Room) => {
    setButtonSaving('saving');
    const org = {
      nom: report.org.nom,
      id: report.org.id,
      autodiagPieceIds: report.org.autodiagPieceIds?.filter((i) => i !== room.id),
    };
    updateThing(
      'autodiag',
      report,
      { org: org },
      () => setButtonSaving('saved'),
      (err) => setButtonSaving(err),
      setReports
    );
  };

  return !row || row.isRoom ? (
    <Form<Room> onSubmit={submitRoom} maxWidth={FORM_MAX_WIDTH}>
      {({ register, errors, control, watch, setValue }) => (
        <>
          <SalleFields
            roomLabel="Salle représentative d’une activité"
            roomTooltip={
              <>
                La gestion des activités pédagogiques, artistiques, culturelles, etc. est à renseigner par les
                responsables d’activités avec <b>un exemplaire par pièce ou par activité de même nature</b>.
              </>
            }
            reportType={'autodiag'}
            report={report}
            room={row?.value as Room}
            errors={errors}
            control={control}
            register={register}
            setValue={setValue}
          />
          <Collapse
            label="Planning facultatif"
            key="planning"
            isOpen={expandPlanning}
            onToggle={(isOpen) => setExpandPlanning(isOpen)}
            collapsible={true}
            className={styles.collapse}
          >
            <RespFields
              report={report}
              row={row}
              errors={errors}
              control={control}
              register={register}
              setValue={setValue}
            />
          </Collapse>
          <HorizontalGroup align="flex-start" justify="flex-end">
            {!row?.value && (
              <Button type="button" variant="secondary" onClick={closeAdd}>
                Annuler
              </Button>
            )}
            {row?.value && (
              <Button
                type="button"
                variant="destructive"
                onClick={() => {
                  removeRoom(row.value as Room);
                }}
              >
                Retirer de l’échantillon
              </Button>
            )}
            <SaveButton label={button} state={buttonSaving} setState={(state: SaveState) => setButtonSaving(state)} />
          </HorizontalGroup>
        </>
      )}
    </Form>
  ) : (
    <Form<Org> onSubmit={submitOrg} maxWidth={FORM_MAX_WIDTH}>
      {({ register, errors, control, watch, setValue }) => (
        <>
          <input {...register('id')} type="hidden" value={row.value?.id} />
          <SmallFieldSet label="Planning facultatif">
            <RespFields
              report={report}
              row={row}
              errors={errors}
              control={control}
              register={register}
              setValue={setValue}
            />
          </SmallFieldSet>
          <HorizontalGroup align="flex-start" justify="flex-end">
            {!row?.value && (
              <Button type="button" variant="secondary" onClick={closeAdd}>
                Annuler
              </Button>
            )}
            <SaveButton label={button} state={buttonSaving} setState={(state: SaveState) => setButtonSaving(state)} />
          </HorizontalGroup>
        </>
      )}
    </Form>
  );
}

const getRoomTabStyles = (theme: GrafanaTheme2) => ({
  column: css`
    min-width: 300px;
  `,
  button: css`
    align-items: left !important;
    width: 14em;
  `,
});

function ResponsibleCell({ row: { original: original } }: CellProps<TableRow<any>, void>) {
  const styles = useStyles2(getRoomTabStyles);
  const row = original as TableRow<any>;
  const deadline = row.value[row.dateField] ? new Date(row.value[row.dateField]) : undefined;
  const daysToDeadline = deadline ? Math.trunc((deadline.getTime() - new Date().getTime()) / (3600000 * 24)) : 0;
  const icon: IconName = AUTODIAG_TAB_DETAILS[row.tab].titleIcon;
  let variant: 'secondary' | 'destructive' | 'success' = 'secondary';
  const isDone = row.isRoom
    ? row.report.autodiagTabProgress.room[row.value.id] === 100
    : row.report.autodiagTabProgress.tab[row.tab] === 100;
  if (isDone) {
    variant = 'success';
  } else if (daysToDeadline < 0) {
    variant = 'destructive';
  }
  return (
    <div className={styles.column}>
      <HorizontalGroup width="100%">
        <LinkButton
          className={styles.button}
          icon={icon}
          variant={variant}
          href={reportUrl('autodiag', row.datasourceName, row.year, row.tab, row.anchorStart, row.id)}
        >
          {row.nom}
        </LinkButton>
        {row.value[row.emailField] ? (
          <a href={'mailto:' + row.value[row.emailField]}>{row.value[row.nameField]}</a>
        ) : (
          row.value[row.nameField]
        )}
        {!isDone && deadline ? ` (${formatDeadline(deadline)})` : ' '}
      </HorizontalGroup>
    </div>
  );
}

const columns: Array<Column<TableRow<any>>> = [
  {
    id: 'nom',
    header: 'Salle',
    cell: expanderCell<TableRow<any>>((row) => (row.isRoom ? row.value.nom : 'Toutes les salles')),
  },
  {
    id: 'tache',
    header: 'Tâche',
    cell: ResponsibleCell,
  },
];

export class AutodiagRoomSceneObject extends SceneObjectBase<TabObjectState> {
  static Component = AutodiagRoomSceneObjectRenderer;

  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({ report: report, setReports: setReports, ...state });
  }
}

const sampleTooltip = (styles: { list: string; nestedlist: string }) => (
  <>
    <p>
      La gestion des activités pédagogiques, artistiques, culturelles, etc. est à renseigner par les responsables
      d’activités avec <b>un exemplaire par pièce ou par activité de même nature</b>.
    </p>
    {evaluableTooltip(styles)}
    <p>(Source&nbsp;: {GUIDE_CEREMA})</p>
  </>
);

function calendarDate(
  event: string,
  urlTab: AutodiagTab,
  anchorStart: AutodiagAnchorStart | undefined,
  report: Report,
  isRoom: boolean,
  thing: Room | Org,
  attendeeName: keyof Room,
  attendeeEmail: keyof Room,
  startField: keyof Room,
  endField?: keyof Room
): CalendarEvent | undefined {
  if (!thing[startField]) {
    return undefined;
  }
  const startDate = new Date(thing[startField]);
  let endDate;
  if (endField) {
    endDate = new Date(thing[endField]);
  } else {
    endDate = new Date(thing[startField]);
    endDate.setMinutes(endDate.getMinutes() + 30);
  }
  const org = report.org.nom ?? report.datasourceName;
  return addAttendee(report, thing[attendeeName], thing[attendeeEmail], {
    name: `Autodiagnostic - ${event}${isRoom ? ' - ' + thing.nom : ''} (${org})`,
    description: `À compléter dans [url]https://app.meo.life${reportUrl(
      'autodiag',
      encodeURIComponent(report.datasourceName),
      report.year,
      urlTab,
      anchorStart,
      thing.id
    )}|le logiciel meo[/url] pour l’autodiagnostic obligatoire sur la Qualité de l’Air Intérieur.`,
    startDate: utcDate(startDate),
    startTime: utcTime(startDate),
    endDate: utcDate(endDate),
    endTime: utcTime(endDate),
    location: `${isRoom ? thing.nom + ', ' : ''}${org}, ${report.org.adresse ?? ''}, ${report.org.codePostal ?? ''} ${
      report.org.ville ?? ''
    }`,
    // different from URL to use ids instead of names which could change
    uid: uuidv5(
      `autodiag/${report.datasourceUser}/${report.year}/${urlTab}/${thing.id}/${anchorStart}`,
      UUID_NAMESPACE
    ),
  });
}

function calendarDates(report: Report): CalendarEvent[] {
  return tableData(report)
    .map((row) =>
      calendarDate(
        `${row.nom}`,
        row.tab,
        row.anchorStart,
        report,
        row.isRoom,
        row.value,
        row.nameField,
        row.emailField,
        row.dateField
      )
    )
    .filter((item): item is CalendarEvent => !!item);
}

function AutodiagRoomSceneObjectRenderer({ model }: SceneComponentProps<AutodiagRoomSceneObject>) {
  const { report, setReports } = model.useState();
  const styles = useStyles2(getStyles);

  const maxRoomsReached = report.autodiagRooms.length >= report.maxRooms;
  return (
    <VerticalGroup>
      <div>
        <EditTable
          topButton="Ajouter une salle par activité"
          topButtonDisabled={maxRoomsReached}
          topButtonTooltip={
            maxRoomsReached ? <>Le nombre maximal de salles de l’abonnement a été atteint.</> : sampleTooltip(styles)
          }
          secondaryTopElements={
            <HorizontalGroup justify="flex-start" spacing="lg" wrap>
              {maxRoomsReached ? (
                <BuyButtonComponent clientReferenceId={report.datasourceUser} quantity={1} />
              ) : undefined}
              <CalendarButton
                name={`Qualité de l’Air Intérieur - ${report.org.nom ?? report.datasourceName}`}
                calendarDates={calendarDates(report)}
              />
            </HorizontalGroup>
          }
          addButton="Ajouter la salle"
          saveButton="Modifier"
          columns={columns}
          data={tableData(report)}
          showFormWhen={3}
          renderExpandedRow={(r, button, closeAdd) => (
            <ExpandedRow report={report} setReports={setReports} row={r} button={button} closeAdd={closeAdd} />
          )}
        />
      </div>
    </VerticalGroup>
  );
}
