import { PLUGIN_BASE_URL, ROUTES } from '../constants';
import { IconName } from '@grafana/data';
import { ReportType } from './utils.model';

export enum AnnuelAnchorStart {
  aeration = '#aeration-',
  capteur = '#capteur-',
  co2 = '#co2-',
}

export enum AnnuelTab {
  etablissement = 'etablissement',
  pieces = 'pieces',
  aeration = 'aeration',
  co2 = 'co2',
  actions = 'actions',
  communication = 'communication',
}

export const ANNUEL_TAB_DETAILS: {
  [key in AnnuelTab]: {
    title: string;
    titleIcon: IconName;
  };
} = {
  [AnnuelTab.etablissement]: {
    title: 'Établissement',
    titleIcon: 'building',
  },
  [AnnuelTab.pieces]: {
    title: 'Salles',
    titleIcon: 'gf-grid',
  },
  [AnnuelTab.aeration]: {
    title: 'Aération',
    titleIcon: 'process',
  },
  [AnnuelTab.co2]: {
    title: 'CO₂',
    titleIcon: 'chart-line',
  },
  [AnnuelTab.actions]: {
    title: 'Plan d’action',
    titleIcon: 'list-ol',
  },
  [AnnuelTab.communication]: {
    title: 'Communication',
    titleIcon: 'clipboard-alt',
  },
};

export enum AutodiagAnchorStart {
  activites = '#activites-',
}

export enum AutodiagTab {
  etablissement = 'etablissement',
  pieces = 'pieces',
  gestion = 'gestion',
  maintenance = 'maintenance',
  entretien = 'entretien',
  activites = 'activites',
  actions = 'actions',
  communication = 'communication',
}

export const AUTODIAG_TAB_DETAILS: {
  [key in AutodiagTab]: {
    title: string;
    titleIcon: IconName;
  };
} = {
  [AutodiagTab.etablissement]: {
    title: 'Établissement',
    titleIcon: 'building',
  },
  [AutodiagTab.pieces]: {
    title: 'Salles',
    titleIcon: 'gf-grid',
  },
  [AutodiagTab.gestion]: {
    title: 'Gestion globale',
    titleIcon: 'process',
  },
  [AutodiagTab.maintenance]: {
    title: 'Maintenance',
    titleIcon: 'dashboard',
  },
  [AutodiagTab.entretien]: {
    title: 'Entretien',
    titleIcon: 'trash-alt',
  },
  [AutodiagTab.activites]: {
    title: 'Activités',
    titleIcon: 'palette',
  },
  [AutodiagTab.actions]: {
    title: 'Plan d’action',
    titleIcon: 'list-ol',
  },
  [AutodiagTab.communication]: {
    title: 'Communication',
    titleIcon: 'clipboard-alt',
  },
};

export function tabLabel<T extends ReportType>(type: T, tab: AnnuelTab | AutodiagTab): string {
  return type === 'annuel'
    ? ANNUEL_TAB_DETAILS[tab as AnnuelTab].title
    : AUTODIAG_TAB_DETAILS[tab as AutodiagTab].title;
}

// Prefixes the route with the base URL of the plugin
export function prefixRoute(route: string): string {
  return `${PLUGIN_BASE_URL}/${route}`;
}

export function reportUrl<T extends ReportType>(
  type: T,
  datasource: string,
  year: string,
  tab: T extends 'annuel' ? AnnuelTab : AutodiagTab,
  anchorStart?: T extends 'annuel' ? AnnuelAnchorStart : AutodiagAnchorStart,
  anchorEnd?: string
) {
  return prefixRoute(
    (ROUTES.Home ? `${ROUTES.Home}/` : '') +
      `${datasource}/${type}/${year}/${tab}${anchorStart && anchorEnd ? anchorStart + anchorEnd : ''}`
  );
}
