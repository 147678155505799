import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import { Form, HorizontalGroup, Input, InputControl, RadioButtonGroup, Select, useStyles2 } from '@grafana/ui';
import React, { Dispatch, SetStateAction } from 'react';
import {
  EXPLOITANT,
  Org,
  PROPRIETAIRE,
  Report,
  SERVICES_TECHNIQUES,
  TabObjectState,
  proprietaireOuExploitant,
  respQualiteWithOwnAddress,
} from 'utils/utils.model';
import { FORM_MAX_WIDTH, getStyles, makeOptions } from 'utils/utils.styles';
import {
  Address,
  CreatableSelect,
  EMAIL_PATTERN,
  GUIDE_CEREMA,
  GUIDE_CSTB,
  NBSP,
  NumberInput,
  OPTIONAL,
  REQUIRED,
  SIRET_TOOLTIP,
  STRICTLY_POSITIVE,
  SaveButton,
  SaveState,
  SiretLink,
  SmallFieldSet,
  TARGET_BLANK,
  TEL_PATTERN,
  TooltipField,
  evaluableTooltip,
  isValidSiret,
} from '../../utils/utils.form';
import { updateThing } from './queries';

const BUTTON = 'button';

export class AnnuelOrgSceneObject extends SceneObjectBase<TabObjectState> {
  static Component = AnnuelOrgSceneObjectRenderer;

  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({ report: report, setReports: setReports, ...state });
  }
  setSaving = (saving: SaveState) => {
    this.state.report.editState.annuel.etablissement.saving[BUTTON] = saving;
    this.setState({ report: this.state.report });
  };
}

function AnnuelOrgSceneObjectRenderer({ model }: SceneComponentProps<AnnuelOrgSceneObject>) {
  const { report, setReports } = model.useState();
  const styles = useStyles2(getStyles);

  const doSubmit = (org: Org) => {
    model.setSaving('saving');
    updateThing(
      'annuel',
      report,
      { org: org },
      () => model.setSaving('saved'),
      (err) => model.setSaving(err),
      setReports
    );
  };

  const org = report.org;
  return (
    <Form<Org> onSubmit={doSubmit} maxWidth={FORM_MAX_WIDTH}>
      {({ register, errors, control, watch, setValue }) => (
        <div>
          <input {...register('id')} type="hidden" value={org.id} />
          <SmallFieldSet label="Description">
            <TooltipField label="Nom de l’établissement" error={errors.nom}>
              <Input
                {...register('nom', REQUIRED)}
                autoComplete="work organization"
                defaultValue={org.nom}
                type="text"
              />
            </TooltipField>
            <TooltipField
              label="Type"
              error={errors.type}
              tooltip={
                <>
                  <p>
                    Établissements déjà entrés dans le dispositif&nbsp;:
                    <ul className={styles.list}>
                      <li>
                        les établissements d’accueil collectif d’enfants de moins de 6 ans, à savoir les crèches,
                        haltes-garderies et jardins d’enfants (les relais d’assistantes maternelles et les logements
                        privés des assistantes maternelles ne sont pas concernés)&nbsp;;
                      </li>
                      <li>
                        les accueils de loisirs extrascolaires ou périscolaires pour mineurs mentionnés au 1° du II de
                        l’
                        <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000042174469/">
                          article R. 227-1 du code de l’action sociale et des familles
                        </a>
                        &nbsp;;
                      </li>
                      <li>
                        les établissements d’enseignement ou de formation professionnelle du premier et du second degré,
                        à savoir les écoles maternelles, élémentaires ainsi que les collèges et les lycées
                        d’enseignement général, techniques ou professionnels.
                      </li>
                    </ul>
                  </p>
                  <p>
                    Établissements dont l’entrée dans le dispositif est prévue au 1<sup>er</sup> janvier 2025&nbsp;:
                    <ul className={styles.list}>
                      <li>
                        les structures sociales et médico-sociales rattachées aux établissements de santé, ainsi que les
                        structures de soins de longue durée de ces établissements&nbsp;;
                      </li>
                      <li>
                        certains établissements et services médico-sociaux prévus au code de l’action sociale et des
                        familles&nbsp;;
                      </li>
                      <li>
                        les établissements pénitentiaires spécialisés pour les mineurs, les quartiers pour mineurs de
                        certains établissements pénitentiaires (maisons d’arrêt, centres pénitentiaires ou centres de
                        semi-liberté) ou les unités affectées à la prise en charge des mineurs.
                      </li>
                    </ul>
                  </p>
                  <p>
                    Les établissements d’activités physiques et sportives couverts dans lesquels sont pratiquées des
                    activités aquatiques, de baignade ou de natation (comme les piscines couvertes) ne sont plus
                    concernés par ce dispositif de surveillance de la QAI. En effet, ces locaux sont considérés comme
                    des locaux à pollution spécifique par le code du travail et doivent respecter les exigences en
                    matière d’aération et d’assainissement définies dans cette réglementation.
                  </p>
                  <p>({GUIDE_CEREMA})</p>
                </>
              }
            >
              <CreatableSelect
                name="type"
                rules={REQUIRED}
                defaultValue={org.type}
                control={control}
                register={register}
                placeholder="Choisir un type ou saisir un autre type"
                defaultOptions={makeOptions([
                  ['Crèche'],
                  ['Multi-accueil'],
                  ['Halte-garderie'],
                  ['Accueil de loisirs'],
                  ['École maternelle'],
                  ['École élémentaire'],
                  ['Collège'],
                  ['Lycée'],
                ])}
              />
            </TooltipField>
            <Address
              adresse="adresse"
              codePostal="codePostal"
              ville="ville"
              org={org}
              errors={errors}
              register={register}
              control={control}
            />
            <TooltipField label="Numéro de SIRET" tooltip={SIRET_TOOLTIP} error={errors.siret}>
              <HorizontalGroup spacing="xs" wrap>
                <Input
                  {...register('siret', { ...REQUIRED, validate: isValidSiret })}
                  defaultValue={org.siret}
                  width={20}
                  inputMode="numeric"
                />
                <SiretLink
                  nom={watch('nom', org.nom)}
                  adresse={watch('adresse', org.adresse)}
                  codePostal={watch('codePostal', org.codePostal)}
                  ville={watch('ville', org.ville)}
                />
              </HorizontalGroup>
            </TooltipField>
            <TooltipField label="Nombre de salles évaluables" tooltip={evaluableTooltip(styles)} error={errors.pieces}>
              <NumberInput
                field="pieces"
                defaultValue={org.pieces}
                options={{ ...REQUIRED, ...STRICTLY_POSITIVE }}
                register={register}
              />
            </TooltipField>
            <TooltipField label="Effectif théorique maximal de l’établissement" error={errors.effectif}>
              <NumberInput
                placeholder={OPTIONAL}
                field="effectif"
                defaultValue={org.effectif}
                register={register}
                options={STRICTLY_POSITIVE}
              />
            </TooltipField>
          </SmallFieldSet>
          <SmallFieldSet
            label={proprietaireOuExploitant(watch('propQualite', org.propQualite))}
            tooltip={
              <>
                Le propriétaire ou, si une convention le prévoit, l’exploitant d’un établissement […] est tenu de faire
                procéder, à ses frais, à une surveillance de la qualité de l’air à l’intérieur des locaux de son
                établissement (Sources{NBSP}:
                <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000046835238" {...TARGET_BLANK}>
                  Art. R. 221-30 du code de l’environnement
                </a>
                ).
              </>
            }
          >
            <TooltipField label="Qualité" error={errors.propQualite}>
              <InputControl
                name={'propQualite'}
                defaultValue={org.propQualite}
                control={control}
                rules={REQUIRED}
                render={({ field: { onChange, ref, ...field } }) => (
                  <RadioButtonGroup
                    {...field}
                    onChange={onChange}
                    options={[
                      { label: PROPRIETAIRE, value: PROPRIETAIRE },
                      { label: EXPLOITANT, value: EXPLOITANT },
                    ]}
                  />
                )}
              />
            </TooltipField>
            <TooltipField label="Personne morale" error={errors.propPersonneMorale}>
              <Input
                {...register('propPersonneMorale', REQUIRED)}
                autoComplete="work organization"
                defaultValue={org.propPersonneMorale}
                type="text"
              />
            </TooltipField>
            <Address
              adresse="propAdresse"
              codePostal="propCodePostal"
              ville="propVille"
              org={org}
              errors={errors}
              register={register}
              control={control}
            />
            <TooltipField label="Service concerné" error={errors.propService}>
              <Input {...register('propService', REQUIRED)} defaultValue={org.propService} type="text" />
            </TooltipField>
            <TooltipField label="Personne référente" error={errors.propPersonne}>
              <Input
                {...register('propPersonne', REQUIRED)}
                autoComplete="work name"
                defaultValue={org.propPersonne}
                type="text"
              />
            </TooltipField>
            <TooltipField label="Téléphone" error={errors.propTel}>
              <Input
                {...register('propTel', { ...REQUIRED, ...TEL_PATTERN })}
                autoComplete="work tel"
                defaultValue={org.propTel}
                type="tel"
              />
            </TooltipField>
            <TooltipField label="Courriel" error={errors.propEmail}>
              <Input
                {...register('propEmail', { ...REQUIRED, ...EMAIL_PATTERN })}
                autoComplete="work email"
                defaultValue={org.propEmail}
                type="email"
              />
            </TooltipField>
          </SmallFieldSet>
          <SmallFieldSet label="Responsable de l’évaluation">
            <TooltipField
              label="Nom de la personne responsable de l’évaluation"
              tooltip="Personne signant le rapport."
              error={errors.respPersonne}
            >
              {/* <InputControl
                control={control}
                name={'respPersonne'}
                rules={REQUIRED}
                defaultValue={report.org.respPersonne}
                render={({ field: { onChange, ref, ...field } }) => (
                  <CreatableSelect
                    {...field}
                    onChange={(v) => {
                      onChange(v);
                      if (v === watch().propPersonne) {
                        setValue('respTel', watch().propTel);
                        setValue('respEmail', watch().propEmail);
                      }
                    }}
                    placeholder={PERSON_PLACEHOLDER}
                    defaultValue={report.org.respPersonne}
                    defaultOptions={
                      watch('propPersonne', org.propPersonne)
                        ? [
                            {
                              label: watch('propPersonne', org.propPersonne),
                              value: watch('propPersonne', org.propPersonne),
                            },
                          ]
                        : []
                    }
                  />
                )}  />*/}

              <Input
                {...register('respPersonne', REQUIRED)}
                autoComplete="work name"
                defaultValue={org.respPersonne}
                type="text"
              />
            </TooltipField>
            <TooltipField label="Téléphone" error={errors.respTel}>
              <Input
                {...register('respTel', { ...REQUIRED, ...TEL_PATTERN })}
                autoComplete="work tel"
                defaultValue={org.respTel}
                type="tel"
              />
            </TooltipField>
            <TooltipField label="Courriel" error={errors.respEmail}>
              <Input
                {...register('respEmail', { ...REQUIRED, ...EMAIL_PATTERN })}
                autoComplete="work email"
                defaultValue={org.respEmail}
                type="email"
              />
            </TooltipField>
            <TooltipField
              label="Qualité"
              tooltip={
                <p>
                  L’évaluation annuelle des moyens d’aération du bâtiment est effectuée par&nbsp;:
                  <ul className={styles.list}>
                    <li>les services techniques de la collectivité publique,</li>
                    <li>ou toute autre personne du propriétaire ou de l’exploitant du bâtiment,</li>
                    <li>
                      par un contrôleur technique au sens de l’
                      <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000041587822/">
                        article L. 125-1 du code de la construction et de l’habitation
                      </a>
                      , titulaire d’un agrément l’autorisant à intervenir sur les bâtiments,
                    </li>
                    <li>par un bureau d’études ou par un ingénieur-conseil intervenant dans le domaine du bâtiment,</li>
                    <li>
                      ou par un organisme effectuant les prélèvements et/ou analyses mentionnés à l’
                      <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000022495537/">
                        article L. 221-8 du code de l’environnement
                      </a>
                      .
                    </li>
                  </ul>
                  ({GUIDE_CSTB})
                </p>
              }
              error={errors.respQualite}
            >
              <InputControl
                name={'respQualite'}
                defaultValue={org.respQualite}
                control={control}
                rules={{
                  ...REQUIRED,
                  validate: (v) => {
                    if (v === PROPRIETAIRE && watch('propQualite', org.propQualite) === EXPLOITANT) {
                      return 'Si le propriétaire est responsable de l’évaluation, la section ci-dessus doit aussi désigner le propriétaire, et non pas l’exploitant.';
                    }
                    return true;
                  },
                }}
                render={({ field: { onChange, ref, ...field } }) => (
                  <Select
                    {...field}
                    onChange={(v) => onChange(v?.value)}
                    options={makeOptions([
                      [PROPRIETAIRE],
                      [EXPLOITANT],
                      [SERVICES_TECHNIQUES],
                      ['Bureau d’études ou ingénieur-conseil'],
                      ['Contrôleur technique agréé'],
                      ['Organisme agréé'],
                    ])}
                    placeholder="Choisir"
                  />
                )}
              />
            </TooltipField>
            {watch('respQualite', org.respQualite) &&
              respQualiteWithOwnAddress(
                watch('respQualite', org.respQualite),
                watch('propQualite', org.propQualite)
              ) && (
                <>
                  <TooltipField label="Nom de l’organisme ou personne morale" error={errors.respPersonneMorale}>
                    <Input
                      {...register('respPersonneMorale', REQUIRED)}
                      autoComplete="work organization"
                      defaultValue={org.respPersonneMorale}
                      type="text"
                    />
                  </TooltipField>
                  <Address
                    adresse="respAdresse"
                    codePostal="respCodePostal"
                    ville="respVille"
                    org={org}
                    errors={errors}
                    register={register}
                    control={control}
                  />
                  <TooltipField label="Numéro de SIRET" tooltip={SIRET_TOOLTIP} error={errors.respSiret}>
                    <HorizontalGroup spacing="xs" wrap>
                      <Input
                        {...register('respSiret', { ...REQUIRED, validate: isValidSiret })}
                        defaultValue={org.respSiret}
                        width={20}
                        inputMode="numeric"
                      />
                      <SiretLink
                        nom={watch('respPersonneMorale', org.respPersonneMorale)}
                        adresse={watch('respAdresse', org.respAdresse)}
                        codePostal={watch('respCodePostal', org.respCodePostal)}
                        ville={watch('respVille', org.respVille)}
                      />
                    </HorizontalGroup>
                  </TooltipField>
                </>
              )}
          </SmallFieldSet>
          <HorizontalGroup align="flex-start" justify="flex-end">
            <SaveButton
              label="Sauvegarder"
              state={report.editState.annuel.etablissement.saving[BUTTON]}
              setState={model.setSaving}
            />
          </HorizontalGroup>
        </div>
      )}
    </Form>
  );
}
