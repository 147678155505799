import { cx } from '@emotion/css';
import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import {
  Collapse,
  Form,
  HorizontalGroup,
  Input,
  InputControl,
  RadioButtonGroup,
  Select,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import {
  DOUBLE_FLUX,
  HYBRID,
  NO_OBTURATION,
  ONLY_WINDOWS,
  Report,
  Room,
  SIMPLE_FLUX,
  TabObjectState,
  Zone,
  hasFilter,
  hasMaintenance,
  makeNameOptions,
} from 'utils/utils.model';
import { AnnuelAnchorStart } from 'utils/utils.routing';
import { DATETIME_WIDTH, DATE_WIDTH, FORM_MAX_WIDTH, getStyles, makeOptions } from 'utils/utils.styles';
import { v4 as uuidv4 } from 'uuid';
import {
  AutosizeTextArea,
  CreatableSelect,
  EmptyRooms,
  GUIDE_CEREMA,
  NumberInput,
  OPTIONAL,
  OPTIONAL_PERSON_PLACEHOLDER,
  POSITIVE,
  Progress,
  REQUIRED,
  REQUIRED_BOOLEAN,
  SaveButton,
  SaveState,
  SmallFieldSet,
  TooltipField,
} from '../../utils/utils.form';
import { Things, updateThing } from './queries';

const DEFAULT_ZONE_NAME = 'Zone principale';
const DEFAULT_ZONE: Zone = { id: uuidv4(), nom: DEFAULT_ZONE_NAME };
const ZONE_TOOLTIP = (
  <p>
    Bâtiment, étage, etc. Le nom peut être par exemple <i>Zone principale</i> dans le cas d’un mode d’aération ou de
    ventilation unique. Des zones peuvent être ajoutées dans le champ <i>Zone</i> des pièces s’il y a des modes
    d’aération ou de ventilation différents selon les bâtiments, étages ou pièces de la structure.
  </p>
);

const defaultZoneName = (room: Room, report: Report): string | undefined => {
  if (report.annuelZones.length === 0) {
    return DEFAULT_ZONE_NAME;
  }
  if (report.annuelZones.length === 1) {
    return report.annuelZones[0].nom;
  }
  return report.annuelZones.find((z) => z.id === room.zoneId)?.nom;
};

const roomSavingId = (roomId: string) => 'room-' + roomId;
const zoneSavingId = (zoneId: string) => 'zone-' + zoneId;

export class AnnuelAerationSceneObject extends SceneObjectBase<TabObjectState> {
  static Component = AnnuelAerationSceneObjectRenderer;
  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({
      report: report,
      setReports: setReports,
      ...state,
    });
  }
  setExpandRoom = (roomId: string | undefined) => (isOpen: boolean) => {
    this.state.report.editState.annuel.aeration.expandedRoom = isOpen ? roomId : undefined;
    this.setState({ report: this.state.report });
  };
  setExpandZone = (zoneId: string | undefined) => (isOpen: boolean) => {
    this.state.report.editState.annuel.aeration.expandedZone = isOpen ? zoneId : undefined;
    this.setState({ report: this.state.report });
  };
  setRoomSaveState = (roomId: string, editState: SaveState) => {
    this.state.report.editState.annuel.aeration.saving[roomSavingId(roomId)] = editState;
    this.setState({ report: this.state.report });
  };
  setZoneSaveState = (zoneId: string, editState: SaveState) => {
    this.state.report.editState.annuel.aeration.saving[zoneSavingId(zoneId)] = editState;
    this.setState({ report: this.state.report });
  };
}

function AnnuelAerationSceneObjectRenderer({ model }: SceneComponentProps<AnnuelAerationSceneObject>) {
  const { report, setReports } = model.useState();
  const styles = useStyles2(getStyles);
  const rooms = report.annuelRooms;
  const displayedZones = report.annuelZones.length > 0 ? report.annuelZones : [DEFAULT_ZONE];
  useEffect(() => {
    if (report.annuelZones.length === 0) {
      model.setExpandZone(DEFAULT_ZONE.id)(true);
    }
  }, [model, report.annuelZones.length]);
  const editState = report.editState.annuel.aeration;

  const roomsRef = useRef<Array<HTMLDivElement | null>>([]);
  useEffect(() => {
    roomsRef.current = roomsRef.current.slice(0, rooms.length);
  }, [rooms.length]);

  const zonesRef = useRef<Array<HTMLDivElement | null>>([]);
  useEffect(() => {
    zonesRef.current = zonesRef.current.slice(0, displayedZones.length);
  }, [displayedZones.length]);

  useEffect(() => {
    if (location.hash.startsWith(AnnuelAnchorStart.aeration)) {
      const id = location.hash.substring(AnnuelAnchorStart.aeration.length);
      model.setExpandRoom(id)(true);
      const roomIdx = rooms.findIndex((r) => r.id === id);
      if (roomIdx !== -1) {
        roomsRef.current?.at(roomIdx)?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  }, [model, rooms, roomsRef]);

  const doSubmitRoomEval = (room: Room) => {
    model.setRoomSaveState(room.id, 'saving');
    // room.zoneId is actually the name ('nom') in field to allow creation
    let zoneId;
    const existingZone = report.allZones.find((z) => z.nom === room.zoneId);
    if (existingZone) {
      zoneId = existingZone.id;
    } else if (room.zoneId === DEFAULT_ZONE_NAME) {
      zoneId = DEFAULT_ZONE.id;
    } else {
      zoneId = uuidv4();
    }
    const things: Things = { room: { ...room, zoneId: zoneId } as Room };
    if (!existingZone) {
      things.ventilation = { id: zoneId, nom: room.zoneId };
    }
    updateThing(
      'annuel',
      report,
      things,
      () => {
        model.setExpandRoom(undefined)(false);
        model.setRoomSaveState(room.id, undefined);
      },
      (err) => model.setRoomSaveState(room.id, err),
      setReports
    );
  };

  const doSubmitZoneEval = (zone: Zone) => {
    model.setZoneSaveState(zone.id, 'saving');
    updateThing(
      'annuel',
      report,
      { ventilation: zone },
      () => {
        model.setExpandZone(undefined)(false);
        model.setZoneSaveState(zone.id, undefined);
      },
      (err) => model.setZoneSaveState(zone.id, err),
      setReports
    );
  };

  return rooms.length === 0 ? (
    <EmptyRooms type="autodiag" report={report} />
  ) : (
    <VerticalGroup>
      <h2 className={cx([styles.title])}>Moyens d’aération par zone</h2>
      {displayedZones.map((zone, idx) => (
        <VerticalGroup key={zone.id} ref={(el) => (zonesRef.current[idx] = el)} width="100%">
          <Collapse
            label={
              <>
                <span className={cx([styles.collapseLabel])}>{zone.nom}</span>
                <Progress value={zone.mode ? 100 : 0} />
              </>
            }
            isOpen={editState.expandedZone === zone.id}
            onToggle={model.setExpandZone(zone.id)}
            collapsible={true}
            className={styles.collapse}
          >
            <div className={cx([styles.collapseDiv])}>
              <Form<Zone> onSubmit={doSubmitZoneEval} maxWidth={FORM_MAX_WIDTH}>
                {({ register, errors, control, watch, setValue, setFocus }) => (
                  <>
                    <input {...register('id')} type="hidden" value={zone.id} />
                    <TooltipField label="Nom" tooltip={ZONE_TOOLTIP} error={errors.nom}>
                      <Input
                        {...register('nom', {
                          ...REQUIRED,
                          validate: (v) => {
                            if (v && v !== zone?.nom && report.allZones.some((r) => r.nom.trim() === v)) {
                              return 'Ne peut pas être le même nom qu’une zone existante.';
                            }
                            return true;
                          },
                        })}
                        defaultValue={zone?.nom}
                        type="text"
                      />
                    </TooltipField>
                    <TooltipField
                      label="Mode de ventilation"
                      error={errors.mode}
                      tooltip={
                        <p>
                          Voir les descriptions dans la section{' '}
                          <strong>
                            Conseils complémentaires d’entretien des systèmes d’aération et de ventilation
                          </strong>{' '}
                          du {GUIDE_CEREMA}.
                        </p>
                      }
                    >
                      <InputControl
                        control={control}
                        name={'mode'}
                        defaultValue={zone.mode}
                        rules={REQUIRED}
                        render={({ field: { onChange, ref, ...field } }) => (
                          <Select
                            autoFocus
                            {...field}
                            onChange={(v) => onChange(v?.value)}
                            placeholder="Choisir"
                            options={makeOptions([
                              [ONLY_WINDOWS, 'Pas de bouches ou grilles d’aération'],
                              ['Naturel avec grilles d’aération hautes et basses'],
                              ['Naturel avec extraction par conduit à tirage naturel'],
                              [HYBRID + ' avec ventilation naturelle couplée à une assistance mécanique intermittente'],
                              [SIMPLE_FLUX + ' par extraction dans la pièce'],
                              [SIMPLE_FLUX + ' par balayage', 'extraction dans une autre pièce'],
                              [SIMPLE_FLUX + ' par insufflation'],
                              [DOUBLE_FLUX + ' par pièce'],
                              [DOUBLE_FLUX + ' par balayage', 'extraction dans une autre pièce'],
                            ])}
                          />
                        )}
                      />
                    </TooltipField>
                    {hasMaintenance(watch('mode', zone.mode)) && (
                      <>
                        <TooltipField
                          label="Date de la dernière maintenance du système de ventilation mécanique"
                          error={errors.maintenance}
                        >
                          <Input
                            {...register('maintenance', REQUIRED)}
                            defaultValue={zone.maintenance}
                            width={DATE_WIDTH}
                            type="date"
                            lang="fr-FR"
                          />
                        </TooltipField>
                        {hasFilter(watch('mode', zone.mode)) && (
                          <TooltipField
                            label="Date du dernier changement des filtres"
                            tooltip={
                              <>
                                Filtres pour l’air neuf placés entre la prise d’air neuf et la bouche de soufflage
                                <ul className={styles.list}>
                                  <li>à nettoyer tous les 6 mois</li>
                                  <li>
                                    à changer au moins une fois par an, selon leur usure et encrassement (liés à
                                    l’environnement extérieur)
                                  </li>
                                </ul>
                                (page 27 du {GUIDE_CEREMA})
                              </>
                            }
                            error={errors.filtres}
                          >
                            <Input
                              {...register('filtres', REQUIRED)}
                              defaultValue={zone.filtres}
                              width={DATE_WIDTH}
                              type="date"
                              lang="fr-FR"
                            />
                          </TooltipField>
                        )}
                      </>
                    )}
                    <HorizontalGroup justify="flex-end">
                      <SaveButton
                        label="Sauvegarder"
                        state={editState.saving[zoneSavingId(zone.id)]}
                        setState={(s) => model.setZoneSaveState(zoneSavingId(zone.id), s)}
                      />
                    </HorizontalGroup>
                  </>
                )}
              </Form>
            </div>
          </Collapse>
        </VerticalGroup>
      ))}
      <h2>Moyens d’aération par salle</h2>
      {rooms.map((room, idx) => (
        <VerticalGroup key={room.id} ref={(el) => (roomsRef.current[idx] = el)} width="100%">
          <Collapse
            label={
              <>
                <span className={cx([styles.collapseLabel])}>{room.nom}</span>{' '}
                <Progress value={room.zoneId ? 100 : 0} />
              </>
            }
            isOpen={editState.expandedRoom === room.id}
            onToggle={model.setExpandRoom(room.id)}
            collapsible={true}
            className={styles.collapse}
          >
            <div className={cx([styles.collapseDiv])}>
              <Form<Room> onSubmit={doSubmitRoomEval} maxWidth={FORM_MAX_WIDTH}>
                {({ register, errors, control, watch, setValue, setFocus }) => (
                  <>
                    <input {...register('id')} type="hidden" value={room.id} />
                    <TooltipField
                      label="Réalisé par"
                      tooltip={<>n’est pas inclus dans le rapport mais seulement utilisé pour organiser l’évaluation</>}
                      error={errors.responsableAeration}
                    >
                      <CreatableSelect
                        control={control}
                        register={register}
                        name={'responsableAeration'}
                        autoComplete="name"
                        placeholder={OPTIONAL_PERSON_PLACEHOLDER}
                        defaultOptions={makeNameOptions(report.names)}
                        defaultValue={room.responsableAeration}
                      />
                    </TooltipField>
                    <TooltipField label="Date de l’évaluation" error={errors.dateAeration}>
                      <Input
                        autoFocus
                        {...register('dateAeration', {
                          ...REQUIRED,
                          validate: (v) => {
                            if (v && new Date(v).getFullYear() !== parseInt(report.year, 10)) {
                              return `L’année doit être celle du rapport: ${report.year}.`;
                            }
                            return true;
                          },
                        })}
                        defaultValue={room.dateAeration}
                        width={DATETIME_WIDTH}
                        type="datetime-local"
                        lang="fr-FR"
                      />
                    </TooltipField>
                    <TooltipField label="Zone" tooltip={ZONE_TOOLTIP} error={errors.zoneId}>
                      <CreatableSelect
                        control={control}
                        register={register}
                        // zone name field value is converted to id after submit
                        name={'zoneId'}
                        rules={REQUIRED}
                        placeholder="Choisir ou ajouter une zone"
                        defaultOptions={report.allZones.map((z) => ({ label: z.nom, value: z.nom }))}
                        defaultValue={defaultZoneName(room, report)}
                      />
                    </TooltipField>
                    <SmallFieldSet label="Nombre d’ouvrants" className={styles.fieldset}>
                      <TooltipField
                        label="Total"
                        tooltip="fenêtre, porte-fenêtre ou porte ouvrant sur l'extérieur"
                        error={errors.ouvrants}
                      >
                        <NumberInput
                          field="ouvrants"
                          defaultValue={room.ouvrants}
                          options={{ ...REQUIRED, ...POSITIVE }}
                          register={register}
                        />
                      </TooltipField>
                      <TooltipField
                        label="En état de fonctionnement"
                        tooltip="effectivement ouvrable"
                        error={errors.ouvrable}
                      >
                        <NumberInput
                          field="ouvrable"
                          defaultValue={room.ouvrable}
                          options={{
                            ...REQUIRED,
                            validate: (v) => {
                              if (typeof v !== 'undefined' && v < 0) {
                                return 'Le nombre ne peut pas être négatif.';
                              }
                              if (typeof v !== 'undefined' && v > watch('ouvrants', room.ouvrants)) {
                                return 'Ne peut pas être supérieur au nombre d’ouvrants.';
                              }
                              return true;
                            },
                          }}
                          register={register}
                        />
                      </TooltipField>
                      <TooltipField
                        label="Facilement accessibles"
                        tooltip="ouvrable sans obstacle ni action supplémentaire nécessaire"
                        error={errors.accessible}
                      >
                        <NumberInput
                          field="accessible"
                          defaultValue={room.accessible}
                          options={{
                            ...REQUIRED,
                            validate: (v) => {
                              if (typeof v !== 'undefined' && v < 0) {
                                return 'Le nombre ne peut pas être négatif.';
                              }
                              if (typeof v !== 'undefined' && v > watch('ouvrable', room.ouvrable)) {
                                return 'Ne peut pas être supérieur au nombre d’ouvrants en état de fonctionnement.';
                              }
                              return true;
                            },
                          }}
                          register={register}
                        />
                      </TooltipField>
                      <TooltipField
                        label="Facilement manœuvrables"
                        tooltip="ouvrable par un adulte sans effort particulier"
                        error={errors.manoeuvrable}
                      >
                        <NumberInput
                          field="manoeuvrable"
                          defaultValue={room.manoeuvrable}
                          options={{
                            ...REQUIRED,
                            validate: (v) => {
                              if (typeof v !== 'undefined' && v < 0) {
                                return 'Le nombre ne peut pas être négatif';
                              }
                              if (typeof v !== 'undefined' && v > watch('ouvrable', room.ouvrable)) {
                                return 'Ne peut pas être supérieur au nombre d’ouvrants en état de fonctionnement.';
                              }
                              return true;
                            },
                          }}
                          register={register}
                        />
                      </TooltipField>
                      <TooltipField label="Commentaire" error={errors.commentOuvrants}>
                        <AutosizeTextArea
                          field="commentOuvrants"
                          control={control}
                          defaultValue={room.commentOuvrants}
                          placeholder={OPTIONAL}
                        />
                      </TooltipField>
                    </SmallFieldSet>
                    {report.allZones.find((z) => z.nom === watch('zoneId', defaultZoneName(room, report)))?.mode !==
                      ONLY_WINDOWS && (
                      <SmallFieldSet label="Examen des bouches ou grilles d’aération" className={styles.fieldset}>
                        <TooltipField
                          label="Fonctionnement"
                          tooltip={
                            <p>
                              Un simple test (par exemple avec une feuille de papier) de vérification de la circulation
                              de l’air est à réaliser annuellement au niveau des bouches et grilles : la feuille doit
                              être aspirée ou rejetée en fonction du rôle de la bouche / grille. Si ce test ne permet
                              pas de s’assurer que les débits réglementaires sont respectés, il permet au moins de
                              vérifier si le système de ventilation fonctionne. Il est cependant possible qu’aucun
                              mouvement d’air ne soit décelé en cas d’absence de tirage thermique (lié à la différence
                              de température entre l’intérieur et l’extérieur) ou de différentiel de pression (effet du
                              vent notamment). Dans ce cas, il est recommandé de refaire le test à une autre période de
                              la journée. (Source : {GUIDE_CEREMA}).
                            </p>
                          }
                          error={errors.fonctionne}
                        >
                          <InputControl
                            name={'fonctionne'}
                            defaultValue={room.fonctionne}
                            control={control}
                            rules={REQUIRED_BOOLEAN}
                            render={({ field: { onChange, ref, ...field } }) => (
                              <RadioButtonGroup
                                {...field}
                                onChange={onChange}
                                options={[
                                  { label: 'Oui, l’air circule dans le bon sens', value: true },
                                  { label: 'Non', value: false },
                                ]}
                              />
                            )}
                          />
                        </TooltipField>
                        <TooltipField
                          label="Obturation"
                          tooltip="masquant partiellement ou complètement la bouche"
                          error={errors.obture}
                        >
                          <CreatableSelect
                            control={control}
                            register={register}
                            name={'obture'}
                            rules={REQUIRED}
                            placeholder="Choisir ou ajouter un type d’obturation"
                            defaultValue={room.obture}
                            defaultOptions={makeOptions([
                              [NO_OBTURATION],
                              ['Obturation volontaire'],
                              ['Présence de mobilier'],
                              ['Autre obstacle'],
                            ])}
                          />
                        </TooltipField>
                        <TooltipField label="Encrassement" error={errors.encrasse}>
                          <InputControl
                            name={'encrasse'}
                            defaultValue={room.encrasse}
                            control={control}
                            rules={REQUIRED_BOOLEAN}
                            render={({ field: { onChange, ref, ...field } }) => (
                              <RadioButtonGroup
                                {...field}
                                onChange={onChange}
                                options={[
                                  { label: 'Encrassé', value: true },
                                  { label: 'Non encrassé', value: false },
                                ]}
                              />
                            )}
                          />
                        </TooltipField>
                        <TooltipField
                          label="Commentaire"
                          tooltip="par exemple sur le nombre de bouches ou grilles concernées"
                          error={errors.commentBouches}
                        >
                          <AutosizeTextArea
                            field={'commentBouches'}
                            control={control}
                            defaultValue={room.commentBouches}
                            placeholder={OPTIONAL}
                          />
                        </TooltipField>
                      </SmallFieldSet>
                    )}
                    <div className={styles.topMargin}>
                      <HorizontalGroup justify="flex-end">
                        <SaveButton
                          label="Sauvegarder"
                          state={editState.saving[roomSavingId(room.id)]}
                          setState={(s) => model.setRoomSaveState(roomSavingId(room.id), s)}
                        />
                      </HorizontalGroup>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </Collapse>
        </VerticalGroup>
      ))}
    </VerticalGroup>
  );
}
