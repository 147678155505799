import { SceneComponentProps, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { CellProps, Column, Input, InteractiveTable, useStyles2 } from '@grafana/ui';
import React, { Dispatch, SetStateAction } from 'react';

import { TooltipLabel, evaluableTooltip } from 'utils/utils.form';
import { getStyles } from 'utils/utils.styles';
import { BuyButtonComponent } from './BuyButtonComponent';
import { Report, getNecessarySampleRooms, hasOneOrg } from 'utils/utils.model';

export interface BillingSceneObjectState extends SceneObjectState {
  billing: Billing[];
  reports: Report[];
  setReports: Dispatch<SetStateAction<Report[]>>;
}

interface Billing {
  datasourceUser: string;
  datasourceName: string;
  evaluable: number;
  necessary: number;
  billed: number;
  toAdd: number;
}

const getBillingColumns = (
  billing: Billing[],
  setBilling: (billing: Billing[]) => void,
  reports: Report[],
  setReports: Dispatch<SetStateAction<Report[]>>
): Array<Column<Billing>> =>
  (hasOneOrg(reports) ? [] : [{ id: 'datasourceName', header: 'Établissement' } as Column<Billing>]).concat([
    { id: 'evaluable', header: 'Évaluables', cell: makeEvaluableCell(billing, setBilling, reports, setReports) },
    { id: 'necessary', header: 'À évaluer', cell: makeNumCell('necessary') },
    { id: 'billed', header: 'Souscrites', cell: BilledCell },
    { id: 'toAdd', header: 'À souscrire', cell: AddRoomsCell },
  ]);

const getDefaultBillingData = (reports: Report[]): Billing[] =>
  [
    ...new Map(
      reports.sort((a, b) => parseInt(a.year, 10) - parseInt(b.year, 10)).map((r) => [r.datasourceUser, r])
    ).values(),
  ].map((r) => makeBilling(r.datasourceUser, r.datasourceName, r.maxRooms, r.org.pieces));

const makeBilling = (
  datasourceUser: string,
  datasourceName: string,
  billed: number,
  pieces: number | undefined
): Billing => {
  const evaluable = pieces ?? 1;
  const necessary = getNecessarySampleRooms(evaluable);
  return {
    datasourceUser: datasourceUser,
    datasourceName: datasourceName,
    evaluable: evaluable,
    necessary: necessary,
    billed: billed,
    toAdd: necessary ? Math.max(0, necessary - billed) : 0,
  };
};

export class BillingSceneObject extends SceneObjectBase<BillingSceneObjectState> {
  static Component = BillingSceneObjectRenderer;

  constructor(
    reports: Report[],
    setReports: Dispatch<SetStateAction<Report[]>>,
    state?: Partial<BillingSceneObjectState>
  ) {
    super({ reports: reports, setReports: setReports, billing: getDefaultBillingData(reports), ...state });
  }

  setBilling = (billing: Billing[]) => {
    this.setState({ billing: billing });
  };
}

function BillingSceneObjectRenderer({ model }: SceneComponentProps<BillingSceneObject>) {
  const { reports, setReports, billing } = model.useState();
  const styles = useStyles2(getStyles);
  const billingColumns = getBillingColumns(billing, model.setBilling, reports, setReports);
  return (
    <div className={styles.container}>
      <h2>Nombre de salles</h2>
      <InteractiveTable columns={billingColumns} data={billing} getRowId={(r: Billing) => r.datasourceUser} />
    </div>
  );
}

const CURRENT_YEAR = new Date().getFullYear();

const makeEvaluableCell = (
  billing: Billing[],
  setBilling: (billing: Billing[]) => void,
  reports: Report[],
  setReports: Dispatch<SetStateAction<Report[]>>
) =>
  function EvaluableCell({
    row: {
      original: { datasourceUser, datasourceName, billed, evaluable },
    },
  }: CellProps<Billing, void>) {
    const styles = useStyles2(getStyles);
    return (
      <Input
        autoFocus
        width={12}
        type="number"
        min="1"
        step="1"
        value={evaluable}
        onChange={(v) => {
          const rowIdx = billing.findIndex((b) => b.datasourceUser === datasourceUser);
          if (rowIdx !== -1) {
            billing[rowIdx] = makeBilling(datasourceUser, datasourceName, billed, parseInt(v.currentTarget.value, 10));
            setBilling(billing);
          }
          let changed;
          reports.forEach((r) => {
            if (r.datasourceUser === datasourceUser && r.year === '' + CURRENT_YEAR && !r.org.pieces) {
              r.org.pieces = parseInt(v.currentTarget.value, 10);
              changed = true;
            }
          });
          if (changed) {
            setReports(reports);
          }
        }}
        suffix={<TooltipLabel label="" tooltip={evaluableTooltip(styles)} />}
      />
    );
  };

const makeNumCell = (field: keyof Billing) =>
  function NumberCell({ row: { original: original } }: CellProps<Billing, void>) {
    const styles = useStyles2(getStyles);
    return <div className={styles.center}>{original[field]}</div>;
  };

function BilledCell({
  row: {
    original: { billed },
  },
}: CellProps<Billing, void>) {
  const styles = useStyles2(getStyles);
  return <div className={styles.center}>{billed === 1 ? '1 (version gratuite)' : '' + billed}</div>;
}

const AddRoomsCell = ({
  row: {
    original: { datasourceUser, toAdd },
  },
}: CellProps<Billing, void>) => {
  return isNaN(toAdd) || toAdd > 0 ? <BuyButtonComponent clientReferenceId={datasourceUser} quantity={toAdd} /> : 0;
};
