import { DataSourceSettings } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import {
  EmbeddedScene,
  SceneApp,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObject,
  SceneQueryRunner,
} from '@grafana/scenes';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { ROUTES } from '../../constants';
import { GUIDE_CEREMA, GUIDE_CSTB, NBSP, Progress, TARGET_BLANK } from '../../utils/utils.form';
import {
  ANNUEL_TAB_DETAILS,
  AUTODIAG_TAB_DETAILS,
  AnnuelTab,
  AutodiagTab,
  prefixRoute,
  reportUrl,
} from '../../utils/utils.routing';
import { AnnuelActionPlanSceneObject, AutodiagActionPlanSceneObject } from './ActionPlanSceneObject';
import { AnnuelAerationSceneObject } from './AnnuelAerationSceneObject';
import { AnnuelCO2SceneObject } from './AnnuelCO2SceneObject';

import { Report, isValidType } from 'utils/utils.model';
import { AnnuelOrgSceneObject } from './AnnuelOrgSceneObject';
import { AnnuelRoomSceneObject } from './AnnuelRoomSceneObject';
import { AutodiagActivitesSceneObject } from './AutodiagActivitesSceneObject';
import {
  AutodiagEntretienSceneObject,
  AutodiagGestionSceneObject,
  AutodiagMaintenanceSceneObject,
} from './AutodiagOrgPratiquesSceneObject';
import { AutodiagOrgSceneObject } from './AutodiagOrgSceneObject';
import { AutodiagRoomSceneObject } from './AutodiagRoomSceneObject';
import { BillingSceneObject } from './BillingSceneObject';
import {
  AnnuelReportCommunicationSceneObject,
  AutodiagReportCommunicationSceneObject,
} from './ReportCommunicationSceneObject';
import { ReportsSceneObject } from './ReportsSceneObject';
import { extractReports, getReportsQuery } from './queries';

function getMeoDatasources(queryRunner: SceneQueryRunner) {
  return (
    getBackendSrv()
      .get<DataSourceSettings[]>('/api/datasources')
      // assuming all postgres datasources are meo datasources
      .then((result) => {
        queryRunner.setState({
          datasource: { uid: '-- Mixed --' },
          queries: result
            .filter((r) => r.type === 'postgres')
            .map((ds, idx) => ({
              refId: '' + idx,
              datasource: ds,
              format: 'table',
              rawQuery: true,
              rawSql: getReportsQuery(ds.user, ds.name),
            })),
        });
        queryRunner.runQueries();
      })
  );
}

const getHomeScene = (reports: Report[], setReports: Dispatch<SetStateAction<Report[]>>) => () =>
  new EmbeddedScene({
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        new SceneFlexItem({
          xSizing: 'content',
          ySizing: 'content',
          minHeight: '100',
          body: getEmbeddedScene(new BillingSceneObject(reports, setReports)),
        }),
        new SceneFlexItem({
          xSizing: 'fill',
          ySizing: 'fill',
          minHeight: '100',
          body: getEmbeddedScene(new ReportsSceneObject(reports)),
        }),
      ],
    }),
  });

const getDrilldownsAppScene = (orgName: string, reports: Report[], setReports: Dispatch<SetStateAction<Report[]>>) => {
  return new SceneApp({
    pages: [
      new SceneAppPage({
        title: `Qualité de l’Air Intérieur - ${orgName}`,
        subTitle: (
          <p>
            selon le décret n°2022-1690 du 27 déc. 2022 (
            <a
              href="https://www.cerema.fr/fr/actualites/qualite-air-interieur-etablissements-recevant-du-public"
              {...TARGET_BLANK}
            >
              Documents de référence
            </a>
            {NBSP}: {GUIDE_CEREMA} et {GUIDE_CSTB})
          </p>
        ),
        controls: [],
        url: prefixRoute(ROUTES.Home),
        hideFromBreadcrumbs: true,
        getScene: getHomeScene(reports, setReports),
        drilldowns: [
          {
            routePath: prefixRoute(`${ROUTES.Home}`) + ':datasource/:type/:year',
            getPage(routeMatch, parent) {
              const datasource = routeMatch.params.datasource;
              const type = routeMatch.params.type;
              const year = routeMatch.params.year;
              const report = reports.find((r) => r.datasourceName === datasource && r.year === year);
              if (!report || !isValidType(type)) {
                return new SceneAppPage({
                  url: reportUrl(type, datasource, year, AnnuelTab.etablissement),
                  title: !report ? `Pas de rapport ${year} pour ${datasource}` : `Type de rapport '${type}' non valide`,
                  subTitle: <a href={prefixRoute(`${ROUTES.Home}`)}>Retourner à l’accueil</a>,
                  getParentPage: () => parent,
                  getScene: () => {
                    return new EmbeddedScene({ body: new SceneFlexLayout({ children: [] }) });
                  },
                  hideFromBreadcrumbs: true,
                });
              }
              return type === 'annuel'
                ? new SceneAppPage({
                    url: reportUrl('annuel', datasource, year, AnnuelTab.etablissement),
                    title: `Évaluation annuelle ${year} - ${report.org.nom ?? report.datasourceName}`,
                    subTitle: '',
                    getParentPage: () => parent,
                    getScene: () => {
                      return new EmbeddedScene({ body: new SceneFlexLayout({ children: [] }) });
                    },
                    hideFromBreadcrumbs: false,
                    tabs: [
                      new SceneAppPage({
                        ...ANNUEL_TAB_DETAILS.etablissement,
                        tabSuffix: () => <Progress value={report.annuelTabProgress.etablissement} />,
                        url: reportUrl('annuel', datasource, year, AnnuelTab.etablissement),
                        getScene: () => getEmbeddedScene(new AnnuelOrgSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...ANNUEL_TAB_DETAILS.pieces,
                        tabSuffix: () => <Progress value={report.annuelTabProgress.pieces} />,
                        url: reportUrl('annuel', datasource, year, AnnuelTab.pieces),
                        getScene: () => getEmbeddedScene(new AnnuelRoomSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...ANNUEL_TAB_DETAILS.aeration,
                        tabSuffix: () => <Progress value={report.annuelTabProgress.aeration} />,
                        url: reportUrl('annuel', datasource, year, AnnuelTab.aeration),
                        getScene: () => getEmbeddedScene(new AnnuelAerationSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...ANNUEL_TAB_DETAILS.co2,
                        tabSuffix: () => <Progress value={report.annuelTabProgress.co2} />,
                        url: reportUrl('annuel', datasource, year, AnnuelTab.co2),
                        getScene: () => getEmbeddedScene(new AnnuelCO2SceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...ANNUEL_TAB_DETAILS.actions,
                        url: reportUrl('annuel', datasource, year, AnnuelTab.actions),
                        getScene: () => getEmbeddedScene(new AnnuelActionPlanSceneObject([report], setReports)),
                      }),
                      new SceneAppPage({
                        ...ANNUEL_TAB_DETAILS.communication,
                        tabSuffix: () => <Progress value={report.annuelTabProgress.communication} />,
                        url: reportUrl('annuel', datasource, year, AnnuelTab.communication),
                        getScene: () => getEmbeddedScene(new AnnuelReportCommunicationSceneObject(report, setReports)),
                      }),
                    ],
                  })
                : new SceneAppPage({
                    url: reportUrl('autodiag', datasource, year, AutodiagTab.etablissement),
                    title: `Autodiagnostic ${year} - ${report.org.nom ?? report.datasourceName}`,
                    subTitle: '',
                    getParentPage: () => parent,
                    getScene: () => {
                      return new EmbeddedScene({ body: new SceneFlexLayout({ children: [] }) });
                    },
                    hideFromBreadcrumbs: false,
                    tabs: [
                      new SceneAppPage({
                        ...AUTODIAG_TAB_DETAILS.etablissement,
                        tabSuffix: () => <Progress value={report.autodiagTabProgress.tab.etablissement} />,
                        url: reportUrl('autodiag', datasource, year, AutodiagTab.etablissement),
                        getScene: () => getEmbeddedScene(new AutodiagOrgSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...AUTODIAG_TAB_DETAILS.pieces,
                        //tabSuffix: () => <Progress value={report.autodiagTabProgress.tab.pieces} />,
                        url: reportUrl('autodiag', datasource, year, AutodiagTab.pieces),
                        getScene: () => getEmbeddedScene(new AutodiagRoomSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...AUTODIAG_TAB_DETAILS.gestion,
                        tabSuffix: () => <Progress value={report.autodiagTabProgress.tab.gestion} />,
                        url: reportUrl('autodiag', datasource, year, AutodiagTab.gestion),
                        getScene: () => getEmbeddedScene(new AutodiagGestionSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...AUTODIAG_TAB_DETAILS.maintenance,
                        tabSuffix: () => <Progress value={report.autodiagTabProgress.tab.maintenance} />,
                        url: reportUrl('autodiag', datasource, year, AutodiagTab.maintenance),
                        getScene: () => getEmbeddedScene(new AutodiagMaintenanceSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...AUTODIAG_TAB_DETAILS.entretien,
                        tabSuffix: () => <Progress value={report.autodiagTabProgress.tab.entretien} />,
                        url: reportUrl('autodiag', datasource, year, AutodiagTab.entretien),
                        getScene: () => getEmbeddedScene(new AutodiagEntretienSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...AUTODIAG_TAB_DETAILS.activites,
                        tabSuffix: () => <Progress value={report.autodiagTabProgress.tab.activites} />,
                        url: reportUrl('autodiag', datasource, year, AutodiagTab.activites),
                        getScene: () => getEmbeddedScene(new AutodiagActivitesSceneObject(report, setReports)),
                      }),
                      new SceneAppPage({
                        ...AUTODIAG_TAB_DETAILS.actions,
                        url: reportUrl('autodiag', datasource, year, AutodiagTab.actions),
                        getScene: () => getEmbeddedScene(new AutodiagActionPlanSceneObject([report], setReports)),
                      }),
                      new SceneAppPage({
                        ...AUTODIAG_TAB_DETAILS.communication,
                        tabSuffix: () => <Progress value={report.autodiagTabProgress.tab.communication} />,
                        url: reportUrl('autodiag', datasource, year, AutodiagTab.communication),
                        getScene: () =>
                          getEmbeddedScene(new AutodiagReportCommunicationSceneObject(report, setReports)),
                      }),
                    ],
                  });
            },
          },
        ],
      }),
    ],
  });
};

function getEmbeddedScene(sceneObject: SceneObject) {
  return new EmbeddedScene({
    body: sceneObject,
    controls: [],
  });
}

export const HomePage = () => {
  // hard to share data between app pages so data is managed outside
  const [reports, setReports] = useState<Report[]>([]);
  const [orgName, setOrgName] = useState<string>('');
  useMemo(() => {
    const queryRunner = new SceneQueryRunner({
      datasource: { uid: '-- Mixed --' },
      queries: [],
    });
    queryRunner.subscribeToState((newData) => setReports(extractReports(newData)));
    getMeoDatasources(queryRunner);
  }, []);

  getBackendSrv()
    .get('/api/org/')
    .then((result) => {
      setOrgName(result.name);
    });

  const scene = useMemo(() => {
    return getDrilldownsAppScene(orgName, reports, setReports);
  }, [orgName, reports, setReports]);
  return (
    <React.StrictMode>
      <scene.Component model={scene} />
    </React.StrictMode>
  );
};
