import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { SceneComponentProps, SceneObjectBase } from '@grafana/scenes';
import {
  Button,
  ButtonGroup,
  Form,
  HorizontalGroup,
  Input,
  InputControl,
  RadioButtonGroup,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';
import { saveAs } from 'file-saver';
import React, { Dispatch, FormEvent, SetStateAction, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import {
  AutosizeTextArea,
  GUIDE_CEREMA,
  NBSP,
  REQUIRED,
  REQUIRED_BOOLEAN,
  SaveButton,
  SaveState,
  SmallFieldSet,
  TARGET_BLANK,
  TooltipField,
} from 'utils/utils.form';
import { Org, Report, ReportType, TabObjectState, forExport } from 'utils/utils.model';
import { fileName } from 'utils/utils.pdf';
import { DATE_WIDTH, getStyles } from 'utils/utils.styles';
import {
  DEFAULT_EMOTICON,
  generateAnnuelFullReportCsv,
  generateAnnuelFullReportPdf,
  generateAnnuelResultsPdf,
  generateBilan,
} from './AnnuelReport';
import { generateAutodiagCsv, generateAutodiagPdf } from './AutodiagReport';
import { updateThing } from './queries';

const SAVING_ID = 'main';
const CUSTOMIZATION_SAVING_ID = 'customization';

class ReportCommunicationSceneObject extends SceneObjectBase<TabObjectState> {
  constructor(report: Report, setReports: Dispatch<SetStateAction<Report[]>>, state?: Partial<TabObjectState>) {
    super({ report: report, setReports: setReports, ...state });
  }

  setSaving = (saving: SaveState, type: ReportType, id: string) => {
    this.state.report.editState[type].communication.saving[id] = saving;
    this.setState({ report: this.state.report });
  };
}

const IMG_SIZE = 150;

function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result as string);
    reader.onerror = (_e) => reject(reader.error);
    reader.onabort = (_e) => reject(new Error('Read aborted'));
    reader.readAsDataURL(blob);
  });
}

// from https://artskydj.github.io/jsPDF/docs/modules_addimage.js.html
const IMG_FORMATS = 'image/jpg, image/jpeg, image/png, image/gif, image/webp';

const FILE_MAX_SIZE = 200000;

export const getLogoStyles = (theme: GrafanaTheme2) => ({
  emptyLogo: css`
    border: 1px solid ${theme.colors.border.weak};
    width: ${IMG_SIZE}px;
    height: ${IMG_SIZE}px;
  `,
});

export const getLabelStyles = (theme: GrafanaTheme2) => ({
  label: css`
    width: 190px;
    margin-right: 10px;
    font-size: 20px;
    height: 30px;
    line-height: 30px;
  `,
});

const FORM_MAX_WIDTH = 500;

const HIDDEN_INPUT_NAME = 'hiddenFileInput';

function saveJSon(report: Report) {
  const blob = new Blob([JSON.stringify(forExport(report))], {
    type: 'application/json;charset=utf-8',
  });
  saveAs(blob, fileName(report.org.nom, report.year, 'Rapports') + '.json');
}

const makeRenderer = (
  type: ReportType,
  generatePdf: { [label: string]: (report: Report) => void },
  generateCsv: { [label: string]: (report: Report) => void },
  comment?: { label: string; field: string; defaultText: (report: Report) => string },
  emoticon?: { label: string; field: string }
) =>
  function ReportCommunicationSceneObjectRenderer({ model }: SceneComponentProps<ReportCommunicationSceneObject>) {
    const { report, setReports } = model.useState();
    const hiddenInputRef = useRef<HTMLInputElement | null>(null);
    const [fileUploadMessage, setFileUploadMessage] = useState<string>('');
    const styles = useStyles2(getStyles);
    const logoStyles = useStyles2(getLogoStyles);
    const labelStyles = useStyles2(getLabelStyles);
    const doSubmit = (buttonId: string) => (org: Org) => {
      model.setSaving('saving', type, buttonId);
      delete org[HIDDEN_INPUT_NAME];
      updateThing(
        type,
        report,
        { org: org },
        () => model.setSaving('saved', type, buttonId),
        (err) => model.setSaving(err, type, buttonId),
        setReports
      );
    };
    const onFileUpload = (event: FormEvent<HTMLInputElement>, setValue: UseFormSetValue<Org>) => {
      const file: File | undefined =
        event.currentTarget.files && event.currentTarget.files.length > 0 && event.currentTarget.files[0]
          ? event.currentTarget.files[0]
          : undefined;
      if (file) {
        if (file.size > FILE_MAX_SIZE) {
          setFileUploadMessage(
            `Le taille du fichier est trop grande : ${Math.round(file.size / 1000)} kB > ${FILE_MAX_SIZE / 1000} kB`
          );
        } else {
          blobToDataURL(file).then((d) => {
            setValue('logo', d);
          });
        }
      }
    };
    const org = report.org;
    const progress = type === 'annuel' ? report.annuelTotalProgress : report.autodiagTotalProgress;
    return (
      <VerticalGroup spacing="lg">
        <VerticalGroup spacing="lg">
          {Object.entries(generatePdf).map(([label, generatePdf]) => (
            <>
              <div className={labelStyles.label}>{label}</div>
              <ButtonGroup key={label}>
                <Button
                  size="md"
                  fill="outline"
                  type="button"
                  disabled={progress < 100}
                  tooltip={
                    progress < 100
                      ? 'Le rapport doit être complet à 100% pour être téléchargé en PDF.'
                      : 'Télécharger le fichier en PDF.'
                  }
                  icon="file-alt"
                  onClick={() => generatePdf(report)}
                >
                  PDF
                </Button>
                {generateCsv[label] !== undefined && (
                  <>
                    <Button
                      size="md"
                      fill="outline"
                      type="button"
                      disabled={progress < 100}
                      tooltip={
                        progress < 100
                          ? 'Le rapport doit être complet à 100% pour être téléchargé en CSV.'
                          : 'Télécharger le fichier en CSV.'
                      }
                      icon="file-download"
                      onClick={() => generateCsv[label](report)}
                    >
                      CSV
                    </Button>
                    <Button
                      size="md"
                      fill="outline"
                      type="button"
                      disabled={progress < 100}
                      tooltip={
                        progress < 100
                          ? 'Le rapport doit être complet à 100% pour être téléchargé en JSON.'
                          : 'Télécharger le fichier en JSON.'
                      }
                      icon="file-download"
                      onClick={() => saveJSon(report)}
                    >
                      JSON
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </>
          ))}
        </VerticalGroup>
        <h2 className={cx([styles.title])}>Personnalisation</h2>
        <VerticalGroup>
          <Form<Org> onSubmit={doSubmit(CUSTOMIZATION_SAVING_ID)} maxWidth={FORM_MAX_WIDTH}>
            {({ register, errors, control, watch, setValue }) => (
              <>
                <input {...register('id')} type="hidden" value={org.id} />
                <input {...register('nom')} type="hidden" value={org.nom ?? report.datasourceName} />
                <SmallFieldSet
                  label="Logo"
                  tooltip={<>Le format idéal est carré et la taille maximale est de {FILE_MAX_SIZE / 1000} kB.</>}
                >
                  <VerticalGroup width={'' + IMG_SIZE} align="center">
                    {watch('logo', org.logo) ? (
                      <img width={IMG_SIZE} src={watch('logo', org.logo)} />
                    ) : (
                      <div className={logoStyles.emptyLogo}></div>
                    )}
                    <input {...register('logo')} type="hidden" defaultValue={org.logo} />
                    <InputControl
                      name={HIDDEN_INPUT_NAME}
                      control={control}
                      render={({ field: { onChange, ref, ...field } }) => (
                        <Input
                          {...field}
                          ref={(e) => {
                            ref(e);
                            hiddenInputRef.current = e;
                          }}
                          type="file"
                          accept={IMG_FORMATS}
                          onChange={(e) => onFileUpload(e, setValue)}
                          multiple={false}
                          className={styles.hidden}
                        />
                      )}
                    />
                    {fileUploadMessage && <p className={styles.warningDescription}>{fileUploadMessage}</p>}
                    <Button
                      icon="upload"
                      variant="secondary"
                      fill="outline"
                      onClick={() => hiddenInputRef.current?.click()}
                    >
                      Importer
                    </Button>
                  </VerticalGroup>
                </SmallFieldSet>
                {comment && emoticon && (
                  <SmallFieldSet label="Résultats">
                    <TooltipField label={emoticon.label} error={errors[emoticon.field]}>
                      <InputControl
                        name={emoticon.field}
                        defaultValue={org[emoticon.field] ?? DEFAULT_EMOTICON}
                        control={control}
                        rules={REQUIRED_BOOLEAN}
                        render={({ field: { onChange, ref, ...field } }) => (
                          // no switch since switch is for immediate change, not for a form
                          <RadioButtonGroup
                            {...field}
                            onChange={onChange}
                            options={[
                              {
                                label: 'Oui',
                                description: 'Afficher des émoticônes dans les résultats.',
                                value: true,
                              },
                              {
                                label: 'Non',
                                description: 'Ne pas afficher des émoticônes dans les résultats.',
                                value: false,
                              },
                            ]}
                          />
                        )}
                      />
                    </TooltipField>
                    {progress >= 100 && (
                      <TooltipField label={comment.label} error={errors[comment.field]}>
                        <AutosizeTextArea
                          options={REQUIRED}
                          field={comment.field}
                          control={control}
                          defaultValue={report.org[comment.field] ?? comment.defaultText(report)}
                        />
                      </TooltipField>
                    )}{' '}
                  </SmallFieldSet>
                )}
                <HorizontalGroup align="flex-start" justify="flex-end" className={styles.topMargin}>
                  <SaveButton
                    label="Sauvegarder"
                    setState={(state: SaveState) => model.setSaving(state, type, CUSTOMIZATION_SAVING_ID)}
                    state={report.editState[type].communication.saving[CUSTOMIZATION_SAVING_ID]}
                  />
                </HorizontalGroup>
              </>
            )}
          </Form>
        </VerticalGroup>

        <h2 className={cx([styles.title])}>Communications {type === 'annuel' ? 'et affichage ' : ''}obligatoires</h2>
        <Form<Org> onSubmit={doSubmit(SAVING_ID)} maxWidth={FORM_MAX_WIDTH}>
          {({ register, errors, control, watch, setValue }) => (
            <>
              <input {...register('id')} type="hidden" value={org.id} />
              <SmallFieldSet label="Par le responsable de l’évaluation">
                <TooltipField
                  label="Propriétaire ou exploitant informé le"
                  error={errors[type + 'Reception']}
                  tooltip={
                    <>
                      Le rapport d’évaluation des moyens d’aération doit être transmis par le prestataire ou le service
                      technique ou l’auteur de l’évaluation au propriétaire, ou si une convention le prévoit, à
                      l’exploitant du bâtiment, dans les 30 jours à compter de la fin de la réalisation de l’évaluation
                      moyens d’aération (Sources{NBSP}: p. 23 du {GUIDE_CEREMA} et{' '}
                      <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000031099410/" {...TARGET_BLANK}>
                        Art. R. 221-32 du code de l’environnement
                      </a>
                      ).
                    </>
                  }
                >
                  <Input
                    {...register(type + 'Reception')}
                    defaultValue={org[type + 'Reception']}
                    width={DATE_WIDTH}
                    type="date"
                  />
                </TooltipField>
              </SmallFieldSet>
              <SmallFieldSet
                label="Par le propriétaire ou l’exploitant"
                tooltip={
                  <>
                    L’ensemble des documents traçant les actions et résultats des différentes phases du dispositif de la
                    surveillance de la QAI (évaluation annuelle des moyens d’aération, autodiagnostic de la qualité de
                    l’air intérieur, campagne de mesures des polluants réglementaires et plan d’actions associé),
                    doivent être <strong>tenus à disposition du préfet de département</strong> qui peut prescrire des
                    mesures correctives, le cas échéant, ainsi qu’à la disposition des autres instances et personne
                    habilitées de l’établissement (Source{NBSP}: p. 13 du {GUIDE_CEREMA}).
                  </>
                }
              >
                <TooltipField
                  label="Directeur ou chef d’établissement informé le"
                  tooltip={
                    <p>
                      À compter de la réception du dernier rapport, le propriétaire (ou, le cas échéant, l’exploitant)
                      de l’établissement informe dans un délai de 30 jours le directeur d’école ou du chef
                      d’établissement (Source{NBSP}: p. 17 du {GUIDE_CEREMA}).
                    </p>
                  }
                  error={errors[type + 'Chef']}
                >
                  <Input
                    {...register(type + 'Chef')}
                    defaultValue={org[type + 'Chef']}
                    width={DATE_WIDTH}
                    type="date"
                  />
                </TooltipField>
                {type === 'annuel' && (
                  <TooltipField
                    label="Affichage obligatoire le"
                    tooltip={
                      <>
                        <p>
                          Après la mise en place du plan d’action ou de sa mise à jour, le propriétaire, ou à défaut,
                          l’exploitant affiche les conclusions de la dernière évaluation des moyens d’aération
                          (conformément au rapport de cette évaluation) et le plan d’action décidé.
                        </p>
                        <p>
                          Afin d’informer et de sensibiliser les usagers, un affichage permanent est obligatoire près de
                          l’entrée principale.
                        </p>
                        <p>
                          Le délai d’affichage n’est pas imposé mais il est recommandé de respecter un délai d’affichage
                          de 30 jours après mise en place ou modification du plan d’action.
                        </p>
                        <p>
                          (Source{NBSP}: p. 133 du {GUIDE_CEREMA})
                        </p>
                      </>
                    }
                    error={errors.annuelAffichage}
                  >
                    <Input
                      {...register('annuelAffichage')}
                      defaultValue={org.annuelAffichage}
                      width={DATE_WIDTH}
                      type="date"
                    />
                  </TooltipField>
                )}
              </SmallFieldSet>

              <SmallFieldSet label="Par le directeur ou le chef d’établissement" className={styles.topMargin}>
                <TooltipField
                  label="Conseil d’école ou conseil d’administration informé le"
                  tooltip={
                    <p>
                      Le directeur d’école ou le chef d’établissement en avise les membres du conseil d’école ou du
                      conseil d’administration ({GUIDE_CEREMA}).
                    </p>
                  }
                  error={errors[type + 'Conseil']}
                >
                  <Input
                    {...register(type + 'Conseil')}
                    defaultValue={org[type + 'Conseil']}
                    width={DATE_WIDTH}
                    type="date"
                  />
                </TooltipField>
                <TooltipField
                  label="Commission hygiène et sécurité informée le"
                  tooltip={
                    <p>
                      Le directeur d’école ou le chef d’établissement en avise la commission hygiène et sécurité à
                      l’occasion de la prochaine réunion qui suit la réception des résultats ({GUIDE_CEREMA}).
                    </p>
                  }
                  error={errors[type + 'Chs']}
                >
                  <Input {...register(type + 'Chs')} defaultValue={org[type + 'Chs']} width={DATE_WIDTH} type="date" />
                </TooltipField>
              </SmallFieldSet>
              <div className={styles.topMargin}>
                <HorizontalGroup align="flex-start" justify="flex-end" className={styles.topMargin}>
                  <SaveButton
                    label="Sauvegarder"
                    setState={(state: SaveState) => model.setSaving(state, type, SAVING_ID)}
                    state={report.editState[type].communication.saving[SAVING_ID]}
                    disabled={progress < 100}
                    tooltip={progress < 100 ? 'Le rapport doit être complet à 100% pour être communiqué.' : undefined}
                  />
                </HorizontalGroup>
              </div>
            </>
          )}
        </Form>
      </VerticalGroup>
    );
  };

export class AnnuelReportCommunicationSceneObject extends ReportCommunicationSceneObject {
  static Component = makeRenderer(
    'annuel',
    {
      'Rapport complet': generateAnnuelFullReportPdf,
      'Résultats à afficher': generateAnnuelResultsPdf,
    },
    {
      'Rapport complet': generateAnnuelFullReportCsv,
    },
    { label: 'Bilan général de l’évaluation', field: 'annuelBilan', defaultText: generateBilan },
    { label: 'Émoticônes', field: 'annuelEmoticon' }
  );
}

export class AutodiagReportCommunicationSceneObject extends ReportCommunicationSceneObject {
  static Component = makeRenderer(
    'autodiag',
    { Autodiagnostic: generateAutodiagPdf },
    { Autodiagnostic: generateAutodiagCsv }
  );
}
