import { DataFrame, DataQueryResponse, DataQueryResponseData, dateTime } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import { SceneDataState } from '@grafana/scenes';
import { Observable, lastValueFrom } from 'rxjs';
import { BaseRow } from 'utils/utils.form';
import { v4 as uuidv4 } from 'uuid';

import { Org, Report, ReportType, Room, Zone, emptyReport, updateReportCalculatedFields } from 'utils/utils.model';
import { updatedActionsWithProposals } from './actionPlanProposer';

const makeQuery = (refId: string, sql: string) => ({
  app: 'meo-erp-app',
  requestId: uuidv4(),
  timezone: '',
  range: {
    from: dateTime(),
    to: dateTime(),
    raw: { from: 'now', to: 'now' },
  },
  interval: '',
  intervalMs: 0,
  targets: [
    {
      refId: refId,
      format: 'table',
      rawQuery: true,
      rawSql: sql,
    },
  ],
  scopedVars: {},
  startTime: Date.now(),
});

export function getReportsQuery(dsUser: string, dsName: string) {
  // TODO add function that creates report from previous year
  // UPDATE "THINGS"
  // SET "PROPERTIES" = jsonb_set("PROPERTIES", '{reports,2024}',("PROPERTIES" -> 'reports') -> '2023')
  const currentYear = new Date().getFullYear();
  return (
    `WITH r AS (SELECT max_rooms() AS m)` +
    ` SELECT r.m AS max_rooms,'${escape(dsUser)}' AS ds_user,'${escape(
      dsName
    )}' AS ds_name,"ID" AS thing_id,"NAME","PROPERTIES"->>'type' AS type,"PROPERTIES"->'reports' AS reports` +
    ` FROM r,"THINGS" WHERE "PROPERTIES"->>'type' IN ('room','ventilation','zone','org')` +
    ` UNION ALL SELECT max_rooms() AS max_rooms,'${escape(dsUser)}' AS ds_user,'${escape(
      dsName
    )}' AS ds_name,uuid_generate_v4() AS thing_id,'' as "NAME",'org' AS type,'{"${currentYear}":{}}' AS reports` +
    ` FROM r,"THINGS" WHERE "PROPERTIES"->>'type'='org' HAVING COUNT(*)=0`
  );
}

export function extractReport(
  queryState: SceneDataState | undefined,
  datasourceName: string,
  datasourceUser: string,
  year: string
): Report {
  const reports = extractReports(queryState, datasourceUser, year);
  if (reports.length) {
    return reports[0];
  }
  return emptyReport(datasourceName, datasourceUser, year);
}

export function extractReports(
  queryState?: SceneDataState,
  datasourceUserFilter?: string,
  yearFilter?: string
): Report[] {
  if (!queryState?.data) {
    return [];
  }
  return extractFrameReports(queryState.data?.series, datasourceUserFilter, yearFilter);
}

export function extractFrameReports(frames: DataFrame[], datasourceUserFilter?: string, yearFilter?: string): Report[] {
  const dsYearToReport: Map<string, Report> = new Map();
  frames.forEach((frame) => {
    const max = frame.fields.find((field) => field.name === 'max_rooms');
    const id = frame.fields.find((field) => field.name === 'thing_id');
    const dsName = frame.fields.find((field) => field.name === 'ds_name');
    const dsUser = frame.fields.find((field) => field.name === 'ds_user');
    const name = frame.fields.find((field) => field.name === 'NAME');
    const type = frame.fields.find((field) => field.name === 'type');
    const allReports = frame.fields.find((field) => field.name === 'reports');
    for (let i = 0; i < frame.length; i++) {
      if (datasourceUserFilter && dsUser?.values[i] !== datasourceUserFilter) {
        continue;
      }
      const report = JSON.parse(allReports?.values[i]);
      if (!report) {
        continue;
      }
      for (const [year, reports] of Object.entries(report)) {
        const yearReport = reports as any;
        yearReport.id = id?.values[i];
        let report = dsYearToReport.get(year + dsUser?.values[i]);
        if (!report) {
          report = emptyReport(dsName?.values[i], dsUser?.values[i], year);
          dsYearToReport.set(year + dsUser?.values[i], report);
        }
        report.maxRooms = max?.values[i];
        switch (type?.values[i]) {
          case 'org':
            const org: Org = { ...report.org, ...yearReport };
            if (!org.nom) {
              org.nom = name?.values[i];
            }
            org.actions.forEach((a, idx) => {
              a.id = '' + (idx + 1);
            });
            report.org = org;
            break;
          case 'room':
            report.allRooms.push(yearReport);
            break;
          case 'ventilation':
            report.allZones.push(yearReport);
            break;
          default:
            console.error('Unkown type ' + type?.values[i]);
        }
      }
    }
  });
  Array.from(dsYearToReport.values()).forEach((r) => {
    updateReportCalculatedFields(r);
  });
  const sortedByYearDesc = [...dsYearToReport.values()].sort((a, b) => parseInt(b.year, 10) - parseInt(a.year, 10));
  const reportWithAutodiag = sortedByYearDesc.find((r) => r.org?.autodiagPieceIds);
  if (!reportWithAutodiag && sortedByYearDesc.length > 0) {
    sortedByYearDesc[0].org = { ...sortedByYearDesc[0].org, autodiagPieceIds: [] } as Org;
  }
  return sortedByYearDesc;
}

const query = (
  datasourceName: string,
  ref: string,
  success: (res: DataQueryResponseData[]) => void,
  error: (err: string) => void,
  sql: string
) =>
  getDataSourceSrv()
    .get(datasourceName)
    .then((ds) => {
      lastValueFrom(ds.query(makeQuery(ref, sql)) as unknown as Observable<DataQueryResponse>)
        .then((res) => {
          if (res.errors) {
            error(JSON.stringify(res.errors));
          } else {
            success(res.data);
          }
        })
        .catch((err) => {
          error(JSON.stringify(err));
        });
    });

// Sample org thing:
// type: 'org',
// reports: {
//   2023: {
// actions: [], // actions have array index as ids and are never deleted
// ...many other key/value fields that can be shared between yearly and autodiag report
// (fields of non completed autodiag are moved to current year)
// },...
//
// Sample ventilation thing for zone:
// type: 'ventilation',
// reports: {
//   2023: {}
// },...
//
// Sample room thing:
// type: 'room',
// reports: {
//  2023: {}
// },...

export enum ThingType {
  org = 'org',
  room = 'room',
  ventilation = 'ventilation',
}

const escape = (str: string) => str.replaceAll("'", "''");

const updateThingSql = (type: ThingType, year: string, name: string, rowUpdate: BaseRow) => {
  return (
    `SELECT insert_thing_if_not_exist(` +
    // TODO find a way to get a unique thing name which is mostly just for index unicity and monitor thing name
    // e.g. only update when current year
    (name ? `'${escape(name)}'` : 'NULL') +
    `,FALSE,NULL,'` +
    escape(JSON.stringify(rowUpdate, (k, v) => (k === 'id' ? undefined : v))) +
    `'::jsonb,'${escape(type)}','${escape(rowUpdate.id)}'::uuid,'{reports,${escape(year)}}'::text[])`
  );
};

const updateReport = (report: Report, type: ThingType, thing: BaseRow) => {
  switch (type) {
    case 'org':
      const org = thing as Org;
      report.org = { ...report.org, ...org };
      break;
    case 'room':
      const room = thing as Room;
      const existingRoom = report.allRooms.find((z) => z.id === room.id);
      if (existingRoom) {
        Object.assign(existingRoom, room);
      } else {
        report.allRooms.push(room);
      }
      break;
    case 'ventilation':
      const zone = thing as Zone;
      const existingZone = report.allZones.find((z) => z.id === zone.id);
      if (existingZone) {
        Object.assign(existingZone, zone);
      } else {
        report.allZones.push(zone);
      }
      break;
    default:
      throw new Error('Unknown type ' + type);
  }
};

export type Things = {
  [Property in ThingType]?: BaseRow;
};

/**
 * Updates Thing table.
 * @param previousReport current non updated report
 * @param type  thing type to be updated
 * @param thing thing to be updated
 * @param success
 * @param error
 * @param setReports
 * @param otherType
 * @param other
 */
export const updateThing = (
  reportType: ReportType,
  previousReport: Report,
  rows: Things,
  success: () => void,
  error: (err: string) => void,
  setReports: (set: (previousReports: Report[]) => Report[]) => void
) => {
  const newReport: Report = structuredClone(previousReport);
  Object.entries(rows).forEach((e) => {
    updateReport(newReport, e[0] as ThingType, e[1]);
  });
  updateReportCalculatedFields(newReport);
  const updatedActions = updatedActionsWithProposals(newReport, reportType);
  if (updatedActions) {
    rows.org = rows.org
      ? { ...rows.org, actions: updatedActions }
      : { id: previousReport.org?.id, nom: previousReport.org?.nom, actions: updatedActions };
    updateReport(newReport, ThingType.org, rows.org);
    updateReportCalculatedFields(newReport);
  }
  query(
    previousReport.datasourceName,
    'update-' + Object.keys(rows).join('-'),
    () => {
      success();
      setReports((previousReports) => {
        const reportIndex = previousReports.findIndex(
          (r) => r.year === newReport.year && r.datasourceUser === newReport.datasourceUser
        );
        if (reportIndex === -1) {
          throw new Error('Report should already exist: ' + JSON.stringify(newReport));
        }
        newReport.editState = { ...newReport.editState, ...previousReports[reportIndex].editState };
        previousReports[reportIndex] = newReport;
        return [...previousReports];
      });
    },
    error,
    `WITH ` +
      Object.entries(rows)
        .map((e, i) => `t${i} as (` + updateThingSql(e[0] as ThingType, previousReport.year, e[1].nom, e[1]) + ')')
        .join(',') +
      ' ' +
      Object.keys(rows)
        .map((t, i) => `SELECT * from t${i}`)
        .join(' UNION ')
  );
};
